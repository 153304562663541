import React from 'react';
import { Avatar, Box, CardMedia, Modal, Slide, Typography, IconButton, useTheme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import getS3Bucket from '../../utils/getS3Bucket';

import styles from './FullScreenMediaModal.module.scss';

const FullScreenMediaModal = ({ open, handleModal, url, mediaType, currentTab }) => {
  const theme = useTheme();

  const getMediaUrl = () => {
    if (currentTab === 'Upload Media') {
      return url;
    }
    if (getS3Bucket() && url) {
      return getS3Bucket() + url;
    }
    return '';
  };

  return (
    <Modal
      open={open}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
      className={styles.mainContainer}
    >
      <Slide in={open} direction="up">
        <Box className={styles.mainBody}>
          <IconButton className={styles.closeBtn} onClick={() => handleModal()}>
            <ClearOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
              }}
            />
          </IconButton>
          <CardMedia
            component={mediaType === 'Image' ? 'img' : 'video'}
            src={getMediaUrl()}
            className={styles.mediaPic}
            controls
            autoPlay
          />
        </Box>
      </Slide>
    </Modal>
  );
};

export default FullScreenMediaModal;
