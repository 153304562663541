import { Box, Drawer, IconButton, Typography, useTheme } from "@mui/material";
import { ReactComponent as ClearOutlinedIcon } from "../../assets/icons/closeFilter.svg";
import styles from "./FilterDrawer.module.scss";

const FilterDrawer = ({ isOpen, handleFilterDrawer, filterType }) => {
    const theme = useTheme();
    return (
        <Drawer
            anchor="right"
            open={isOpen}
            sx={{
                '.MuiBackdrop-root': {
                    background: 'rgba(0,0,0,0.4)',
                }
            }}
        >
            <Box className={styles.mainContainer}>
                <IconButton className={styles.closeBtn} onClick={() => handleFilterDrawer()} disableRipple>
                    <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
                </IconButton>

                <Typography className={styles.modalTitle}>Filters</Typography>
                

            </Box>
        </Drawer>
    )
}

export default FilterDrawer;