import { Box, Typography } from '@mui/material';
import styles from './StoreDetailStyles.module.scss';

const StoreBankDetails = ({ bankDetails }) => {
  return (
    <Box className={styles.bankDetailContainer}>
      <Typography className={styles.addressTitle}>Bank</Typography>
      <Box className={styles.content}>
        <Box className={styles.dFlex}>
          <Typography className={styles.title}>Account Number</Typography>
          <Typography className={styles.value}>{bankDetails?.accountNumber}</Typography>
        </Box>

        <Box className={styles.dFlex}>
          <Typography className={styles.title}>IFSC Code</Typography>
          <Typography className={styles.value}>{bankDetails?.ifsc}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default StoreBankDetails;
