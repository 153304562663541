import * as Yup from "yup";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { Button, Stack, TextField, Typography, useTheme } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { addQuestion } from "../../../api/AddQuestion";
import { updateQuestion } from "../../../api/UpdateQuestion";
import styles from "./QuestionContainer.module.scss";
import { EditorField } from "../Editor/Editor";

const QuestionContainer = ({ headerId, questionId, question, answer, questionLength, refetch, setAddForm, setExand, editQuestion }) => {

    const theme = useTheme();

    const validateFaq = Yup.object({
        question: Yup.string().required("Question is required"),
        answer: Yup.string().required("Answer is required")
    })

    const setFieldVal = (value) => {
        setFieldValue("answer", value)
    }

    const initialValues = {
        question: question !== undefined ? question : "",
        answer: answer !== undefined ? answer : ""
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validateFaq,
        onSubmit: (values, { resetForm }) => {
            if (questionId) {
                updateQuestionMutation.mutateAsync({ value: { question: values.question, answer: values.answer }, headerId, questionId })
            } else {
                addQuestionMutation.mutateAsync({ value: { question: values.question, answer: values.answer }, headerId })
            }
        }
    })
    const { errors, touched, getFieldProps, handleSubmit, values, resetForm, setFieldValue } = formik;

    const addQuestionMutation = useMutation(
        "addQuestion",
        ({ value, headerId }) => addQuestion(value, headerId),
        {
            onSuccess: ({ data }) => {
                if (data) {
                    if (!data?.success) {
                        toast.error(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    } else if (data?.success) {
                        toast.success(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    }
                    resetForm();
                    setAddForm();
                    refetch();
                }
            },
            onError: (error) => {
                console.log('error', error)
                resetForm();
            },
        }
    )

    const updateQuestionMutation = useMutation(
        "updateQuestion",
        ({ value, headerId, questionId }) => updateQuestion(value, headerId, questionId),
        {
            onSuccess: ({ data }) => {
                if (data) {
                    if (!data?.success) {
                        toast.error(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    } else if (data?.success) {
                        toast.success(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    }
                    resetForm();
                    refetch();
                    editQuestion();
                    setExand();
                }
            },
            onError: (error) => {
                console.log('error', error)
                resetForm();
            },
        }
    )

    return (
        <FormikProvider value={formik}>
            <Stack direction="row" alignItems="center" gap="15px">
                <Typography>Q.</Typography>
                <TextField
                    fullWidth
                    autoComplete="question"
                    type="question"
                    focused={Boolean(values.question)}
                    {...getFieldProps('question')}
                    error={Boolean(touched.question && errors.question)}
                    helperText={touched.question && errors.question}
                    sx={{
                        borderRadius: 0,
                        input: {
                            padding: '11px',
                            borderRadius: 0,
                        },
                        '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                            borderRadius: '5px',
                        },
                        ".css-16d5wub-MuiFormHelperText-root.Mui-error": {
                            marginLeft: "0 !important"
                        }
                    }}
                />
            </Stack>
            <Stack direction="row" alignItems="center" gap="15px">
                <Typography>A.</Typography>
                {/* <TextField
                    fullWidth
                    autoComplete="answer"
                    type="answer"
                    focused={Boolean(values.answer)}
                    {...getFieldProps('answer')}
                    error={Boolean(touched.answer && errors.answer)}
                    helperText={touched.answer && errors.answer}
                    sx={{
                        borderRadius: 0,
                        input: {
                            padding: '11px',
                            borderRadius: 0,
                        },
                        '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                            borderRadius: '5px',
                        },
                        ".css-16d5wub-MuiFormHelperText-root.Mui-error": {
                            marginLeft: "0 !important"
                        }
                    }}
                /> */}
                <EditorField setFieldValue={setFieldVal} initalAnswer={values.answer} />
            </Stack>
            <Stack direction="row" justifyContent="end" gap="10px" marginTop="15px">
                {
                    Boolean(setAddForm && questionLength) && (
                        <Button
                            className={styles.btn}
                            onClick={() => {
                                resetForm();
                                setAddForm();
                            }}
                        >
                            Cancel
                        </Button>
                    )
                }
                {
                    editQuestion && (
                        <Button
                            className={styles.btn}
                            onClick={() => {
                                resetForm();
                                editQuestion();
                            }}
                        >
                            Cancel
                        </Button>
                    )
                }
                {
                    Boolean(setAddForm && !questionLength) && (
                        <Button
                            className={styles.btn}
                            onClick={() => {
                                resetForm();
                                setExand();
                            }}
                        >
                            Cancel
                        </Button>
                    )
                }

                <Button className={styles.btn} sx={{
                    color: "#FFF",
                    background: theme.palette.primary.main,
                    "&:hover": {
                        color: "#FFF",
                        background: theme.palette.primary.main,
                    }
                }}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    Save
                </Button>
            </Stack>
        </FormikProvider >
    )
}

export default QuestionContainer;