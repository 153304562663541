// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppPagination_paginationContainer__jYDVO {\n  width: 1000px;\n  max-width: 1000px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 50px;\n}\n\n.AppPagination_pagination__qF0u- {\n  color: \"red\";\n}", "",{"version":3,"sources":["webpack://./src/components/AppPagination/AppPagination.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".paginationContainer {\n  width: 1000px;\n  max-width: 1000px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 50px;\n}\n\n.pagination {\n  color: 'red';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": "AppPagination_paginationContainer__jYDVO",
	"pagination": "AppPagination_pagination__qF0u-"
};
export default ___CSS_LOADER_EXPORT___;
