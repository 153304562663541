import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { updateGst } from '../../api/UpdateGst';
import { createGst } from '../../api/CreateGst';
import { getAllCategories } from '../../api/GetAllCategory';
import { getGst } from '../../api/GetGstById';
import { getSubCategories } from '../../api/GetSubCategories';
import styles from './AddStateGst.module.scss';
import { ReactComponent as DownArrow } from '../../assets/icons/downArrow.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/addIcon.svg';
import { getStateGstById } from '../../api/GetStateGstById';
import { addSalesPerson } from '../../api/AddSalesPerson';
import { updateStateGst } from '../../api/UpdateStaeGst';
import addSalesPersonValidationSchema from './AddSalesPersonValidation';

const gstRegEx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

const states = [
  { name: 'Select state', stateGstCode: undefined },
  { name: 'Andhra Pradesh', stateGstCode: 37 },
  { name: 'Arunachal Pradesh', stateGstCode: 12 },
  { name: 'Assam', stateGstCode: 18 },
  { name: 'Bihar', stateGstCode: 10 },
  { name: 'Chhattisgarh', stateGstCode: 4 },
  { name: 'Goa', stateGstCode: 30 },
  { name: 'Gujarat', stateGstCode: 24 },
  { name: 'Haryana', stateGstCode: 6 },
  { name: 'Himachal Pradesh', stateGstCode: 2 },
  { name: 'Jharkhand', stateGstCode: 20 },
  { name: 'Karnataka', stateGstCode: 29 },
  { name: 'Kerala', stateGstCode: 32 },
  { name: 'Madhya Pradesh', stateGstCode: 23 },
  { name: 'Maharashtra', stateGstCode: 27 },
  { name: 'Manipur', stateGstCode: 14 },
  { name: 'Meghalaya', stateGstCode: 17 },
  { name: 'Mizoram', stateGstCode: 15 },
  { name: 'Nagaland', stateGstCode: 13 },
  { name: 'Odisha', stateGstCode: 21 },
  { name: 'Punjab', stateGstCode: 3 },
  { name: 'Rajasthan', stateGstCode: 8 },
  { name: 'Sikkim', stateGstCode: 11 },
  { name: 'Tamil Nadu', stateGstCode: 33 },
  { name: 'Telangana', stateGstCode: 36 },
  { name: 'Tripura', stateGstCode: 16 },
  { name: 'Uttarakhand', stateGstCode: 5 },
  { name: 'Uttar Pradesh', stateGstCode: 9 },
  { name: 'West Bengal', stateGstCode: 19 },
];

const AddSalesPersonModal = ({ isOpen, handleShowSalesPersonModal, refetch,refetchCount }) => {
  const theme = useTheme();


  const formik = useFormik({
    initialValues: {
      name: '',
      mobile: '',
      email: '',
      address: '',
      aadharNo: '',
    },
    validationSchema: addSalesPersonValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {},
  });
  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, setFieldValue, resetForm } = formik;

  const addSalesPersonMutation = useMutation('addSalesPerson', () => addSalesPerson(values), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
          handleModalClose();
          refetch();
          refetchCount();
        }
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const handleModalClose = () => {
    resetForm();
    handleShowSalesPersonModal();
  };

  const checkButtonDisability = () => {
    if (
      values?.name === '' ||
      values?.mobile === '' ||
      values?.email === '' ||
      values?.address === '' ||
      values?.aadharNo === ''
    ) {
      return true;
    }
    if (Object.keys(errors).length > 0) {
      return true;
    }
    return false;
  };


  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <FormikProvider value={formik}>
            <IconButton className={styles.closeBtn} onClick={() => handleModalClose()}>
              <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
            </IconButton>
            <Box className={styles.formFieldWrapper}>
              <Box>
                <Typography className={styles.modalTitle}>Add Sales Person</Typography>
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>Name</Typography>
                <TextField
                  placeholder="Enter sales person name"
                  fullWidth
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.name && errors?.name && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>{errors.name}</FormHelperText>
                )}
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>Mobile Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter mobile number"
                  {...getFieldProps('mobile')}
                  type="number"
                  error={Boolean(touched.mobile && errors.mobile)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.mobile && errors?.mobile && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.mobile}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>Email</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.email && errors?.email && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.email}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>Address</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter address"
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.address && errors?.address && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.address}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>Aadhar Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter aadhar number"
                  {...getFieldProps('aadharNo')}
                  type="number"
                  error={Boolean(touched.aadharNo && errors.aadharNo)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.aadharNo && errors?.aadharNo && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.aadharNo}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Button
                  sx={{
                    background: theme.palette.primary.main,
                    '&:hover': {
                      background: theme.palette.primary.main,
                      color: '#FFF',
                    },
                  }}
                  type="submit"
                  className={styles.submitButton}
                  disabled={checkButtonDisability()}
                  onClick={() => addSalesPersonMutation.mutateAsync()}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </FormikProvider>
        </Box>
      </Slide>
    </Modal>
  );
};

export default AddSalesPersonModal;
