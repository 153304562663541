import React, { useEffect, useRef, useState } from 'react';
import { Box, CardMedia, Modal, Slide, Typography, IconButton, useTheme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import getS3Bucket from '../../utils/getS3Bucket';
import styles from './FullScreenCarouselModal.module.scss';
import { ReactComponent as NextArrow } from '../../assets/icons/nextArrow.svg';
import { ReactComponent as PreArrow } from '../../assets/icons/prevArrow.svg';

const FullScreenCarouselModal = ({ open, handleModal, mediaData, mediaType }) => {
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);
  const getMediaUrl = () => {
    if (getS3Bucket() && Boolean(mediaData?.length)) {
      return getS3Bucket() + mediaData[currentIndex];
    }
    return '';
  };

  const changeImageIndex = (step) => {
    if (step === 'next') {
      setCurrentIndex((prev) => {
        if (prev < mediaData?.length - 1) {
          return prev + 1;
        }
        return prev;
      });
      return;
    }
    setCurrentIndex((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const closeModal=()=>{
    handleModal();
    setCurrentIndex(0);
  }

  return (
    <Modal
      open={open}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
      className={styles.mainContainer}
    >
      <Slide in={open} direction="up">
        <Box className={styles.mainBody}>
          <IconButton className={styles.closeBtn} onClick={() => closeModal()}>
            <ClearOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              changeImageIndex('prev');
            }}
          >
            <PreArrow />
          </IconButton>
          <Box className={styles.mediaWrapper}>
            <CardMedia
              component={mediaType === 'Image' ? 'img' : 'video'}
              className={styles.mediaPic}
              src={getMediaUrl()}
            />
          </Box>
          <IconButton
            onClick={() => {
              changeImageIndex('next');
            }}
          >
            <NextArrow />
          </IconButton>
        </Box>
      </Slide>
    </Modal>
  );
};

export default FullScreenCarouselModal;
