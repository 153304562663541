import client from './Client';

const endPoint = '/su/gst/';

const updateStateGst = ({ stateGstId, gstNo, fssaiNumber, address }) => {
  const token = localStorage.getItem('token');
  return client.put(
    endPoint + stateGstId,
    { gstNo, fssaiNumber, address },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { updateStateGst };
