import client from './Client';

const endPoint = '/su/refund/return-order';

const createReturnOrder = ({ orderId, itemId }) => {

  const token = localStorage.getItem('token');
  return client.post(
    endPoint ,
    { orderId, itemId },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { createReturnOrder };
