// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterDrawer_mainContainer__P4p6O {\n  width: 770px;\n  background-color: #fff;\n  margin: 60px 0 60px 150px;\n}\n\n.FilterDrawer_closeBtn__HXrcH {\n  position: absolute;\n  top: 64px;\n  left: 80px;\n  padding: 0 !important;\n  z-index: 1;\n}\n\n.FilterDrawer_modalTitle__j1fms {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 30px;\n  line-height: 37px;\n  text-transform: capitalize;\n  color: #000000;\n  opacity: 0.8;\n}", "",{"version":3,"sources":["webpack://./src/components/Filter/FilterDrawer.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,sBAAA;EACA,yBAAA;AACF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,qBAAA;EACA,UAAA;AAEF;;AACA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,cAAA;EACA,YAAA;AAEF","sourcesContent":[".mainContainer {\n  width: 770px;\n  background-color: #fff;\n  margin: 60px 0 60px 150px;\n}\n.closeBtn {\n  position: absolute;\n  top: 64px;\n  left: 80px;\n  padding: 0 !important;\n  z-index: 1;\n}\n\n.modalTitle {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 30px;\n  line-height: 37px;\n  text-transform: capitalize;\n  color: #000000;\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "FilterDrawer_mainContainer__P4p6O",
	"closeBtn": "FilterDrawer_closeBtn__HXrcH",
	"modalTitle": "FilterDrawer_modalTitle__j1fms"
};
export default ___CSS_LOADER_EXPORT___;
