import client from './Client';

const endPoint = `/su/invite`;

const getInvites = (isApproved,page, limit) => {
  const token = localStorage.getItem('token');
  return client.get(
    endPoint,
    { isApproved,page, limit },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getInvites };
