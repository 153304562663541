import client from './Client';

const endPoint = '/su/category/margin/all';

const getAllMargin = () => {
 
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getAllMargin };
