import { useState } from 'react';
import {
  Modal,
  Slide,
  Box,
  IconButton,
  Typography,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import styles from './CreditNotesModal.module.scss';


const CreditNotesModal = ({ isOpen, handleCreditNotesModal, creditNotes, handleConfirmationModal,setCrNoteId,invoiceNumber,orderNumber }) => {
  const theme = useTheme();

  

  return (
    <Modal
      open={isOpen}
      className={styles.modalMainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <Box className={styles.headingContainer}>
            <IconButton
              className={styles.closeBtn}
              onClick={() => {
                handleCreditNotesModal();
              }}
            >
              <ClearOutlinedIcon
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
            <Typography className={styles.mainHeading}>Credit Notes</Typography>
          </Box>
          <Box className={styles.subHeadWrapper}>
            <Box className={styles.subHeadingMain}>
              <Typography className={styles.subHeadTextMain}>Invoice No </Typography>
              <Typography className={styles.subHeadTextMain}>Order No </Typography>
            </Box>
            <Box className={styles.subHeadingValue}>
              <Typography className={styles.subHeadTextValue}>{invoiceNumber}</Typography>
              <Typography className={styles.subHeadTextValue}>{orderNumber}</Typography>
            </Box>
          </Box>

          {/** Credit Notes Table */}
          <TableContainer sx={{ marginTop: '15px' }}>
            <Table>
              <TableHead>
                <TableRow className={styles.tableHead}>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography className={styles.tableHeadText}>No</Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography className={styles.tableHeadText}>Credit Note No</Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography className={styles.tableHeadText}>Amount ₹</Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography className={styles.tableHeadText}>Date</Typography>
                  </TableCell>
                  <TableCell sx={{ border: 'none' }}>
                    <Typography className={styles.tableHeadText}>Status</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: 'center' }}>
                {creditNotes.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography className={styles.tableBobyText}>{index + 1}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.tableBobyText}>{item.creditNumber}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.tableBobyText}>{item.creditAmount}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.tableBobyText}>
                          {item.createdAt.slice(0, 10).split('-').reverse().join('-')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={styles.tableBobyText}>
                          {item.creditStatus === 'Raised' ? (
                            <Button
                              className={styles.enabledButton}
                              onClick={() => {
                                setCrNoteId(item._id)
                                handleConfirmationModal();
                              }}
                            >
                              Settle
                            </Button>
                          ) : (
                            item.creditStatus
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Slide>
    </Modal>
  );
};

export default CreditNotesModal;
