import React, { Fragment, useState, useEffect } from "react";
import { Box, Pagination, Slide, useTheme } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

const PaginatedComponent = ({
    child,
    pageCount,
    ref,
    items,
    entryProp,
    elementPerPage,
    sx,
}) => {
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const [slideIn, setSlideIn] = useState(true);
    const [slideDirection, setSlideDirection] = useState("right");

    const handleChange = (event, value) => {
        if (value > page) {
            setSlideDirection("left");
        } else {
            setSlideDirection("right");
        }
        setSlideIn(false);
        setPage(value);
    };

    useEffect(() => {
        if (pageCount < page) {
            setPage(1);
        }
    }, [pageCount]);

    return (
        <>
            <TransitionGroup style={{ overflowX: "hidden" }}>
                {Array.from(Array(pageCount).keys())
                    .slice(page - 1, page)
                    .map((pageItem, pageIndex) => {
                        return (
                            <Slide
                                key={pageItem}
                                direction={slideDirection}
                                in={slideIn}
                                mountOnEnter
                                unmountOnExit
                                appear={false}
                            >
                                <Box
                                    key={(page - 1 + elementPerPage) * page}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                        ...sx,
                                    }}
                                >
                                    {items
                                        .slice((page - 1) * elementPerPage, page * elementPerPage)
                                        .map((item, index) => {
                                            const objectToClone = { pageNumber: page, index };
                                            if (entryProp) {
                                                objectToClone[entryProp] = item;
                                            }
                                            return (
                                                <Fragment
                                                    key={(page - 1 + elementPerPage) * page + index}
                                                >
                                                    {React.cloneElement(child, objectToClone)}
                                                </Fragment>
                                            );
                                        })}
                                </Box>
                            </Slide>
                        );
                    })}
            </TransitionGroup>
            <Box
                sx={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {pageCount > 1 && (
                    <Pagination
                        count={pageCount}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        color={"primary"}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                color: theme.palette.primary.main,
                                background: "white",
                                backgroundColor: "white",
                            },
                            "& .Mui-selected": {
                                color: "white !important",
                                background: `${theme.palette.primary.main} !important`,
                                backgroundColor: `${theme.palette.primary.main} !important`,
                                "&:hover": {
                                    color: "white",
                                    background: theme.palette.primary.main,
                                    backgroundColor: theme.palette.primary.main,
                                    "&:hover": {
                                        color: "white",
                                        background: theme.palette.primary.main,
                                        backgroundColor: theme.palette.primary.main,
                                    },
                                },
                            },
                        }}
                    />
                )}
            </Box>
        </>
    );
};

export default PaginatedComponent;
