import { Box, Button, Modal, Slide, Typography, useTheme } from "@mui/material";
import styles from "./ConfirmationModal.module.scss";

const ConfirmationModal = ({
    isOpen,
    handleConfirmationModal,
    onConfirm,
    modalText
}) => {

    const theme = useTheme();

    return (
        <Modal
            open={isOpen}
            closeAfterTransition
            className={styles.modalMainContainer}
            disableAutoFocus
            sx={{
                '.MuiBackdrop-root': {
                    background: 'none',
                },
            }}
        >
            <Slide in={isOpen} direction="up">
                <Box className={styles.modalBody}>
                    <Typography className={styles.title}>
                        {modalText}
                    </Typography>
                    <Box className={styles.buttonContainer}>
                        <Button
                            className={`${styles.btn} ${styles.noBtn}`}
                            sx={{
                                border: `1px solid ${theme.palette.primary.main}`,
                                color: theme.palette.primary.main,
                                "&:hover": {
                                    color: theme.palette.primary.main,
                                    background: "#FFF",
                                }
                            }}
                            onClick={() => 
                                handleConfirmationModal()}
                        >
                            No
                        </Button>
                        <Button
                            className={`${styles.btn} ${styles.yesBtn}`}
                            onClick={() => {
                                onConfirm();
                                handleConfirmationModal();
                            }}
                            sx={{
                                background: theme.palette.primary.main,
                                color: "#FFF",
                                "&:hover": {
                                    background: theme.palette.primary.main,
                                    color: "#FFF",
                                }
                            }}
                        >
                            Yes
                        </Button>
                    </Box>
                </Box>
            </Slide>
        </Modal >
    );
}

export default ConfirmationModal;
