import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Divider } from '@mui/material';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';

import account from '../../_mock/account';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import navConfig from './NavConfig';
import styles from '../../pages/Dashboard/Dashboard.module.scss';

const DRAWER_WIDTH = '300px';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();
  const [checked, setChecked] = useState(window?.location?.search);

  const handleChange = () => {
    if (!window?.location?.search) {
      navigate({
        pathname: '/dashboard/app',
        search: '?env=Prod',
      });
    } else {
      navigate({
        pathname: '/dashboard/app',
      });
    }
    setChecked(window?.location?.search);

    window?.location?.reload();
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          marginX: '30px',
          marginY: '25px',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Logo />
          <Divider flexItem orientation="vertical" sx={{ borderRight: '1px solid #CACACA', marginX: '9px' }} />
          <Typography
            sx={{
              fontSize: 20,
              lineHeight: '100%',
              color: '#757575',
            }}
          >
            Mission Control
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '8px',
          }}
        >
          <Typography className={checked ? styles.notChecked : styles.checked}>Test</Typography>
          <ToggleSwitch checked={window?.location?.search?.includes('env=Prod')} onChange={() => handleChange()} />
          <Typography className={checked ? styles.checked : styles.notChecked}> Production</Typography>
        </Box>
      </Box>

      <NavSection navConfig={navConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: 280 },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 300,
              bgcolor: 'white !important',
              boxShadow: '4px 4px 7px rgba(0, 0, 0, 0.05)',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
