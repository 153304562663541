import client from './Client';

const endPoint = '/su/refund';

const getRefund = ({ type, page, limit }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    { storeType: type, page, limit },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export default getRefund;
