import client from './Client';

const endPoint = '/su/category/all';

const getCategoryAndSubCategories = ({ type, fetchFor, page }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    { type, fetchFor, page, limit: 10 },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getCategoryAndSubCategories };
