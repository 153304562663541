import client from './Client';

const endPoint = '/su/category/gst/all';

const getAllGst = ({ type, gstExists, page }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      type,
      gstExists,
      page,
      limit: 10,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getAllGst };
