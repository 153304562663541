import client from './Client';

const endPoint = '/su/category/all';

const getAllCategories = ({ type, gstExists, page, limit }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      type,
      gstExists,
      page,
      limit
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getAllCategories };
