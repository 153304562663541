import { Avatar, Box, Divider, IconButton, Modal, Slide, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PaginatedComponent from '../PaginatedComponent/PaginatedComponent';
import getS3Bucket from "../../utils/getS3Bucket";
import styles from './ViewLiveGroupModal.module.scss';

const elementsPerPage = 5;
const elementPerPageOneOnOne = 9;

const ViewVideoLiveGroupModal = ({ isOpen, handleViewVideoModal, LiveVideoData, isSuccess }) => {
  const theme = useTheme();

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      disableAutoFocus
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
      className={styles.mainContainer}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <IconButton className={styles.closeBtn} onClick={() => handleViewVideoModal()}>
            <ClearOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
              }}
            />
          </IconButton>
          {isSuccess && (
            <>
              <Typography className={styles.title}>View Listing - {LiveVideoData?.session} Session</Typography>

              <Box className={styles.videoMainContainer}>
                <Box className={styles.contentSection}>
                  <Typography className={styles.contentTitle}>Title</Typography>
                  <Typography className={styles.contentDesc}>{LiveVideoData?.title}</Typography>
                </Box>

                <Box className={styles.contentSection}>
                  <Typography className={styles.contentTitle}>Thumbnail</Typography>
                  <Box className={styles.thumbnailSection}>
                    <Avatar
                      className={`${styles.animatedBackground} ${styles.thumbnail}`}
                      variant="rounded"
                      src={
                        getS3Bucket() && LiveVideoData?.thumbnail
                          ? getS3Bucket() + LiveVideoData.thumbnail
                          : ''
                      }
                    />
                  </Box>
                </Box>

                <Box className={styles.contentSection}>
                  <Typography className={styles.contentTitle}>Listing description</Typography>
                  <Typography className={styles.contentDesc}>{LiveVideoData?.description}</Typography>
                </Box>

                <Box className={styles.contentSection}>
                  <Typography className={styles.contentTitle}>Session Type</Typography>
                  <Box className={styles.sessionCountContainer}>
                    <Typography className={styles.contentDesc}>
                      {LiveVideoData?.session} Session
                      {LiveVideoData?.typeOfListing ? `- ${LiveVideoData?.typeOfListing}` : ''}
                    </Typography>

                    {Boolean(LiveVideoData?.session === 'Multiple' && LiveVideoData?.typeOfSession === 'Group') && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                          height: '30px',
                        }}
                      />
                    )}
                    {Boolean(LiveVideoData?.session === 'Multiple' && LiveVideoData?.typeOfSession === 'Group') && (
                      <Typography className={styles.contentDesc}>
                        No. of sessions-
                        {(LiveVideoData?.slotsForGroups || []).length}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className={styles.contentSection}>
                  {Boolean(LiveVideoData?.session === 'Single' && LiveVideoData?.typeOfSession === 'Group') && (
                    <Typography className={styles.contentTitle}>Slot Offered</Typography>
                  )}

                  {LiveVideoData?.typeOfSession === 'One on One' && (
                    <Typography className={styles.contentTitle}>Slots Offered</Typography>
                  )}

                  {Boolean(LiveVideoData?.session === 'Single' && LiveVideoData?.typeOfSession === 'Group') && (
                    <Box className={styles.slotContainer}>
                      {(LiveVideoData?.slotsForGroups[0]?.slot || []).map((item, index) => {
                        return (
                          <Box key={index}>
                            <SlotTag date={item.date} time={item.time} />
                          </Box>
                        );
                      })}
                    </Box>
                  )}

                  {Boolean(LiveVideoData?.typeOfSession === 'One on One') && (
                    <>
                      <Box className={styles.paginationMain}>
                        <PaginatedComponent
                          pageCount={
                            (LiveVideoData?.slotsForOneOnOne || []).length > 0
                              ? Math.ceil((LiveVideoData?.slotsForOneOnOne || []).length / elementPerPageOneOnOne)
                              : 0
                          }
                          items={LiveVideoData?.slotsForOneOnOne || []}
                          entryProp="slot"
                          child={<SlotOfferedOneOnOne />}
                          elementPerPage={elementPerPageOneOnOne}
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3,1fr)',
                          }}
                        />
                      </Box>
                    </>
                  )}

                  {Boolean(LiveVideoData?.typeOfListing === 'Course') && (
                    <>
                      <Box className={styles.slotCourseContainer}>
                        <Typography className={styles.slotTitle} sx={{ gap: '10px !important' }}>
                          Slots Offered
                        </Typography>
                      </Box>
                      <PaginatedComponent
                        pageCount={
                          (LiveVideoData?.slotsForGroups || []).length > 0
                            ? Math.ceil((LiveVideoData?.slotsForGroups || []).length / elementsPerPage)
                            : 0
                        }
                        items={LiveVideoData?.slotsForGroups || []}
                        entryProp="slot"
                        child={<SlotPagination />}
                        elementPerPage={elementsPerPage}
                      />
                    </>
                  )}

                  {Boolean(LiveVideoData?.typeOfListing === 'Independent Classes') && (
                    <>
                      <Box className={styles.slotIndependentContainer}>
                        <Typography className={styles.slotTitle}>Slot </Typography>
                        <Typography className={styles.slotTitle}>Session Description </Typography>
                        <Typography className={styles.slotTitle}>Class fee</Typography>
                      </Box>
                      <PaginatedComponent
                        pageCount={
                          (LiveVideoData?.slotsForGroups || []).length > 0
                            ? Math.ceil((LiveVideoData?.slotsForGroups || []).length / elementsPerPage)
                            : 0
                        }
                        items={LiveVideoData?.slotsForGroups || []}
                        entryProp="slot"
                        child={<SlotOfferedPagination />}
                        elementPerPage={elementsPerPage}
                      />
                    </>
                  )}

                  {Boolean(LiveVideoData?.typeOfSession === 'One on One' && LiveVideoData?.session === 'Multiple') && (
                    <Box>
                      <Typography className={styles.contentTitle}>Session Description</Typography>
                      <Box>
                        {(LiveVideoData?.slotsOneOnOneDescription || []).map((item, index) => {
                          return (
                            <Typography className={styles.sessionDesc} key={index}>
                              Session {index + 1}- {item.description}.
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                </Box>
                {Boolean(LiveVideoData?.typeOfSession === 'Group') && (
                  <Box className={styles.contentSection}>
                    <Typography className={styles.contentTitle}>Maximum No. of participants</Typography>
                    <Typography className={styles.contentDesc}>{LiveVideoData?.maxNoOfParticipants}</Typography>
                  </Box>
                )}
                {Boolean(LiveVideoData?.typeOfListing !== 'Independent Classes') && (
                  <Box className={styles.contentSection}>
                    <Typography className={styles.contentTitle}>Session cost per participants</Typography>
                    <Typography className={styles.contentDesc}>₹{LiveVideoData?.cost}</Typography>
                  </Box>
                )}
                {Boolean(LiveVideoData?.searchKeywords?.length) && (
                  <Box className={styles.contentSection} sx={{ border: 'none !important' }}>
                    <Typography className={styles.contentTitle}>Search Keywords</Typography>
                    <Box className={styles.searchArea}>
                      {(LiveVideoData?.searchKeywords || []).map((item, index) => {
                        return (
                          <Typography className={styles.colorBox} key={index}>
                            {item}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

const SlotPagination = ({ pageNumber = 1, slot, index = 0 }) => {
  const theme = useTheme();
  return (
    <Box className={styles.slotCourseContainer} key={(pageNumber - 1 + elementsPerPage) * pageNumber + index}>
      <Box>
        <SlotTag
          date={slot?.slot[0]?.date || ''}
          time={slot?.slot[0]?.time || ''}
          sx={{
            height: '50px',
          }}
        />
      </Box>
      <Box
        className={styles.slotDesc}
        sx={{
          paddingX: '10px',
          paddingY: '5px',
          height: '50px',
        }}
      >
        <Typography className={styles.slotDescription}>{slot?.description}</Typography>
      </Box>
    </Box>
  );
};

const SlotOfferedPagination = ({ pageNumber = 1, slot, index = 0 }) => {
  const theme = useTheme();
  return (
    <Box className={styles.slotIndependentContainer} key={(pageNumber - 1 + elementsPerPage) * pageNumber + index}>
      <Box>
        <SlotTag
          date={slot?.slot[0]?.date || ''}
          time={slot?.slot[0]?.time || ''}
          sx={{
            maxHeight: '50px',
            minHeight: '50px',
          }}
        />
      </Box>
      <Box
        className={styles.slotDesc}
        sx={{
          maxHeight: '50px',
          paddingX: '11px',
          paddingY: '5px',
        }}
      >
        <Typography className={styles.slotDescription}>{slot?.description}</Typography>
      </Box>
      <Box
        className={styles.slotDesc}
        sx={{
          paddingX: '10px',
          maxHeight: '50px',
        }}
      >
        <Typography className={styles.slotDescription}>₹{slot?.cost || ''}</Typography>
      </Box>
    </Box>
  );
};

const SlotOfferedOneOnOne = ({ pageNumber = 1, slot, index = 0 }) => {
  return (
    <Box key={(pageNumber - 1 + elementPerPageOneOnOne) * pageNumber + index}>
      <SlotTag
        date={slot?.[0]?.date || ''}
        time={slot?.[0]?.time || ''}
        type={'One on One'}
        sx={{
          background: 'rgba(255, 166, 62, 0.05)',
          border: '1px solid #FFA63E',
          '.css-mgsrif-MuiTypography-root': {
            color: '#FFA63E',
          },
        }}
      />
    </Box>
  );
};

const SlotTag = ({ date, time, type, onRemoveSlot, index, sx }) => {
  return (
    <Box className={`${styles.slotTag} ${type === 'One on One' && styles.oneOnOneTag}`} sx={{ ...sx }}>
      <Typography className={styles.dayText}>
        {moment(date).format('ddd')} {moment(date).format('DD-MM-YYYY')}
      </Typography>
      <Typography className={`${styles.timeText} ${type === 'One on One' && styles.oneOnOnetimeText}`}>
        {time?.replace(/\s/g, '')}
      </Typography>
    </Box>
  );
};

export default ViewVideoLiveGroupModal;
