// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductOrderCard_th__iRFQd {\n  border: 0;\n  padding-left: 0px;\n  padding-right: 0px;\n  font-weight: 500;\n  font-size: 14;\n  line-height: 18px;\n  color: #3e3e3e;\n}\n\n.ProductOrderCard_td__qQwHQ {\n  border: 0;\n  padding: 2px 0 2px 0;\n  font-weight: 500;\n  font-size: 14;\n  line-height: 18px;\n  color: #5e5e5e;\n  vertical-align: top;\n}\n\n.ProductOrderCard_divider__8vLJk {\n  border-right: 1px solid #cacaca;\n  margin: 2px 12px 2px 12px;\n}\n\n.ProductOrderCard_value__iflKi {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 18px;\n  color: #5e5e5e;\n}", "",{"version":3,"sources":["webpack://./src/components/Custom/ProductOrderCard/ProductOrderCard.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,SAAA;EACA,oBAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,+BAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".th {\n  border: 0;\n  padding-left: 0px;\n  padding-right: 0px;\n  font-weight: 500;\n  font-size: 14;\n  line-height: 18px;\n  color: #3e3e3e;\n}\n\n.td {\n  border: 0;\n  padding: 2px 0 2px 0;\n  font-weight: 500;\n  font-size: 14;\n  line-height: 18px;\n  color: #5e5e5e;\n  vertical-align: top;\n}\n\n.divider {\n  border-right: 1px solid #cacaca;\n  margin: 2px 12px 2px 12px;\n}\n\n.value {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 18px;\n  color: #5e5e5e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th": "ProductOrderCard_th__iRFQd",
	"td": "ProductOrderCard_td__qQwHQ",
	"divider": "ProductOrderCard_divider__8vLJk",
	"value": "ProductOrderCard_value__iflKi"
};
export default ___CSS_LOADER_EXPORT___;
