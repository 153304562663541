import client from './Client';

const getTotalStoreInvoiceCount = ({ id, type }) => {
  const endPoint = `/su/invoice/store/${id}/total`;

  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      type,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getTotalStoreInvoiceCount };
