import client from './Client';

const endPoint = '/su/transaction/payments/total';

const getTotalPaymentsCount = ({ type }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    { type },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getTotalPaymentsCount };
