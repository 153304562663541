import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useMutation } from 'react-query';
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, useTheme } from '@mui/material';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { login } from '../../../api/Login';

export default function LoginForm() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      loginMutation.mutateAsync({
        email: values.email,
        password: values.password,
      });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const loginMutation = useMutation('login', ({ email, password }) => login(email, password), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
          setSubmitting(false);
        } else if (data?.success) {
          localStorage.setItem('token', data?.data?.accessToken);
          navigate('/dashboard/app', { replace: true });
        }
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            background: theme.palette.primary.main,
            "&:hover": {
              background: theme.palette.primary.main
            }
          }}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
