import { useState } from 'react';
import { Modal, Slide, Typography, Box, IconButton, useTheme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import styles from './StoreInfoStyle.module.scss';
import StoreMedia from '../Custom/StoreDetail/StoreMedia';
import StoreGeneralInfo from '../Custom/StoreDetail/StoreGeneralInfo';
import StoreAddress from '../Custom/StoreDetail/StoreAddress';
import StoreBankDetails from '../Custom/StoreDetail/StoreBankDetails';
import StoreBusinessParameters from '../Custom/StoreDetail/StoreBusinessParameters';

const tabs = [
  { id: '1', title: 'Media' },
  { id: '2', title: 'General Information' },
  { id: '3', title: 'Addresses' },
  { id: '4', title: 'Banking & GST' },
  { id: '5', title: 'Business Parameters' },
];

const videoTabs = [
  { id: '1', title: 'Media' },
  { id: '2', title: 'General Information' },
  { id: '3', title: 'Addresses' },
  { id: '4', title: 'Banking & GST' },
];

const StoreInfoModal = ({ isOpen, handleStoreModal, storeInfo, storeType }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(tabs?.[0]?.title);

  const settingColor = {
    Food: theme.palette.food.main,
    Product: theme.palette.product.main,
    Video: theme.palette.video.main,
  };

  return (
    <Modal
      open={isOpen}
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <IconButton
            className={styles.closeBtn}
            onClick={() => {
              setActiveTab(tabs?.[0]?.title);
              handleStoreModal();
            }}
          >
            <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
          </IconButton>
          <Box className={styles.tabContainer}>
            {(storeType !== 'Video' ? tabs : videoTabs).map((item, index) => {
              return (
                <Box className={styles.tab} key={index}>
                  <Typography className={styles.tabText} onClick={() => setActiveTab(item?.title)}>
                    {item.title}
                  </Typography>
                  {activeTab === item?.title && (
                    <Box
                      className={styles.tabIndicator}
                      sx={{
                        backgroundColor: settingColor[storeType],
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>

          {activeTab === 'Media' && (
            <StoreMedia bannerImage={storeInfo?.media?.coverPic} introVideo={storeInfo?.media?.video} />
          )}

          {activeTab === 'General Information' && (
            <StoreGeneralInfo storeType={storeType} storeInfo={storeInfo?.generalInformation} />
          )}

          {activeTab === 'Addresses' && (
            <StoreAddress
              residentialAddress={storeInfo?.addresses?.residentialAddress}
              pickUpAddress={storeInfo?.addresses?.pickUpAddress}
              storeType={storeType}
            />
          )}

          {activeTab === 'Banking & GST' && <StoreBankDetails bankDetails={storeInfo?.bankDetails} />}

          {activeTab === 'Business Parameters' && (
            <StoreBusinessParameters
              businessDays={storeInfo?.businessParameters?.businessDays}
              preparationTime={storeInfo?.businessParameters?.preparationTime}
              businessTiming={storeInfo?.businessParameters?.businessTimings}
              open24Hours={storeInfo?.businessParameters?.open24Hours}
            />
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

export default StoreInfoModal;
