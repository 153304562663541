import client from './Client';

const endPoint = '/su/store/all';

const getAllStores = (page, limit) => {
  //   const { client } = useClient(process.env.AUTH_SERVER || '');
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      page,
      limit
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getAllStores };
