import client from './Client';

const endPoint = '/su/refund/total';

const getTotalRefundCount = ({ type }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    { storeType: type },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getTotalRefundCount };
