import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, IconButton, Typography, useTheme, TablePagination } from '@mui/material';
import useModal from '../../../hooks/useModal';
import useLoader from '../../../hooks/useLoader';
import Page from '../../../components/Page';
import InProcessStoreInvoicesTable from '../../../components/Custom/StoreInvoiceTable/InProcessStoreInvoiceTable';
import SettledStoreInvoicesTable from '../../../components/Custom/StoreInvoiceTable/SettledStoreInvoiceTable';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { ReactComponent as LeftArrow } from '../../../assets/icons/leftArrow.svg';
import styles from '../../../pageStyles/pageStyles.module.scss';
import { getStoreInvoices } from '../../../api/GetStoreInvoice';
import Loader from '../../../components/Loader/Loader';
import { settleTransaction } from '../../../api/SettleTransaction';
import { getTotalStoreInvoiceCount } from '../../../api/GetTotalStoreInvoicesCount';

const invoicesType = ['Invoices Under Process', 'Invoices Settled'];

const StoreInvoices = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(invoicesType[0]);
  const [invoiceId, setInvoiceId] = useState();
  const [userId, setUserId] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { confirmationModal, handleConfirmationModal } = useModal();
  const { loader, showLoader, hideLoader } = useLoader();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const setId = (invoiceId, userId) => {
    setInvoiceId(invoiceId);
    setUserId(userId);
  };

  const handleChangeTab = (item) => {
    setActiveTab(item);
    setPage(1);
    setRowsPerPage(10);
  };

  const { data, isLoading, refetch } = useQuery(
    ['getStoreInvoices', id, activeTab, page, rowsPerPage],
    () =>
      getStoreInvoices({
        id,
        status: activeTab === 'Invoices Under Process' ? 'Under Process' : 'Settled',
        page,
        limit: rowsPerPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  const invoiceData = data?.data?.data;

  // Get Total Store Invoices Count
  const { data: storeInvoiceCounts, isLoading: storeInvoiceCountLoading } = useQuery(
    ['getTotalStoreInvoiceCount', activeTab],
    () => getTotalStoreInvoiceCount({ id, type: activeTab === 'Invoices Under Process' ? 'Under Process' : 'Settled' }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  const storeInvoiceCountVal = storeInvoiceCounts?.data?.data;

  const settleInvoiceMutation = useMutation(
    'settleTransaction',
    ({ invoiceId, userId }) => settleTransaction({ id: invoiceId, userId }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        refetch();
        hideLoader();
      },
      onError: (error) => {
        console.log('error', error);
        refetch();
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, data]);

  return (
    <Page title="Invoices">
      {Boolean(!loader && data?.data?.success) && (
        <>
          <Box>
            <Box className={styles.dFlex}>
              <IconButton className={styles.icon} onClick={() => navigate('/dashboard/invoices')}>
                <LeftArrow />
              </IconButton>
              <Typography className={styles.linkText} onClick={() => navigate('/dashboard/invoices')}>
                Back
              </Typography>
            </Box>
            <Box className={styles.showFlex}>
              <Typography className={styles.storeText}>{invoiceData?.store?.name}</Typography>
              <Typography
                className={styles.linkText}
                onClick={() =>
                  navigate({
                    pathname: `/dashboard/invoices/ledger/${id}`,
                    search: `?storeName=${invoiceData?.store?.name}`,
                  })
                }
              >
                View Ledger
              </Typography>
            </Box>
          </Box>

          <Box className={styles.invoiceTypeBox}>
            {invoicesType.map((item, index) => {
              return (
                <Box className={styles.tab} key={index}>
                  <Typography
                    className={styles.tabText}
                    onClick={() => handleChangeTab(item)}
                    sx={{
                      color:
                        activeTab === item
                          ? theme.palette[(invoiceData?.store?.type).toLowerCase()].main
                          : 'rgba(36, 36, 36, 0.8)',
                    }}
                  >
                    {item}
                  </Typography>
                  {activeTab === item && (
                    <Box
                      className={styles.tabIndicator}
                      sx={{
                        backgroundColor: theme.palette[(invoiceData?.store?.type).toLowerCase()].main,
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
          {Boolean(invoiceData?.invoice?.length) && (
            <Card className={styles.invoiceCard}>
              {activeTab === 'Invoices Under Process' && (
                <InProcessStoreInvoicesTable
                  inProcessInvoices={invoiceData?.invoice}
                  handleConfirmationModal={handleConfirmationModal}
                  setId={setId}
                />
              )}
              {activeTab === 'Invoices Settled' && <SettledStoreInvoicesTable settledInvoices={invoiceData?.invoice} />}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={storeInvoiceCountVal?.count ? storeInvoiceCountVal?.count : 0}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </>
      )}

      {loader && <Loader isLoaderVisible={loader} />}

      {confirmationModal && (
        <ConfirmationModal
          isOpen={confirmationModal}
          handleConfirmationModal={handleConfirmationModal}
          modalText={'Are you sure you want to settle Invoice?'}
          onConfirm={() => {
            settleInvoiceMutation.mutateAsync({ invoiceId, userId });
            showLoader();
          }}
        />
      )}
    </Page>
  );
};

export default StoreInvoices;
