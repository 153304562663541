import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { getCategoryAndSubCategories } from '../../api/GetCategoryAndSubCategories';
import { updateSubCateory } from '../../api/UpdateSubCategory';
import { getSubCategory } from '../../api/GetSubCategory';
import { createSubCategory } from '../../api/CreateSubCategory';
import styles from './SubCategory.module.scss';
import { ReactComponent as DownArrow } from '../../assets/icons/downArrow.svg';

// const typeOfStore = {
//   Food: ['Home Kitchen', 'Home Café', 'Pet Café', 'Home Store'],
//   Product: ['Home Store', 'Home Boutique', 'Home Gallery'],
//   Video: ['Home Studio', 'Home Office', 'Home Classes'],
// };

const AddSubCategoryModal = ({ isOpen, handleSubCategoryModal, refetchData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { data: subCategoryData, isLoading: subCategoryLoading } = useQuery(
    'getSubCategory',
    () => getSubCategory({ catId: params.get('catId'), subCatId: params.get('subCatId') }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(params.get('catId')) && Boolean(params.get('subCatId')),
    }
  );
  const subCategory = subCategoryData?.data?.data?.[0];

  // Schema Validation
  const CategorySchema = Yup.object().shape({
    name: Yup.string().required('Sub-Category name is required'),
    type: Yup.string().required('Business type is required'),
    category: Yup.string().required('Category is required'),
    typeOfStore: Yup.array().min(1, 'HBID is required'),
    hsnCode: Yup.string()
      .required('HSN/SAC code is required')
      .test('hsnCodeLenght', 'HSN/SAC code can be of  6 to 8 letters', (value) => {
        if (value.length === 6 || value.length === 8) {
          return true;
        }
        return false;
      }),
    // .max(8, 'HSN/SAC code can be of  6/8 letters')
    // .min(6, 'HSN/SAC code can be of  6/8 letters')
  });

  const formik = useFormik({
    initialValues: {
      name: params.get('subCatId') ? subCategory?.subcategory?.name : '',
      type: params.get('subCatId') ? subCategory?.type : '',
      category: params.get('subCatId') ? params.get('catId') : '',
      typeOfStore: params.get('subCatId') ? subCategory?.subcategory?.typeOfStore || [] : [],
      hsnCode: params.get('subCatId') ? subCategory?.subcategory?.hsnCode : '',
    },
    validationSchema: CategorySchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      createSubCategoryMutation.mutateAsync({
        name: values.name,
        category: values.category,
        hsnCode: values.hsnCode,
        typeOfStore: values.typeOfStore,
      });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, setFieldValue, resetForm } = formik;

  const returnFunc = () => {
    resetForm();
    if (params?.get('env')) {
      navigate({
        pathname: '/dashboard/subcategories',
        search: '?env=Prod',
      });
    } else {
      navigate({
        pathname: '/dashboard/subcategories',
        search: null,
      });
    }
    handleSubCategoryModal();
  };

  const { data, isLoading, refetch } = useQuery(
    ['getCategoryAndSubCategories', values.type],
    () =>
      getCategoryAndSubCategories({
        type: values.type,
        fetchFor: 'subCategory',
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(values.type),
    }
  );

  const categoryData = data?.data?.data;

  const createAndUpdateSubCategory = async ({ name, hsnCode, category, typeOfStore }) => {
    const subCategoryId = params.get('subCatId');

    if (subCategoryId) {
      return updateSubCateory({ catId: category, subCatId: subCategoryId, hsnCode, name, typeOfStore });
    }
    return createSubCategory({ catId: category, hsnCode, name, typeOfStore });
  };

  const createSubCategoryMutation = useMutation(
    'createCategory',
    ({ category, hsnCode, name, typeOfStore }) => createAndUpdateSubCategory({ category, hsnCode, name, typeOfStore }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        returnFunc();
        refetchData();
      },
      onError: (error) => {
        console.log('error', error);
        returnFunc();
      },
    }
  );

  const [typeOfStores, setTypeOfStores] = useState([]);

  useEffect(() => {
    if (params.get('subCatId')) {
      const cat = (categoryData || []).filter((x) => x._id === values.category)[0]?.typeOfStore;
      setTypeOfStores(cat);
    }
  }, [categoryData, values.category, params.get('subCatId')]);

  return (
    <>
      <Modal
        open={isOpen}
        closeAfterTransition
        className={styles.mainContainer}
        sx={{
          '.MuiBackdrop-root': {
            background: 'none',
          },
        }}
      >
        <Slide in={isOpen} direction="up">
          <Box className={styles.modalBody}>
            <Box className={styles.titleContainer}>
              <Typography className={styles.modalTitle}>
                {params.get('subCatId') ? 'Update Sub-Category' : 'Add Sub-Category'}
              </Typography>
              <Button
                className={styles.submitButton}
                sx={{
                  background: theme.palette.primary.main,
                  '&:hover': {
                    background: theme.palette.primary.main,
                  },
                }}
                disabled={Object.keys(errors)?.length}
                onClick={() => handleSubmit()}
              >
                {params.get('subCatId') ? 'Update Sub-Category' : 'Create Sub-Category'}
              </Button>
            </Box>
            {
              <IconButton className={styles.closeBtn} onClick={() => returnFunc()}>
                <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
              </IconButton>
            }
            <FormikProvider value={formik}>
              <Card
                sx={{
                  borderRadius: '5px',
                  padding: '10px',
                  marginY: '30px',
                  boxShadow: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Stack spacing={3}>
                  <FormControl fullWidth>
                    <Typography className={styles.inputTitle}>Select Vertical</Typography>
                    <Select
                      labelId="type"
                      id="type"
                      value={values.type}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('typeOfStore', []);
                      }}
                      name="type"
                      error={Boolean(touched.type && errors.type)}
                      key={values.type}
                      sx={{
                        borderRadius: '5px',
                        '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                          padding: '11px',
                          borderRadius: '5px',
                        },
                        '.css-4xgxwl-MuiSelect-icon': {
                          top: 'auto !important',
                        },
                      }}
                      IconComponent={DownArrow}
                    >
                      <MenuItem value="Food">Food</MenuItem>
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Video">Video</MenuItem>
                    </Select>
                    {touched.type && errors.type && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                        {errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth disabled={Boolean(params.get('subCatId'))} >
                    <Typography className={styles.inputTitle}>Category</Typography>
                    <Select
                      labelId="category"
                      id="category"
                      value={values.category}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('typeOfStore', []);
                      }}
                      name="category"
                      error={Boolean(touched.category && errors.category)}
                      key={values.category}
                      sx={{
                        borderRadius: '5px',
                        '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                          padding: '11px',
                          borderRadius: '5px',
                        },
                        '.css-4xgxwl-MuiSelect-icon': {
                          top: 'auto !important',
                        },
                      }}
                      IconComponent={DownArrow}
                    >
                      {data?.data?.success
                        ? (categoryData || []).map((item, index) => {
                            return (
                              <MenuItem
                                value={item._id}
                                key={index}
                                onClick={() => {
                                  setTypeOfStores(categoryData[index]?.typeOfStore);
                                }}
                              >
                                {item?.name}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                    {touched.category && errors.category && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                        {errors.category}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {/* ////////////////////////////////////// */}
                  <FormControl fullWidth>
                    <Typography className={styles.inputTitle}>Select HBID</Typography>
                    <Select
                      labelId="typeOfStore"
                      id="typeOfStore"
                      multiple
                      value={values.typeOfStore}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="typeOfStore"
                      error={Boolean(touched.typeOfStore && errors.typeOfStore)}
                      key={values.typeOfStore}
                      sx={{
                        borderRadius: '5px',
                        '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                          padding: '11px',
                          borderRadius: '5px',
                        },
                        '.css-4xgxwl-MuiSelect-icon': {
                          top: 'auto !important',
                        },
                      }}
                      IconComponent={DownArrow}
                    >
                      {(typeOfStores || []).map((item, index) => {
                        return (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched?.typeOfStore && errors?.typeOfStore && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                        {errors.typeOfStore}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Box>
                    <Typography className={styles.inputTitle}>Sub-Category</Typography>
                    <TextField
                      fullWidth
                      autoComplete="name"
                      type="name"
                      focused={Boolean(values.name)}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      sx={{
                        borderRadius: 0,
                        input: {
                          padding: '11px',
                          borderRadius: 0,
                        },
                        '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                          borderRadius: '5px',
                        },
                        '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                          marginLeft: 0,
                        },
                      }}
                    />
                  </Box>

                  <Box>
                    <Typography className={styles.inputTitle}>HSN/SAC</Typography>
                    <TextField
                      fullWidth
                      autoComplete="hsnCode"
                      type="hsnCode"
                      focused={Boolean(values.hsnCode)}
                      {...getFieldProps('hsnCode')}
                      error={Boolean(touched.hsnCode && errors.hsnCode)}
                      helperText={touched.hsnCode && errors.hsnCode}
                      sx={{
                        borderRadius: 0,
                        input: {
                          padding: '11px',
                          borderRadius: 0,
                        },
                        '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                          borderRadius: '5px',
                        },
                        '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                          marginLeft: 0,
                        },
                      }}
                    />
                  </Box>
                </Stack>
              </Card>
            </FormikProvider>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default AddSubCategoryModal;
