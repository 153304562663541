import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { updateGst } from '../../api/UpdateGst';
import { createGst } from '../../api/CreateGst';
import { getAllCategories } from '../../api/GetAllCategory';
import { getGst } from '../../api/GetGstById';
import { getSubCategories } from '../../api/GetSubCategories';
import styles from './AddStateGst.module.scss';
import { ReactComponent as DownArrow } from '../../assets/icons/downArrow.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/addIcon.svg';
import { getStateGstById } from '../../api/GetStateGstById';
import { addStateGst } from '../../api/AddStateGst';
import { updateStateGst } from '../../api/UpdateStaeGst';
import addStateGstValidationSchema from './AddStateGstValidation';

const gstRegEx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

const states = [
  { name: 'Select state', stateGstCode: undefined },
  { name: 'Andhra Pradesh', stateGstCode: 37 },
  { name: 'Arunachal Pradesh', stateGstCode: 12 },
  { name: 'Assam', stateGstCode: 18 },
  { name: 'Bihar', stateGstCode: 10 },
  { name: 'Chhattisgarh', stateGstCode: 4 },
  { name: 'Goa', stateGstCode: 30 },
  { name: 'Gujarat', stateGstCode: 24 },
  { name: 'Haryana', stateGstCode: 6 },
  { name: 'Himachal Pradesh', stateGstCode: 2 },
  { name: 'Jharkhand', stateGstCode: 20 },
  { name: 'Karnataka', stateGstCode: 29 },
  { name: 'Kerala', stateGstCode: 32 },
  { name: 'Madhya Pradesh', stateGstCode: 23 },
  { name: 'Maharashtra', stateGstCode: 27 },
  { name: 'Manipur', stateGstCode: 14 },
  { name: 'Meghalaya', stateGstCode: 17 },
  { name: 'Mizoram', stateGstCode: 15 },
  { name: 'Nagaland', stateGstCode: 13 },
  { name: 'Odisha', stateGstCode: 21 },
  { name: 'Punjab', stateGstCode: 3 },
  { name: 'Rajasthan', stateGstCode: 8 },
  { name: 'Sikkim', stateGstCode: 11 },
  { name: 'Tamil Nadu', stateGstCode: 33 },
  { name: 'Telangana', stateGstCode: 36 },
  { name: 'Tripura', stateGstCode: 16 },
  { name: 'Uttarakhand', stateGstCode: 5 },
  { name: 'Uttar Pradesh', stateGstCode: 9 },
  { name: 'West Bengal', stateGstCode: 19 },
];

const AddStateGstModal = ({ isOpen, handleStateGstModal, setStateGstId, stateGstId, refetch }) => {
  const theme = useTheme();
  const {
    data,
    isLoading,
    refetch: refetchById,
  } = useQuery(['getStateGstById', isOpen], () => getStateGstById(stateGstId), {
    refetchOnWindowFocus: false,
    enabled: stateGstId.length > 0 && isOpen,
  });

  const categoryData = data?.data?.data;
  const [gstDataLocal, setGstDataLocal] = useState(categoryData);

  useEffect(() => {
    setGstDataLocal({
      address: stateGstId.length > 0 && categoryData ? categoryData?.address : '',
      createdAt: stateGstId.length > 0 && categoryData ? categoryData?.createdAt : '',
      fssaiNumber: stateGstId.length > 0 && categoryData ? categoryData?.fssaiNumber : '',
      gstNo: stateGstId.length > 0 && categoryData ? categoryData?.gstNo : '',
      state: stateGstId.length > 0 && categoryData ? categoryData?.state : '',
      stateGstCode: stateGstId.length > 0 && categoryData ? categoryData?.stateGstCode : '',
    });
  }, [categoryData]);

  const formik = useFormik({
    initialValues: {
      address: stateGstId.length > 0 && categoryData ? categoryData?.address : '',
      createdAt: stateGstId.length > 0 && categoryData ? categoryData?.createdAt : '',
      fssaiNumber: stateGstId.length > 0 && categoryData ? categoryData?.fssaiNumber : '',
      gstNo: stateGstId.length > 0 && categoryData ? categoryData?.gstNo : '',
      state: stateGstId.length > 0 && categoryData ? categoryData?.state : '',
      stateGstCode: stateGstId.length > 0 && categoryData ? categoryData?.stateGstCode : '',
    },
    validationSchema: addStateGstValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {},
  });
  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, setFieldValue, resetForm } = formik;

  useEffect(() => {
    if (values.state !== '') {
      for (let i = 0; i < states?.length; i += 1) {
        if (states[i].name === values.state) {
          setFieldValue('stateGstCode', states[i].stateGstCode);
          break;
        }
      }
    } else {
      setFieldValue('stateGstCode', '');
    }
  }, [values.state]);

  const addStateGstMutation = useMutation(
    'addStateGst',
    () =>
      addStateGst({
        gstNo: values.gstNo,
        state: values.state,
        stateGstCode: values.stateGstCode,
        fssaiNumber: String(values.fssaiNumber),
        address: values.address,
      }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
            handleModalClose();
            refetch();
          }
        }
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  const updateStateGstMutation = useMutation(
    'addStateGst',
    () =>
      updateStateGst({
        stateGstId,
        gstNo: values.gstNo,
        fssaiNumber: String(values.fssaiNumber),
        address: values.address,
      }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
            refetchById();
            handleModalClose();
            refetch();
          }
        }
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  const handleModalClose = () => {
    setStateGstId('');
    resetForm();
    setGstDataLocal(undefined);
    handleStateGstModal();
  };

  const addAnUpdateStateGst = () => {
    if (stateGstId === '') {
      addStateGstMutation.mutateAsync();
    } else {
      updateStateGstMutation.mutateAsync();
    }
  };

  const checkButtonDisability = () => {
    if (stateGstId === '' && (Object.keys(errors).length > 0 || Object.keys(touched).length === 0)) {
      return true;
    }
    if (
      stateGstId !== '' &&
      (JSON.stringify(values) === JSON.stringify(gstDataLocal) || Object.keys(errors).length > 0)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <FormikProvider value={formik}>
            <IconButton className={styles.closeBtn} onClick={() => handleModalClose()}>
              <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
            </IconButton>
            <Box className={styles.formFieldWrapper}>
              <Box>
                <Typography className={styles.modalTitle}>{`${
                  stateGstId === '' ? 'Add' : 'Edit'
                } State GST`}</Typography>
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>{'State - State GST Code'}</Typography>
                <Select
                  disabled={stateGstId !== ''}
                  {...getFieldProps('gstNo')}
                  value={values?.state === '' ? 'Select state' : values.state}
                  name="state"
                  placeholder="Enter GST number"
                  sx={{
                    width: '100%',
                    borderRadius: '5px',
                    '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '11px',
                      borderRadius: '5px',
                    },
                    '.css-4xgxwl-MuiSelect-icon': {
                      top: 'auto !important',
                    },
                  }}
                  IconComponent={DownArrow}
                >
                  {states.map((item, index) => (
                    <MenuItem
                      value={item.name}
                      key={index}
                      sx={{ display: item?.name === 'Select state' ? 'none' : 'block' }}
                    >
                      {item.name} {item.name !== 'Select state' && '-'} {item.stateGstCode}
                    </MenuItem>
                  ))}
                </Select>
                {touched?.state && errors?.state && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.state}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>GST Number</Typography>
                <TextField
                  placeholder="Enter GST number"
                  fullWidth
                  {...getFieldProps('gstNo')}
                  error={Boolean(touched.gstNo && errors.gstNo)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.gstNo && errors?.gstNo && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.gstNo}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>FSSAI Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter FSSAI number"
                  {...getFieldProps('fssaiNumber')}
                  type="number"
                  error={Boolean(touched.fssaiNumber && errors.fssaiNumber)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.fssaiNumber && errors?.fssaiNumber && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.fssaiNumber}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Typography className={styles.inputTitle}>Address</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter ByzWiz's address"
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address)}
                  sx={{
                    borderRadius: 0,
                    input: {
                      padding: '11px',
                      borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: '0 !important',
                    },
                  }}
                />
                {touched?.address && errors?.address && (
                  <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                    {errors.address}
                  </FormHelperText>
                )}
              </Box>

              <Box>
                <Button
                  sx={{
                    background: theme.palette.primary.main,
                    '&:hover': {
                      background: theme.palette.primary.main,
                      color: '#FFF',
                    },
                  }}
                  type="submit"
                  className={styles.submitButton}
                  disabled={checkButtonDisability()}
                  onClick={addAnUpdateStateGst}
                >
                  {stateGstId === '' ? 'Add' : 'Update'}
                </Button>
              </Box>
            </Box>
          </FormikProvider>
        </Box>
      </Slide>
    </Modal>
  );
};

export default AddStateGstModal;
