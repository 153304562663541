import client from './Client';

const endPoint = '/su/store/block/';

const storeStatus = ({ status, id }) => {
  const token = localStorage.getItem('token');
  return client.put(
    endPoint + id,
    {
      blockStatus: status
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { storeStatus };
