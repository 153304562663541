import client from './Client';

const endPoint = '/su/invoice/';

const getStoreInvoices = ({ id, status, page, limit }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint + id,
    {
      status,
      page,
      limit,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getStoreInvoices };
