// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullScreenMediaModal_mainContainer__Botte {\n  background: #000 !important;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.FullScreenMediaModal_mainBody__djrMa {\n  height: 100%;\n  width: 100%;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  justify-content: center;\n}\n\n.FullScreenMediaModal_mediaPic__INLha {\n  height: 100%;\n  border-radius: 0px;\n  object-fit: contain;\n}\n\n.FullScreenMediaModal_closeBtn__c1Vzm {\n  position: absolute;\n  width: 45px;\n  height: 45px;\n  background: #fff;\n  padding: 0 !important;\n  top: 20px;\n  left: 20px;\n  margin-top: 10px;\n  z-index: 3;\n}\n\n.FullScreenMediaModal_closeBtn__c1Vzm:hover {\n  background: #fff;\n}", "",{"version":3,"sources":["webpack://./src/components/FullScreenMediaModal/FullScreenMediaModal.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,uBAAA;AAEF;;AAAA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;AAGF;;AADA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,qBAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,UAAA;AAIF;;AAFA;EACE,gBAAA;AAKF","sourcesContent":[".mainContainer {\n  background: #000 !important;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.mainBody {\n  height: 100%;\n  width: 100%;\n  background: transparent;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  justify-content: center;\n}\n.mediaPic {\n  height: 100%;\n  border-radius: 0px;\n  object-fit: contain;\n}\n.closeBtn {\n  position: absolute;\n  width: 45px;\n  height: 45px;\n  background: #fff;\n  padding: 0 !important;\n  top: 20px;\n  left: 20px;\n  margin-top: 10px;\n  z-index: 3;\n}\n.closeBtn:hover {\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "FullScreenMediaModal_mainContainer__Botte",
	"mainBody": "FullScreenMediaModal_mainBody__djrMa",
	"mediaPic": "FullScreenMediaModal_mediaPic__INLha",
	"closeBtn": "FullScreenMediaModal_closeBtn__c1Vzm"
};
export default ___CSS_LOADER_EXPORT___;
