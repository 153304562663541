import { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  Card,
  Table,
  Avatar,
  Button,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  SvgIcon,
  Box,
  useTheme,
  Stack,
  IconButton,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import getS3Bucket from '../../utils/getS3Bucket';
import useLoader from '../../hooks/useLoader';
import useModal from '../../hooks/useModal';
import UserInfoModal from '../../components/UserInfoModal/UserInfoModal';
import FullScreenMediaModal from '../../components/FullScreenMediaModal/FullScreenMediaModal';
import { getAllUsers } from '../../api/GetAllUsers';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import IOSSwitch from '../../components/IOSSwitch/IOSSwitch';
import { userStatus } from '../../api/UserStatus';
import { deleteUser } from '../../api/DeleteUser';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import Loader from '../../components/Loader/Loader';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import FilterDrawer from '../../components/Filter/FilterDrawer';
import styles from '../../pageStyles/pageStyles.module.scss';
import { getUserDetails } from '../../api/GetUserDetails';
import { getTotalUsersCount } from '../../api/GetTotalUsersCount';

export default function User() {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userId, setUserId] = useState();
  const [role, setRole] = useState();
  const [docUrl, setDocUrl] = useState('');

  const {
    showModal,
    handleModalState,
    confirmationModal,
    handleConfirmationModal,
    filterDrawer,
    handleFilterDrawer,
    showFullScreenMediaModal,
    handleFullScreenMediaModal,
  } = useModal();
  const { loader, showLoader, hideLoader } = useLoader();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  // Get Total Users
  const { data, isLoading, refetch } = useQuery(
    ['getAllUser', page, rowsPerPage],
    () => getAllUsers({ page, limit: rowsPerPage }),
    {
      refetchOnWindowFocus: false,
    }
  );

  // Get Total Users Count
  const { data: usersCount } = useQuery('getTotalUsersCount', () => getTotalUsersCount(), {
    refetchOnWindowFocus: false,
  });

  const { data: userData, isLoading: userDataLoading } = useQuery(
    ['getUserDetails', userId, role],
    () => getUserDetails(userId, role),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(userId) && Boolean(role),
    }
  );
  const userInfo = userData?.data?.data?.[0];

  const userStatusMutation = useMutation('userStatus', ({ status, id }) => userStatus({ status, id }), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        }
        refetch();
      }
      hideLoader();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const userDeleteMutation = useMutation('userDelete', (id) => deleteUser(id), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        }
        refetch();
      }
      hideLoader();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (isLoading || userDataLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, data, userDataLoading, userData]);

  return (
    <Page title="Businesses">
      {!loader && (
        <Container className={styles.container}>
          <Stack direction="row" justifyContent="end" width="1000px" marginBottom="30px">
            <Box className={styles.filterBox}>
              <IconButton
                sx={{ padding: 0 }}
                disableRipple
                //  onClick={() => handleFilterDrawer()}
              >
                <FilterIcon />
              </IconButton>
            </Box>
          </Stack>
          <Card className={styles.card}>
            <Scrollbar>
              <TableContainer sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ background: '#F5F4F4' }}>
                      <TableCell
                        align="left"
                        width="323px"
                        className={styles.borderTableCell}
                        sx={{ paddingLeft: '20px !important' }}
                      >
                        Name
                      </TableCell>
                      <TableCell align="center" width="116px" className={styles.borderTableCell}>
                        Age Bracket
                      </TableCell>
                      <TableCell align="center" width="130px" className={styles.borderTableCell}>
                        ByzCode
                      </TableCell>
                      <TableCell align="center" width="80px" className={styles.borderTableCell}>
                        Role
                      </TableCell>
                      <TableCell align="center" width="64px" className={styles.borderTableCell}>
                        Byz
                      </TableCell>
                      <TableCell align="center" width="84px" className={styles.borderTableCell}>
                        Verified
                      </TableCell>
                      <TableCell align="center" width="96px" className={styles.borderTableCell}>
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        width="107px"
                        className={styles.borderTableCell}
                        sx={{ borderRight: '0 !important' }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {data?.data.success && (
                    <TableBody>
                      {(data?.data?.data || []).map((item, index) => {
                        return (
                          <TableRow key={item._id}>
                            <TableCell className={styles.borderTableDataCell} sx={{ paddingLeft: '20px !important' }}>
                              <Box className={styles.dFlex}>
                                <Avatar
                                  src={encodeURI(getS3Bucket() + item.profilePic)}
                                  className={styles.avtar}
                                  variant="square"
                                />
                                <Typography
                                  className={styles.tableText}
                                  sx={{
                                    color: `${theme.palette.primary.main} !important`,
                                    cursor: 'pointer',
                                    fontWeight: '500 !important',
                                  }}
                                  onClick={() => {
                                    setUserId(item?._id);
                                    setRole(item?.role);
                                    handleModalState();
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              {item?.ageBracket}
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              <Typography
                                className={styles.tableText}
                                sx={{ color: `${theme.palette.primary.main} !important`, fontWeight: '500 !important' }}
                              >
                                {item?.byzcode ? item?.byzcode : '-'}
                              </Typography>
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              {item?.role}
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              <Typography className={styles.tableText}>{item?.businesses}</Typography>
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              <Typography className={styles.tableText}>{item?.verified}</Typography>
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              <Typography
                                className={styles.tableText}
                                sx={{
                                  color: item?.status === 'Active' ? '#00CB20 !important' : '#DA1A18 !important',
                                  fontWeight: '500 !important',
                                }}
                              >
                                {item?.status}
                              </Typography>
                            </TableCell>

                            <TableCell
                              align="center"
                              className={styles.borderTableDataCell}
                              sx={{ borderRight: '0 !important' }}
                            >
                              <Stack direction="row" alignItems="center" gap="20px" justifyContent="center">
                                <IOSSwitch
                                  checked={Boolean(item?.status === 'Active')}
                                  onChange={() => {
                                    userStatusMutation.mutateAsync({
                                      status: item.status === 'Active' ? 'Blocked' : 'Active',
                                      id: item._id,
                                    });
                                    showLoader();
                                  }}
                                />
                                <Button
                                  disableRipple
                                  sx={{
                                    '&:hover': {
                                      background: 'transparent',
                                    },
                                    padding: 0,
                                    minWidth: 'fit-content',
                                  }}
                                  onClick={() => {
                                    setUserId(item?._id);
                                    handleConfirmationModal();
                                  }}
                                >
                                  <SvgIcon>
                                    <DeleteIcon />
                                  </SvgIcon>
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={usersCount?.data?.data?.count}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      )}
      {loader && <Loader isLoaderVisible={loader} />}

      <UserInfoModal
        isOpen={Boolean(showModal && !userDataLoading)}
        handleModalState={handleModalState}
        userInfo={userInfo}
        role={role}
        setDocUrl={setDocUrl}
        handleFullScreenMediaModal={handleFullScreenMediaModal}
      />

      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={'Are you sure you wish to delete?'}
        onConfirm={() => {
          userDeleteMutation.mutateAsync(userId);
          showLoader();
        }}
      />
      <FullScreenMediaModal open={showFullScreenMediaModal} handleModal={handleFullScreenMediaModal} url={docUrl} mediaType={"Image"} />

      <FilterDrawer isOpen={filterDrawer} handleFilterDrawer={handleFilterDrawer} filterType={'user'} />
    </Page>
  );
}
