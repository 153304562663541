import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Blog from './pages/Blog/Blog';
import Login from './pages/Login/Login';
import NotFound from './pages/Page404/Page404';
import User from './pages/User';

import DashboardApp from './pages/Dashboard';
import Products from './pages/Product/Products';
import Register from './pages/Register/Register';
import Store from './pages/Store';
import Category from './pages/Category';
import Gst from './pages/Gst';
import Margin from './pages/Margin';
import Carousel from './pages/Carousel';
import Orders from './pages/Orders';
import Invoices from './pages/Invoices';
import Payments from './pages/Payments';
import Financial from './pages/Financial';
import Flags from './pages/Flags';
import Faq from './pages/Faq';
import StoreInvoices from './pages/Invoices/StoreInvoices';
import Ledger from './pages/Invoices/View Ledger';
import { StoreInverntory } from './pages/Store/Inventory';
import SubCategories from './pages/SubCategories';
import Refund from './pages/Refund';
import Propagation from './pages/Propagation';
import InvitationRequests from './pages/InvitationRequest';
import StateGST from './pages/StateGST';
import SalesPerson from './pages/SalesPerson';

const RouteConfig = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard/app" /> },
      { path: 'dashboard/app', element: <DashboardApp /> },
      { path: 'dashboard/user', element: <User /> },
      { path: 'dashboard/stores', element: <Store /> },
      { path: 'dashboard/stores/:id', element: <StoreInverntory /> },
      { path: 'dashboard/products', element: <Products /> },
      { path: 'dashboard/blog', element: <Blog /> },
      { path: 'dashboard/category', element: <Category /> },
      { path: 'dashboard/subcategories', element: <SubCategories /> },
      { path: 'dashboard/gst', element: <Gst /> },
      { path: 'dashboard/state-gst', element: <StateGST /> },
      { path: 'dashboard/margin', element: <Margin /> },
      { path: 'dashboard/carousel', element: <Carousel /> },
      { path: 'dashboard/orders', element: <Orders /> },
      { path: 'dashboard/propagation', element: <Propagation /> },
      { path: 'dashboard/sales-person', element: <SalesPerson /> },
      { path: 'dashboard/invoices', element: <Invoices /> },
      { path: 'dashboard/payments', element: <Payments /> },
      { path: 'dashboard/financial', element: <Financial /> },
      { path: 'dashboard/refund', element: <Refund /> },
      { path: 'dashboard/flags', element: <Flags /> },
      { path: 'dashboard/faq', element: <Faq /> },
      { path: 'dashboard/invoices/:id', element: <StoreInvoices /> },
      { path: 'dashboard/invoices/ledger/:id', element: <Ledger /> },
      { path: 'dashboard/invitation-requests', element: <InvitationRequests /> },
    ],
    isPrivate: true,
    isAuth: true,
  },

  {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: '/', element: <Navigate to="/Login" /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
    isAuth: false,
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

export default RouteConfig;
