import * as Yup from 'yup';
import { Box, Button, IconButton, Modal, Slide, TextField, Typography, useTheme } from '@mui/material';
import { useMutation } from 'react-query';
import { toast } from 'react-hot-toast';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormikProvider, useFormik } from 'formik';
import RejectRefund from '../../api/RejectRefund';
import styles from './RejectRefundModal.module.scss';

const RejectRefundModal = ({ isOpen, handleRejectRefundModal, refundId, refetch }) => {
    const theme = useTheme();

    const rejectRefundValidation = Yup.object({

        reason: Yup.string().required('Required').min("50", "Minimum 50 Characters"),
    });

    const initialValues = {
        reason: '',
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {
            rejectRefundMutation.mutateAsync({ refundId, reason: values.reason })
            handleRejectRefundModal();
        },
        validationSchema: rejectRefundValidation
    });

    const { values, errors, touched, getFieldProps, handleSubmit, resetForm } = formik;

    const rejectRefundMutation = useMutation("rejectRefund", ({ refundId, reason }) => RejectRefund({ refundId, reason }), {
        onSuccess: ({ data }) => {
            if (data) {
                if (!data.success) {
                    toast.error(data?.message, {
                        position: 'bottom-center',
                        duration: 2000
                    })
                } else if (data?.success) {
                    toast.success(data?.message, {
                        position: 'bottom-center',
                        duration: 2000
                    })
                }
                resetForm();
                refetch();
            }
        },
        onError: (error) => {
            console.log('error', error)
        }
    })

    return (
        <Modal
            open={isOpen}
            closeAfterTransition
            className={styles.mainContainer}
            sx={{
                '.MuiBackdrop-root': {
                    background: 'none',
                },
            }}
        >
            <Slide in={isOpen} direction="up">
                <Box className={styles.modalBody}>
                    <IconButton className={styles.closeBtn} onClick={() => handleRejectRefundModal()}>
                        <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
                    </IconButton>
                    <Typography className={styles.modalTitle}>Reject Refund</Typography>
                    <Box className={styles.contentContainer}>
                        <FormikProvider value={formik}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className={styles.label}>Refund Reason</Typography>

                                <TextField
                                    fullWidth
                                    focused={Boolean(values.reason)}
                                    {...getFieldProps('reason')}
                                    error={Boolean(touched.reason && errors.reason)}
                                    placeholder="Describe Reason"
                                    helperText={touched.reason && errors.reason}
                                    sx={{
                                        borderRadius: '5px',
                                        '.css-zrvlb7-MuiInputBase-root-MuiOutlinedInput-root': {
                                            padding: '10px 0 20px 10px',
                                            width: '100%',
                                            minHeight: 100,
                                            maxHeight: 100,
                                            alignItems: 'flex-start',
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: '#242424',
                                            textArea: {
                                                height: 90,
                                                '&::-webkit-scrollbar': {
                                                    width: 5,
                                                },

                                                '&::-webkit-scrollbar-thumb': {
                                                    background: theme.palette.primary.main,
                                                },
                                            },
                                            borderRadius: "5px"
                                        },
                                        '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                                            marginLeft: 0,
                                        },
                                    }}
                                    multiline
                                    inputProps={{
                                        maxLength: 400,
                                    }}
                                    maxRows={4}
                                />
                            </Box>
                        </FormikProvider>

                        <Button
                            className={styles.sbtn}
                            sx={{
                                background: theme.palette.primary.main,
                                color: '#FFF',
                                '&:hover': {
                                    background: theme.palette.primary.main,
                                    color: '#FFF',
                                },
                            }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    );
};
export default RejectRefundModal;
