import client from './Client';

const endPoint = '/su/user/total';

const getTotalUsersCount = () => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getTotalUsersCount };
