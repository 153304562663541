import client from './Client';

const endPoint = '/su/invoice/total';

const getTotalInvoicesCount = ({ type }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      type,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getTotalInvoicesCount };
