import client from "./Client";


const getSubCategory = ({ catId, subCatId }) => {

    const endPoint = `/su/category/${catId}/sub-category/${subCatId}`;

    const token = localStorage.getItem("token");

    return client.get(endPoint, {}, {
        headers: {
            Authorization: token
        }
    })
}

export { getSubCategory }