import { Box, CardMedia, Typography, Divider } from '@mui/material';
import moment from 'moment';
import getS3Bucket from "../../../utils/getS3Bucket";
import styles from './VideoOrder.module.scss';

const VideoOrderCard = ({ videoItems }) => {
  return (
    <Box sx={{ marginTop: '12px' }}>
      {(videoItems || []).map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              paddingBottom: index !== videoItems?.length - 1 ? '10px' : '',
              borderBottom: index !== videoItems?.length - 1 ? '1px dashed #cacaca' : '',
              marginTop: index === 0 ? '' : '20px',
            }}
          >
            <Box className={styles.dFlex}>
              <CardMedia
                component={'img'}
                image={getS3Bucket() && item?.thumbnail ? getS3Bucket() + item?.thumbnail : ''}
                className={styles.thumbnail}
              />
              <Box className={styles.columnFlex}>
                <Typography className={styles.itemName}>{item?.itemName}</Typography>
                {item?.typeOfSession && (
                  <Typography className={styles.typeOfListing}>{`Live Video (${item?.typeOfSession})`}</Typography>
                )}
                {item?.typeOfSession && (
                  <Typography className={styles.session}>{`${item?.session} Sessions`}</Typography>
                )}
              </Box>
            </Box>
            {Boolean(item?.slots?.length) && (
              <>
                <Divider className={styles.divider} flexItem />

                <Box className={styles.slotsContainer}>
                  {(item?.slots || []).map((ite, ind) => {
                    return (
                      <Box
                        key={ind}
                        className={`${styles.slotBox} ${ite?.usedFor ? styles.notAvailable : styles.available}`}
                      >
                        <Typography className={styles.date} sx={{ color: ite?.usedFor ? '#cacaca' : '#ff6962' }}>
                          {moment(ite?.date).format('ddd DD-MM-YYYY')}
                        </Typography>

                        <Typography className={styles.time}>{ite?.time}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default VideoOrderCard;
