import client from './Client';

const endPoint = '/su/whatsapp/users-count';

const getUserCount = () => {
  const token = localStorage.getItem('token');
  return client.get(
    endPoint,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getUserCount };
