import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Avatar,
  Box,
  IconButton,
  Card,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-hot-toast';
import Page from '../../components/Page';
import useModal from '../../hooks/useModal';
import useLoader from '../../hooks/useLoader';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { getAllCategories } from '../../api/GetAllCategory';
import { deleteCategory } from '../../api/DeleteCategory';
import { getTotalCategoriesCount } from '../../api/GetTotalCategoriesCount';
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg';
import AddCategoryModal from '../../components/AddCategoryModal/AddCategoryModal';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import Loader from '../../components/Loader/Loader';
import getS3Bucket from "../../utils/getS3Bucket";
import styles from '../../pageStyles/pageStyles.module.scss';

const buttonType = ['Food', 'Product', 'Video'];

const Category = () => {
  const theme = useTheme();
  const [categoryState, setCategoryState] = useState('Food');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { categoryModal, handleCategoryModal } = useModal();
  const { loader, hideLoader, showLoader } = useLoader();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onClickCatBtn = (value) => {
    setCategoryState(value);
    setPage(1);
    setRowsPerPage(10);
  };

  // Get All Categories
  const { data, isLoading, refetch } = useQuery(
    ['getAllCategories', categoryState, page, rowsPerPage],
    () =>
      getAllCategories({
        type: categoryState,
        page,
        limit: rowsPerPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );

  // Get All Categories Count
  const { data: categoriesCount } = useQuery(
    ['getTotalCategoriesCount', categoryState],
    () => getTotalCategoriesCount({ type: categoryState }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );

  return (
    <Page title="Category">
      {Boolean(!isLoading && !loader) && (
        <>
          <Stack direction="row" justifyContent="space-between" width="1000px" sx={{ marginBottom: '30px' }}>
            <Stack direction="row" alignItems="flex-start" gap="20px">
              {buttonType.map((item, index) => {
                return (
                  <Button
                    key={index}
                    className={styles.categoryState}
                    sx={{
                      background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                      color: categoryState === item ? 'white' : theme.palette.primary.main,
                      '&:hover': {
                        background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                        boxShadow: '0px 0.920487px 5px rgba(0, 0, 0, 0.15)',
                      },
                    }}
                    onClick={() => onClickCatBtn(item)}
                  >
                    {Boolean(categoryState !== item) && (
                      <Box sx={{ background: theme.palette[item.toLowerCase()].main }} className={styles.dot} />
                    )}
                    {item}
                  </Button>
                );
              })}
            </Stack>
            <Button
              className={styles.addButton}
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
              }}
              onClick={() => handleCategoryModal()}
            >
              Add Category
            </Button>
          </Stack>

          {data?.data && data?.data.success && (
            <Card className={styles.card}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{ background: '#F2F2F2' }}>
                      <TableCell
                        width="270px"
                        className={styles.tableCell}
                        align="left"
                        sx={{ paddingLeft: '20px !important' }}
                      >
                        Category
                      </TableCell>

                      <TableCell width="86px" className={styles.tableCell} align="center">
                        Icon
                      </TableCell>

                      <TableCell width="247px" className={styles.tableCell} align="center">
                        HBID
                      </TableCell>

                      <TableCell
                        width="126px"
                        className={styles.tableCell}
                        align="center"
                        sx={{ borderRight: '0 !important' }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(data?.data.data || []).map((item) => (
                      <TableRow
                        key={item._id}
                        sx={{ '&:nth-child(even)': { backgroundColor: '#F1F1F1' }, borderBottom: 0 }}
                      >
                        <TableCell className={styles.tableDataCell} sx={{ paddingLeft: '20px !important' }}>
                          <Typography className={styles.tableText}>{item?.name}</Typography>
                        </TableCell>

                        <TableCell className={styles.tableDataCell} align="center">
                          <Avatar
                            src={encodeURI(getS3Bucket() + item.icon)}
                            variant="square"
                            className={styles.picture}
                          />
                        </TableCell>

                        <TableCell className={styles.tableDataCell} align="center">
                          <Typography className={styles.tableText}>
                            {typeof item?.typeOfStore === 'string'
                              ? item?.typeOfStore
                              : (item?.typeOfStore || []).join(' | ')}
                          </Typography>
                        </TableCell>

                        <TableCell className={styles.tableDataCell} align="center" sx={{ borderRight: '0 !important' }}>
                          <MenuButton
                            id={item._id}
                            refetch={refetch}
                            handleCategoryModal={handleCategoryModal}
                            showLoader={showLoader}
                            hideLoader={hideLoader}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50]}
                count={categoriesCount?.data?.data?.count}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </>
      )}
      {Boolean(isLoading || loader) && <Loader isLoaderVisible />}

      <AddCategoryModal isOpen={categoryModal} handleCategoryModal={handleCategoryModal} refetchData={refetch} />
    </Page>
  );
};

const MenuButton = ({ id, refetch, handleCategoryModal, showLoader, hideLoader }) => {
  const navigate = useNavigate();
  const { confirmationModal, handleConfirmationModal } = useModal();
  const [params] = useSearchParams();

  const onClickEdit = () => {
    if (params?.get('env')) {
      navigate({
        pathname: '/dashboard/category',
        search: `?env=Prod&catId=${id}`,
      });
    } else {
      navigate({
        pathname: '/dashboard/category',
        search: `catId=${id}`,
      });
    }

    handleCategoryModal();
  };
  const categoryDeleteMutation = useMutation('storeDelete', (id) => deleteCategory(id), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        }
        refetch();
        hideLoader();
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });
  return (
    <Stack direction="row" gap="20px" justifyContent="center">
      <IconButton onClick={onClickEdit} sx={{ padding: 0 }}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => handleConfirmationModal()} sx={{ padding: 0 }}>
        <DeleteIcon />
      </IconButton>

      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={'Are you sure you wish to delete?'}
        onConfirm={() => {
          categoryDeleteMutation.mutateAsync(id);
          showLoader();
        }}
      />
    </Stack>
  );
};

export default Category;
