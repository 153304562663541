import client from "./Client";

const endPoint = '/su/carousel/';

const deleteCarousel = (id) => {
    const token = localStorage.getItem("token");
    return client.delete(endPoint + id, {}, {
        headers: {
            Authorization: token
        }
    })
}

export { deleteCarousel }