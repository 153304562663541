import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { updateGst } from '../../api/UpdateGst';
import { createGst } from '../../api/CreateGst';
import { getAllCategories } from '../../api/GetAllCategory';
import { getGst } from '../../api/GetGstById';
import { getSubCategories } from '../../api/GetSubCategories';
import styles from './GstModal.module.scss';
import { ReactComponent as DownArrow } from '../../assets/icons/downArrow.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/addIcon.svg';

const AddGstModal = ({ isOpen, handleGstModal, refetchData }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [params] = useSearchParams();

  const addRange = () => {
    const temp = [...values.gstRange];
    temp.push({
      value: undefined,
      priceRange: {
        minimum: undefined,
        maximum: undefined,
      },
    });
    setFieldValue('gstRange', temp);
  };

  const removeRange = (index) => {
    const temp = [...values.gstRange];
    temp.splice(index, 1);
    setFieldValue('gstRange', temp);
  };

  const GstSchema = Yup.object().shape({
    catId: Yup.string().required('Category is required'),
    segment: Yup.string().required('Segment is required'),
    subCatId: Yup.string().required('Subcategory is required'),
    // gstRange: Yup.array().min(1, 'At least one slot is required'),
  });

  const { data: GstIdData } = useQuery(
    ['getGst', params.get('catId'), params.get('subCatId')],
    () => getGst({ catId: params.get('catId'), subCatId: params.get('subCatId') }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(params.get('catId')) && Boolean(params.get('subCatId')),
    }
  );
  const gstData = GstIdData?.data?.data?.[0];

  const formik = useFormik({
    initialValues: {
      catId: params.get('catId') ? params.get('catId') : '',
      segment: params.get('catId') ? gstData?.type : '',
      subCatId: params.get('catId') ? gstData?.subCategories?.[0]?._id : '',
      gstRange: params.get('catId')
        ? gstData?.subCategories?.[0]?.gst?.gstSlots
        : [
            {
              value: undefined,
              priceRange: {
                minimum: undefined,
                maximum: undefined,
              },
            },
          ],
    },
    validationSchema: GstSchema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      if (params.get('catId')) {
        updateGstMutation.mutateAsync({
          catId: values.catId,
          subCatId: values.subCatId,
          gstSlots: values.gstRange,
        });
      } else {
        createGstMutation.mutateAsync({
          catId: values.catId,
          subCatId: values.subCatId,
          gstSlots: values.gstRange,
        });
      }
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, setFieldValue, resetForm } = formik;

  const returnFunc = () => {
    resetForm();
    handleGstModal();
    if (params?.get('env')) {
      navigate({
        pathname: '/dashboard/gst',
        search: '?env=Prod',
      });
    } else {
      navigate({
        pathname: '/dashboard/gst',
        search: null,
      });
    }
  };

  const { data } = useQuery(
    ['getAllCategories', values.segment],
    () =>
      getAllCategories({
        type: values.segment,
        gstExists: false,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(values.segment),
    }
  );

  // Get Subcategories of a Category
  const { data: subCategoriesData, isLoading: subCategoriesLoading } = useQuery(
    ['getSubCategories', values.catId],
    () =>
      getSubCategories({
        catId: values.catId,
        fetchFor: 'subCategory',
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(values.catId),
    }
  );

  const createGstMutation = useMutation(
    'createGst',
    ({ catId, subCatId, gstSlots }) => createGst({ catId, subCatId, gstSlots }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        returnFunc();
        refetchData();
      },
      onError: (error) => {
        console.log('error', error);
        resetForm();
        returnFunc();
      },
    }
  );
  const updateGstMutation = useMutation(
    'updateGst',
    ({ catId, gstSlots, subCatId }) => updateGst({ catId, gstSlots, subCatId }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        refetchData();
        returnFunc();
      },
      onError: (error) => {
        console.log('error', error);
        returnFunc();
      },
    }
  );

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} gap="10px">
            <Typography className={styles.modalTitle}>{params.get('catId') ? 'Update Gst' : 'Add Gst'}</Typography>
            <Button
              className={styles.submitButton}
              sx={{
                background: theme.palette.primary.main,
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
              }}
              disabled={Object.keys(errors)?.length}
              onClick={() => handleSubmit()}
            >
              {params.get('catId') ? 'Update Gst' : 'Add Gst'}
            </Button>
          </Stack>

          {
            <IconButton className={styles.closeBtn} onClick={() => returnFunc()}>
              <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
            </IconButton>
          }
          <FormikProvider value={formik}>
            <Card
              sx={{
                borderRadius: '5px',
                padding: '10px',
                boxShadow: 'none',
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
              }}
            >
              <Stack>
                <Typography className={styles.inputTitle}>Select Vertical</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="segment"
                    id="segment"
                    value={values.segment}
                    key={values.segment}
                    onChange={handleChange}
                    name="segment"
                    error={Boolean(touched.segment && errors.segment)}
                    sx={{
                      borderRadius: '5px',
                      '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                        padding: '11px',
                        borderRadius: '5px',
                      },
                      '.css-4xgxwl-MuiSelect-icon': {
                        top: 'auto !important',
                      },
                    }}
                    IconComponent={DownArrow}
                  >
                    <MenuItem value="Food">
                      <Stack direction="row" alignItems="center" gap="12px">
                        <Box className={styles.circle} sx={{ background: theme.palette.food.main }} />
                        Food
                      </Stack>
                    </MenuItem>
                    <MenuItem value="Product">
                      <Stack direction="row" alignItems="center" gap="12px">
                        <Box className={styles.circle} sx={{ background: theme.palette.product.main }} />
                        Product
                      </Stack>
                    </MenuItem>
                    <MenuItem value="Video">
                      <Stack direction="row" alignItems="center" gap="12px">
                        <Box className={styles.circle} sx={{ background: theme.palette.video.main }} />
                        Video
                      </Stack>
                    </MenuItem>
                  </Select>
                  {touched.segment && errors.segment && (
                    <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: '0px !important' }}>
                      {errors.segment}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              {values.segment && (
                <Stack>
                  <Typography className={styles.inputTitle}>Category</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="catId"
                      name="catId"
                      id="catId"
                      value={values.catId}
                      key={values.catId}
                      onChange={handleChange}
                      error={Boolean(touched.catId && errors.catId)}
                      sx={{
                        borderRadius: '5px',
                        '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                          padding: '11px',
                          borderRadius: '5px',
                        },
                        '.css-4xgxwl-MuiSelect-icon': {
                          top: 'auto !important',
                        },
                      }}
                      IconComponent={DownArrow}
                    >
                      {data?.data &&
                        data?.data.success &&
                        (data?.data.data || []).map((item, index) => {
                          return (
                            <MenuItem value={item._id} key={index}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {touched.catId && errors.catId && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: '0px !important' }}>
                        {errors.catId}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              )}

              {values.catId && (
                <Stack>
                  <Typography className={styles.inputTitle}>Sub-Category</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="subCatId"
                      name="subCatId"
                      id="subCatId"
                      value={values.subCatId}
                      key={values.subCatId}
                      onChange={handleChange}
                      error={Boolean(touched.subCatId && errors.subCatId)}
                      sx={{
                        borderRadius: '5px',
                        '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                          padding: '11px',
                          borderRadius: '5px',
                        },
                        '.css-4xgxwl-MuiSelect-icon': {
                          top: 'auto !important',
                        },
                      }}
                      IconComponent={DownArrow}
                    >
                      {!params.get('catId')
                        ? Boolean(subCategoriesData?.data && subCategoriesData?.data?.success) &&
                          (subCategoriesData?.data?.data?.[0]?.subCategories || []).map((item, index) => {
                            return (
                              <MenuItem value={item._id} key={index}>
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : Boolean(GstIdData?.data && GstIdData?.data?.success) &&
                          (gstData?.subCategories || []).map((item, index) => {
                            return (
                              <MenuItem value={item._id} key={index}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                    </Select>
                    {touched.subCatId && errors.subCatId && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: '0px !important' }}>
                        {errors.subCatId}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              )}

              <Stack>
                <Typography className={styles.inputTitle} sx={{ marginBottom: '8px !important' }}>
                  GST Range
                </Typography>
                <Stack direction="column" gap="20px">
                  {(values.gstRange || []).map((item, index) => {
                    return (
                      <Stack direction="row" gap="10px" justifyContent="space-between" key={index}>
                        <Box>
                          {index === 0 && <Typography className={styles.inputLabel}>Min GST Amount </Typography>}
                          <TextField
                            fullWidth
                            autoComplete="value"
                            type="number"
                            {...getFieldProps(`gstRange[${index}].priceRange.minimum`)}
                            error={Boolean(
                              touched[`gstRange[${index}].priceRange.minimum`] &&
                                errors[`gstRange[${index}].priceRange.minimum`]
                            )}
                            helperText={
                              touched[`gstRange[${index}].priceRange.minimum`] &&
                              errors[`gstRange[${index}].priceRange.minimum`]
                            }
                            focused={Boolean(values[`gstRange[${index}].priceRange.minimum`])}
                            sx={{
                              borderRadius: 0,
                              input: {
                                padding: '11px',
                                borderRadius: 0,
                              },
                              '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                                borderRadius: '5px',
                              },
                              '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                                marginLeft: '0 !important',
                              },
                            }}
                          />
                        </Box>
                        <Box>
                          {index === 0 && <Typography className={styles.inputLabel}>Max GST Amount</Typography>}
                          <TextField
                            fullWidth
                            autoComplete="value"
                            type="number"
                            {...getFieldProps(`gstRange[${index}].priceRange.maximum`)}
                            error={Boolean(
                              touched[`gstRange[${index}].priceRange.maximum`] &&
                                errors[`gstRange[${index}].priceRange.maximum`]
                            )}
                            helperText={
                              touched[`gstRange[${index}].priceRange.maximum`] &&
                              errors[`gstRange[${index}].priceRange.maximum`]
                            }
                            focused={Boolean(values[`gstRange[${index}].priceRange.maximum`])}
                            sx={{
                              borderRadius: 0,
                              input: {
                                padding: '11px',
                                borderRadius: 0,
                              },
                              '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                                borderRadius: '5px',
                              },
                              '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                                marginLeft: '0 !important',
                              },
                            }}
                          />
                        </Box>
                        <Box>
                          {index === 0 && <Typography className={styles.inputLabel}>GST Value</Typography>}
                          <TextField
                            id={`gstRange${index}.value`}
                            fullWidth
                            autoComplete="value"
                            type="number"
                            name={`gstRange${index}.value`}
                            {...getFieldProps(`gstRange[${index}].value`)}
                            error={Boolean(touched[`gstRange[${index}].value`] && errors[`gstRange[${index}].value`])}
                            helperText={touched[`gstRange[${index}].value`] && errors[`gstRange[${index}].value`]}
                            focused={Boolean(values[`gstRange[${index}].value`])}
                            sx={{
                              borderRadius: 0,
                              input: {
                                padding: '11px',
                                borderRadius: 0,
                              },
                              '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                                borderRadius: '5px',
                              },
                              '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                                marginLeft: '0 !important',
                              },
                            }}
                          />
                        </Box>
                        {values?.gstRange?.length > 1 && (
                          <Box
                            className={styles.columnFlex}
                            sx={{
                              marginTop: index === 0 ? '20px' : 0,
                            }}
                          >
                            <IconButton onClick={() => removeRange(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        )}
                      </Stack>
                    );
                  })}
                  <Stack direction="row" gap="10px" alignItems="center">
                    <IconButton sx={{ fontSize: 30, padding: 0 }} onClick={() => addRange()}>
                      <AddIcon />
                    </IconButton>
                    <Typography>Add more</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </FormikProvider>
        </Box>
      </Slide>
    </Modal>
  );
};

export default AddGstModal;
