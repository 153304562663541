import {
  Avatar,
  Box,
  CardMedia,
  Divider,
  IconButton,
  Modal,
  Slide,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ImageIcon from '@mui/icons-material/Image';
import getS3Bucket from '../../utils/getS3Bucket';
import styles from './ViewItemModal.module.scss';

const Quantity = {
  'Pack of': 'Pack of',
  g: 'gm',
  kg: 'kg',
  mL: 'ml',
  L: 'litres',
  inch: 'Inches',
  cm: 'cm',
  m: 'm',
  'Full Portion': 'Full Portion',
  'Half Portion': 'Half Portion',
  'Per Piece': 'Per Piece', 
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
};

const ViewProductItemModal = ({ handleViewProductModal, isOpen, ProductDetail, isSuccess }) => {
  const theme = useTheme();

  const prepTime = ProductDetail?.prepTime;

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      disableAutoFocus
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
      className={styles.mainContainer}
    >
      <Slide in={isOpen} direction="up">
        <Box>
          <Box className={styles.modalBody}>
            <IconButton className={styles.closeBtn} onClick={() => handleViewProductModal()}>
              <ClearOutlinedIcon
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 30,
                }}
              />
            </IconButton>

            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>View Product Item</Typography>
            </Box>
            {isSuccess && (
              <Box className={styles.modalInnerContainer}>
                <Box>
                  <Typography className={styles.productTitle} sx={{ paddingBottom: '15px' }}>
                    Product item Name
                  </Typography>
                  <Typography className={styles.nameText}>{ProductDetail?.name}</Typography>
                </Box>
                <Divider className={styles.divider} />
                {ProductDetail?.attribute && (
                  <Box>
                    <Typography className={styles.productTitle}>Images</Typography>
                    <Box className={styles.imageContainer}>
                      {(ProductDetail?.images || []).map((item, index) => {
                        return (
                          <Avatar
                            variant="rounded"
                            className={`${styles.image} ${styles.animatedBackground}`}
                            key={index}
                            src={getS3Bucket() ? getS3Bucket() + item : ''}
                          >
                            <ImageIcon
                              sx={{
                                fontSize: 30,
                              }}
                            />
                          </Avatar>
                        );
                      })}
                    </Box>
                    <Divider className={styles.divider} />
                  </Box>
                )}

                <Box className={styles.inputBoxContainer}>
                  <Typography className={styles.productTitle}>Product Item Description</Typography>
                  <Typography className={styles.desc}>{ProductDetail?.description}</Typography>
                </Box>
                <Divider className={styles.divider} />
                {ProductDetail?.weightAttribute?.length > 0 && (
                  <Box>
                    <Typography className={styles.productTitle}>Photos and Video</Typography>
                    <Box className={styles.imageContainer}>
                      {(ProductDetail?.weightAttrImages || []).map((item, index) => {
                        const isVideo = item.match(/\.(mp4|avi|mov|flv|wmv|mkv)$/i);
                        return isVideo ? (
                          <CardMedia
                            component="video"
                            variant="rounded"
                            className={`${styles.video} ${styles.animatedBackground}`}
                            key={index}
                            src={getS3Bucket() ? getS3Bucket() + item : ''}
                            controls
                          >
                            <ImageIcon
                              sx={{
                                fontSize: 30,
                              }}
                            />
                          </CardMedia>
                        ) : (
                          <Avatar
                            variant="rounded"
                            className={`${styles.image} ${styles.animatedBackground}`}
                            key={index}
                            src={getS3Bucket() ? getS3Bucket() + item : ''}
                          >
                            <ImageIcon
                              sx={{
                                fontSize: 30,
                              }}
                            />
                          </Avatar>
                        );
                      })}
                    </Box>
                    <Divider className={styles.divider} />
                  </Box>
                )}

                {(ProductDetail?.weightAttribute || []).length > 0 && (
                  <>
                    <Box className={styles.priceContainer}>
                      {/* <Typography className={styles.inputLabel}>Price ₹</Typography>
                      <Box className={styles.weightAttribute}></Box> */}
                      {/* <Table aria-label="simple table"> */}
                      {/* <TableHead>
                          <TableRow className={styles.tableRow}>
                            <TableCell align="left" className={`${styles.tableCell} ${styles.sizeCell}`}>
                              Price ₹
                            </TableCell>
                            <TableCell align="center" className={`${styles.tableCell} ${styles.stockCell}`}>
                              UOM
                            </TableCell>
                            <TableCell align="right" className={`${styles.tableCell} ${styles.priceCell}`}>
                              Stock
                            </TableCell>
                          </TableRow>
                        </TableHead> */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box className={styles.tableHead}>Price ₹</Box>
                        <Box className={styles.tableHead}>UOM</Box>
                        <Box className={styles.tableHead}>Stock</Box>
                      </Box>
                      <TableBody>
                        {(ProductDetail?.weightAttribute || []).map((item, index) => {
                          return (
                            <Box
                              sx={{
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                            >
                              <Box className={styles.tableText}>{item?.itemWeight.price}</Box>
                              <Box className={styles.tableText}>
                                {item?.itemWeight.value} {item.itemWeight.unit}
                              </Box>
                              <Box className={styles.tableText}>{item?.itemWeight.itemStock}</Box>
                            </Box>
                            // <Typography className={styles.priceText} key={index}>
                            //   <Typography component="span" className={styles.shippingText}>
                            //     - === 'Pack of' && Quantity[item?.itemWeight.unit]}{' '}

                            //     {item?.itemWeight.unit !== 'Pack of' && Quantity[item?.itemWeight.unit]}
                            //   </Typography>
                            //   {index !== (ProductDetail?.weightAttribute || []).length - 1 && (
                            //     <Typography
                            //       component={'span'}
                            //       sx={{
                            //         ...styles.shippingText,
                            //         padding: '0 10px',
                            //       }}
                            //     >
                            //       |
                            //     </Typography>
                            //   )}
                            // </Typography>
                          );
                        })}
                      </TableBody>
                      {/* </Table> */}
                    </Box>
                    <Divider className={styles.divider} />
                  </>
                )}

                {/* attribute table */}
                {(ProductDetail?.attribute || []).length > 0 && (
                  <Typography className={styles.productTitle}>Attributes</Typography>
                )}
                {(ProductDetail?.attribute || []).length > 0 && (
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={styles.tableRow}>
                        <TableCell className={`${styles.tableCell} ${styles.numberCell}`}>No</TableCell>
                        {ProductDetail?.typeOfAttribute.includes('Color') && (
                          <TableCell align="left" className={`${styles.tableCell} ${styles.colourCell}`}>
                            Colour
                          </TableCell>
                        )}
                        {ProductDetail?.typeOfAttribute.includes('Size') && (
                          <TableCell align="left" className={`${styles.tableCell} ${styles.sizeCell}`}>
                            Size
                          </TableCell>
                        )}
                        {/* <TableCell align="center" className={`${styles.tableCell} ${styles.photoCell}`}>
                          Photos
                        </TableCell> */}
                        <TableCell align="center" className={`${styles.tableCell} ${styles.stockCell}`}>
                          Stock-Units
                        </TableCell>
                        <TableCell align="right" className={`${styles.tableCell} ${styles.priceCell}`}>
                          Price ₹
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(ProductDetail?.attribute || []).map((attItem, attIndex) => {
                        return (
                          <>
                            {' '}
                            <TableRow className={styles.tableBodyRow} key={attIndex}>
                              <TableCell align="left" className={`${styles.tableBodyCell} ${styles.numberCell}`}>
                                <Typography sx={{ fontSize: 12 }}>{attIndex + 1}</Typography>
                              </TableCell>
                              {ProductDetail?.typeOfAttribute.includes('Color') && (
                                <TableCell align="left" className={`${styles.tableBodyCell} ${styles.colourCell}`}>
                                  <Typography className={styles.colorText}>
                                    <Typography
                                      className={styles.colorBox}
                                      sx={{
                                        border: attItem?.color?.hex === '#FFFFFF' ? '0.5px solid #cacaca' : 'none',
                                        background: attItem?.color.hex,
                                      }}
                                    />
                                    {attItem?.color.name}
                                  </Typography>
                                </TableCell>
                              )}

                              {ProductDetail?.typeOfAttribute.includes('Size') && (
                                <TableCell align="left" className={`${styles.tableBodyCell} ${styles.sizeCell}`}>
                                  <Box className={styles.sizeContainer}>
                                    <Typography
                                      className={styles.colorText}
                                      sx={{
                                        borderLeft: 'solid 1px #CACACA',
                                        height: '12px',
                                        borderWidth: 1,
                                        paddingLeft: '10px',
                                        '&:nth-child(4n+1)': {
                                          border: 'none',
                                          paddingLeft: 0,
                                        },
                                      }}
                                    >
                                      {attItem?.size?.name && <>{attItem?.size?.name}</>}
                                      {attItem?.size.value && <>{attItem?.size.value}</>}
                                      {attItem?.size?.weight && (
                                        <>{`${attItem?.size?.weight?.value} ${
                                          Quantity[attItem?.size?.weight?.unit]
                                        }`}</>
                                      )}
                                      {attItem?.size?.volume && (
                                        <>{`${attItem?.size?.volume?.value} ${
                                          Quantity[attItem?.size?.volume?.unit]
                                        }`}</>
                                      )}
                                      {attItem?.size.dimension && (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            gap: 0.3,
                                          }}
                                        >
                                          <Typography className={styles.shippingText}>
                                            {Object.keys(attItem?.size.dimension)
                                              .slice(0, Object.keys(attItem?.size.dimension).length - 1)
                                              .map((key) => attItem?.size?.dimension && attItem?.size?.dimension[key])
                                              .join('x')}
                                          </Typography>
                                          <Typography className={styles.shippingText}>
                                            {' '}
                                            {attItem?.size.dimension?.unit}
                                          </Typography>
                                        </Box>
                                      )}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              )}
                              {/* <TableCell
                            align="right"
                            className={styles.tableBodyCell}
                            sx={{
                              display: 'flex',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '12px',
                                width: 75,
                              }}
                            >
                              {(attItem?.productImages || []).map((item, index) => {
                                return (
                                  <Avatar
                                    variant="rounded"
                                    className={styles.animatedBackground}
                                    sx={{
                                      width: '30px !important',
                                      height: '30px !important',
                                      borderRadius: '3px !important',
                                    }}
                                    key={index}
                                    src={getS3Bucket() && item ? getS3Bucket() + item : ''}
                                  >
                                    <ImageIcon
                                      sx={{
                                        fontSize: 30,
                                      }}
                                    />
                                  </Avatar>
                                );
                              })}
                            </Box>
                          </TableCell> */}
                              <TableCell align="center" className={`${styles.tableBodyCell} ${styles.stockCell}`}>
                                {attItem?.itemStock ? attItem?.itemStock : 0}
                              </TableCell>
                              <TableCell align="right" className={`${styles.tableBodyCell} ${styles.priceCell}`}>
                                {`${attItem?.price}.00`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  padding: '0px',
                                  width: '100%',
                                }}
                                colSpan={5}
                              >
                                {attItem?.productImages.length > 0 && (
                                  <Typography className={styles.productTitle}>Photos and Video</Typography>
                                )}
                                {
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: '12px',
                                      // width: 75,
                                    }}
                                  >
                                    {(attItem?.productImages || []).map((item, index) => {
                                      const isVideo = item.match(/\.(mp4|avi|mov|flv|wmv|mkv)$/i);
                                      return isVideo ? (
                                        <CardMedia
                                          component="video"
                                          variant="rounded"
                                          className={`${styles.video} ${styles.animatedBackground}`}
                                          key={index}
                                          src={getS3Bucket() ? getS3Bucket() + item : ''}
                                          controls
                                        >
                                          <ImageIcon
                                            sx={{
                                              fontSize: 30,
                                            }}
                                          />
                                        </CardMedia>
                                      ) : (
                                        <Avatar
                                          variant="rounded"
                                          className={`${styles.image} ${styles.animatedBackground}`}
                                          key={index}
                                          src={getS3Bucket() ? getS3Bucket() + item : ''}
                                        >
                                          <ImageIcon
                                            sx={{
                                              fontSize: 30,
                                            }}
                                          />
                                        </Avatar>
                                      );
                                    })}
                                  </Box>
                                }
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}

                {(ProductDetail?.unique || [])[0]?.isUnique && (
                  <Box>
                    <Box className={styles.shippingContainer}>
                      <Typography className={styles.inputLabel}>Price ₹</Typography>
                      <Typography className={styles.colorText}>
                        {`
                    ${(ProductDetail?.unique || [])[0]?.price}.00 
                    - 
                    ${(ProductDetail?.unique || [])[0]?.packageWeight?.weight?.value}  
                    ${Quantity[(ProductDetail?.unique || [])[0]?.packageWeight?.weight?.unit]}
                    `}{' '}
                      </Typography>
                    </Box>
                    <Divider className={styles.divider} />
                  </Box>
                )}

                <Box className={styles.shippingContainer}>
                  <Typography className={styles.inputLabel}>Shipping</Typography>
                  {!(ProductDetail?.unique || [])[0]?.isUnique && (
                    <Box className={styles.shippingContent}>
                      {(ProductDetail?.attribute || []).map((item, index) => {
                        return (
                          <Box className={styles.shippingTextContainer} key={index}>
                            <Typography className={styles.shippingText}>Item {index + 1}</Typography>
                            <Typography className={styles.shippingText} sx={{ color: '#CACACA' }}>
                              |
                            </Typography>
                            <Typography className={styles.shippingText}>
                              {`${item.packageWeight.dimension.length} x ${item.packageWeight.dimension.breadth} x ${item.packageWeight.dimension.height} cm`}
                            </Typography>
                            <Typography className={styles.shippingText} sx={{ color: '#CACACA' }}>
                              |
                            </Typography>

                            <Typography className={styles.shippingText}>
                              {item.packageWeight.weight.value} {Quantity[item.packageWeight.weight.unit]}
                            </Typography>
                          </Box>
                        );
                      })}
                      {(ProductDetail?.weightAttribute || []).map((item, index) => {
                        return (
                          <Box className={styles.shippingTextContainer} key={index}>
                            <Typography className={styles.shippingText}>Item {index + 1}</Typography>
                            <Typography className={styles.shippingText} sx={{ color: '#CACACA' }}>
                              |
                            </Typography>
                            <Typography className={styles.shippingText}>
                              {`${item.packageWeight.dimension.length} x ${item.packageWeight.dimension.breadth} x ${item.packageWeight.dimension.height} cm`}
                              {/* {Object.keys(item.packageWeight.dimension)
                                .map((key) => item.packageWeight.dimension && item.packageWeight.dimension[key])
                                .join('x')}{' '} */}
                            </Typography>
                            <Typography className={styles.shippingText} sx={{ color: '#CACACA' }}>
                              |
                            </Typography>

                            <Typography className={styles.shippingText}>
                              {' '}
                              {item.packageWeight.weight.value} {Quantity[item.packageWeight.weight.unit]}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}

                  {(ProductDetail?.unique || [])[0]?.isUnique && (
                    <Box className={styles.shippingContent}>
                      <Box className={styles.shippingTextContainer}>
                        <Typography className={styles.shippingText}>Item 1</Typography>
                        <Typography className={styles.shippingText} sx={{ color: '#CACACA' }}>
                          |
                        </Typography>
                        <Typography className={styles.shippingText}>
                          {Object.keys((ProductDetail?.unique || [])[0].packageWeight.dimension)
                            .map(
                              (key) =>
                                (ProductDetail?.unique || [])[0].packageWeight.dimension &&
                                (ProductDetail?.unique || [])[0].packageWeight.dimension[key]
                            )
                            .join('x')}{' '}
                          cm
                        </Typography>
                        <Typography className={styles.shippingText} sx={{ color: '#CACACA' }}>
                          |
                        </Typography>

                        <Typography className={styles.shippingText}>
                          {' '}
                          {(ProductDetail?.unique || [])[0].packageWeight.weight.value}{' '}
                          {Quantity[(ProductDetail?.unique || [])[0].packageWeight.weight.unit]}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Divider sx={styles.divider} />

                <Box className={styles.shippingContainer}>
                  <Typography className={styles.inputLabel}>Preparation Time</Typography>
                  {prepTime !== undefined ? (
                    <>
                      {Number(prepTime?.days) + Number(prepTime?.hours) + Number(prepTime?.mins) !== 0 ? (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                            }}
                          >
                            {Boolean(Number(prepTime?.days)) && (
                              <Typography component="span" className={styles.colorText}>
                                {prepTime?.days}
                                {Number(prepTime?.days) > 1 ? ' Days ' : ' Day '}
                                {Boolean(Number(prepTime?.hours) || Number(prepTime?.mins)) && '- '}
                              </Typography>
                            )}
                            {Boolean(Number(prepTime?.hours)) && (
                              <Typography component="span" className={styles.colorText}>
                                {prepTime?.hours}
                                {Number(prepTime?.hours) > 1 ? ' Hours ' : ' Hour '}
                                {Boolean(Number(prepTime?.mins)) && '- '}
                              </Typography>
                            )}
                            {Boolean(Number(prepTime?.mins)) && (
                              <Typography component="span" className={styles.colorText}>
                                {prepTime?.mins} {' Mins '}
                              </Typography>
                            )}
                          </Box>
                        </>
                      ) : (
                        <Typography component="span" className={styles.colorText}>
                          Item always ready for pick-up
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography component="span" className={styles.colorText}>
                      Item always ready for pick-up
                    </Typography>
                  )}
                </Box>
                {ProductDetail?.sellWithin && (
                  <>
                    <Divider className={styles.divider} />
                    <Box className={styles.shippingContainer}>
                      <Typography className={styles.productTitle}>Sell your items within</Typography>
                      {typeof ProductDetail?.sellWithin === 'number' ? (
                        <Typography className={styles.colorText}>{ProductDetail?.sellWithin} Km</Typography>
                      ) : (
                        <>
                          {ProductDetail?.sellWithin === 'country' && (
                            <Typography className={styles.colorText}>India</Typography>
                          )}
                          {ProductDetail?.sellWithin === 'state' && (
                            <Typography className={styles.colorText}>Maharashtra</Typography>
                          )}
                          {ProductDetail?.sellWithin === 'city' && (
                            <Typography className={styles.colorText}>Pune</Typography>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}
                {(ProductDetail?.searchKeywords || []).join(',') && (
                  <>
                    <Divider className={styles.divider} />
                    <Box className={styles.shippingContainer}>
                      <Typography className={styles.productTitle}>Keyword</Typography>
                      <Typography className={styles.colorText}>{ProductDetail?.searchKeywords.join(',')}</Typography>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export { ViewProductItemModal };
