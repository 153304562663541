import { Modal, Slide, Box, IconButton, Typography, CardMedia, useTheme } from '@mui/material';
import { Carousel } from 'react-material-ui-carousel';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import getS3Bucket from '../../utils/getS3Bucket';
import styles from './RefundModal.module.scss';

const RefundModal = ({ isOpen, handleOpenRefundModal, refundDetails }) => {
  const theme = useTheme();
  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.boxContainer}>
          <Box className={styles.modalBody}>
            <IconButton className={styles.closeBtn} onClick={() => handleOpenRefundModal()}>
              <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 25 }} />
            </IconButton>

            <Typography className={styles.modalTitle}>Refund Reason</Typography>
            <Box className={styles.contentContainer}>
              <Box className={styles.imgAndTitleContainer}>
                {(refundDetails?.itemDetails?.groupImages || []).map((x, ind) => {
                  return (
                    <Box key={ind} className={styles.imageContainer}>
                      <CardMedia
                        component={'img'}
                        image={getS3Bucket() && x ? getS3Bucket() + x : ''}
                        className={styles.image}
                      />
                    </Box>
                  );
                })}
                <Typography className={styles.title}>{refundDetails?.itemDetails?.itemName}</Typography>
              </Box>

              <Box className={styles.colFlex}>
                <Typography className={styles.titleText}>Reason</Typography>
                <Typography className={styles.valueText}>{refundDetails?.reason}</Typography>
              </Box>

              <Box className={styles.colFlex}>
                <Typography className={styles.titleText}>Described Reason</Typography>
                <Typography className={styles.valueText}>{refundDetails?.description}</Typography>
              </Box>

              {Boolean(refundDetails?.media?.video || refundDetails?.media?.images?.length) && (
                <Box className={styles.colFlex}>
                  <Typography className={styles.titleText}>Image / Video</Typography>
                  {Boolean(refundDetails?.media?.video) && (
                    <CardMedia
                      component={'video'}
                      image={
                        getS3Bucket() && refundDetails?.media?.video ? getS3Bucket() + refundDetails?.media?.video : ''
                      }
                      className={styles.img}
                      controls
                    />
                  )}
                  {Boolean(refundDetails?.media?.images?.length && !refundDetails?.media?.video) &&
                    (refundDetails?.media?.images?.length > 1 ? (
                      <Carousel>
                        {(refundDetails?.media?.images || []).map((item, index) => {
                          return (
                            <CardMedia
                              key={index}
                              component={'img'}
                              image={getS3Bucket() && item ? getS3Bucket() + item : ''}
                              className={styles.img}
                            />
                          );
                        })}
                      </Carousel>
                    ) : (
                      <CardMedia
                        component={'img'}
                        image={
                          getS3Bucket() && refundDetails?.media?.images[0]
                            ? getS3Bucket() + refundDetails?.media?.images[0]
                            : ''
                        }
                        className={styles.img}
                        controls
                      />
                    ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default RefundModal;
