import client from './Client';

const endPoint = '/su/whatsapp/users';

const uploadUserContacts = ({ type, csvFile }) => {
  const token = localStorage.getItem('token');
  const value = new FormData();
  value.append('type', type);
  value.append('csvFile', csvFile);
  return client.post(endPoint, value, {
    headers: {
      Authorization: token,
    },
  });
};

export { uploadUserContacts };
