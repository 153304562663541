import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Stack, Typography } from "@mui/material";
import QuestionContainer from "../QuestionContainer/QuestionContainer";
import styles from "./QuestionHeader.module.scss"
import { ReactComponent as EditIcon } from '../../../assets/icons/editIcon.svg';
import HeadingForm from "../HeadingForm/HeadingForm";
import { ReactComponent as AddButton } from "../../../assets/icons/addIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/deleteIcon.svg";
import { ReactComponent as DownArrow } from '../../../assets/icons/moreIcon.svg';
import { ReactComponent as DisableEdit } from '../../../assets/icons/disableEdit.svg';
import { deleteQuestion } from "../../../api/DeleteQuestion";


const QuestionHeader = ({
    header,
    headerId,
    questions,
    refetch
}) => {

    const [expand, setExand] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editQuestion, setEditQuestion] = useState(false);
    const [addForm, setAddForm] = useState(false);
    const [questionID, setQuestionID] = useState(false);


    const deleteQuestionMutation = useMutation(
        "deleteQuestion",
        ({ headerId, qId }) => deleteQuestion({ headerId, qId }),
        {
            onSuccess: ({ data }) => {
                if (data) {
                    if (!data?.success) {
                        toast.error(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    } else if (data?.success) {
                        toast.success(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    }
                    refetch();
                    setExand(!expand)
                }
            },
            onError: (error) => {
                console.log('error', error)
            },
        }
    )

    return (
        <>
            <Accordion className={styles.accordion} expanded={expand}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={styles.accordionSummary}>
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Stack direction="column" gap="30px" width="100%">
                            <Stack direction="column">
                                {!editForm && (<Stack direction="row" alignItems="center" gap="15px">
                                    <Typography>{header}</Typography>
                                    <IconButton sx={{ padding: "0 !important" }} onClick={() => setEditForm(!editForm)}>
                                        <EditIcon />
                                    </IconButton>
                                </Stack>)}
                                {editForm && <HeadingForm header={header} headerId={headerId} setShowForm={setEditForm} refetch={refetch} />}
                            </Stack>
                            {
                                Boolean(!questions?.length) && (
                                    <Stack direction="row" alignItems="center" gap="15px" className={styles.questionBox}>
                                        <IconButton sx={{ padding: "0 !important" }} onClick={() => setExand(!expand)}>
                                            <AddButton />
                                        </IconButton>
                                        <Typography>Add Questions</Typography>
                                    </Stack>
                                )

                            }
                        </Stack>
                        {
                            Boolean(questions?.length && !editForm) && (<IconButton
                                onClick={() => setExand(!expand)}
                                sx={{ padding: "0 !important" }}
                                className={expand ? styles.downArrow : styles.upArrow}
                                disableRipple
                            >
                                <DownArrow />
                            </IconButton>)
                        }
                    </Stack>
                </AccordionSummary>

                <AccordionDetails>
                    <Box className={styles.qaContainer}>
                        {
                            (questions || []).map((item, index) => {
                                return (
                                    <Stack direction="row" gap="15px" key={index}>
                                        {Boolean(!(editQuestion && questionID === item?._id)) && (
                                            <Box key={index} className={styles.queansContainer}>
                                                <Stack direction="row" gap="20px">
                                                    <Typography>Q.</Typography>
                                                    <Typography>{item?.question}</Typography>
                                                </Stack>
                                                <Stack direction="row" gap="20px">
                                                    <Typography>A.</Typography>
                                                    <Box dangerouslySetInnerHTML={{ __html: item?.answer }} />
                                                </Stack>
                                            </Box>
                                        )}
                                        {
                                            Boolean(editQuestion && questionID === item?._id) && (
                                                <Box key={index} className={styles.queansContainer}>
                                                    <QuestionContainer
                                                        headerId={headerId}
                                                        question={item?.question}
                                                        answer={item?.answer}
                                                        questionId={item?._id}
                                                        refetch={() => refetch()}
                                                        editQuestion={() => setEditQuestion(!editQuestion)}
                                                    />
                                                </Box>
                                            )
                                        }

                                        <Stack direction="column" gap="25px" paddingTop="10px">

                                            {
                                                !(editQuestion && questionID === item?._id)
                                                    ?
                                                    <IconButton sx={{ padding: "0 !important" }} disableRipple>
                                                        <EditIcon onClick={() => {
                                                            setEditQuestion(!editQuestion);
                                                            setQuestionID(item?._id)
                                                        }} />
                                                    </IconButton>
                                                    :
                                                    <IconButton sx={{ padding: "0 !important", cursor: "default !important" }} disableRipple>
                                                        <DisableEdit />
                                                    </IconButton>
                                            }

                                            <IconButton
                                                sx={{ padding: "0 !important" }}
                                                onClick={() => deleteQuestionMutation.mutateAsync({
                                                    headerId,
                                                    qId: item?._id
                                                })}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                )
                            })
                        }
                        {
                            Boolean(!questions?.length || addForm) && (
                                <Box className={styles.queansContainer}>
                                    <QuestionContainer
                                        headerId={headerId}
                                        refetch={() => refetch()}
                                        setExand={() => setExand(false)}
                                        setAddForm={() => setAddForm(false)}
                                        questionLength={questions?.length}
                                    />
                                </Box>
                            )
                        }
                        {
                            Boolean(questions?.length) && (
                                <Stack direction="row" alignItems="center" gap="15px" className={styles.questionBox}>
                                    <IconButton sx={{ padding: "0 !important" }} onClick={() => setAddForm(!addForm)}>
                                        <AddButton />
                                    </IconButton>
                                    <Typography>Add Questions</Typography>
                                </Stack>
                            )
                        }
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default QuestionHeader;