import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  IconButton,
  Typography,
  Container,
  SvgIcon,
  TablePagination,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { getStateGst } from '../../api/GetStateGst';
import { deleteSalesPerson } from '../../api/DeleteSalesPerson';
import Scrollbar from '../../components/Scrollbar';
import useModal from '../../hooks/useModal';
import Page from '../../components/Page';
import Loader from '../../components/Loader/Loader';
import styles from '../../pageStyles/pageStyles.module.scss';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import AddSalesPersonModal from '../../components/AddSalesPersonModal/AddSalesPersonModal';
import { GetAllSalesPerson } from '../../api/GetAllSalesPerson';
import { GetAllSalesPersonCount } from '../../api/GetAllSalesPersonCount';

const SalesPerson = () => {
  const theme = useTheme();
  const [salePersonId, setSalesPersonId] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { showSalesPersonModal, handleShowSalesPersonModal, confirmationModal, handleConfirmationModal } = useModal();

  const { data, isLoading, refetch } = useQuery(
    ['GetAllSalesPerson', page, limit],
    () => GetAllSalesPerson(page, limit),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: countData, refetch: refetchCount } = useQuery(
    ['GetAllSalesPersonCount'],
    () => GetAllSalesPersonCount(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteSalesPersonMutation = useMutation('deleteSalesPerson', () => deleteSalesPerson(salePersonId), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
          refetch();
          refetchCount();
          // setPage(1);
        }
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (countData?.data?.success && countData?.data?.data) {
      if (page >Math.ceil(countData?.data?.data/limit)) {
        setPage((page) => page - 1);
      }
    }
  }, [countData,limit]);

  return (
    <Page title="Gst">
      {!isLoading && (
        <Container className={styles.container}>
          <Stack direction="row" justifyContent="flex-end" width="1000px" sx={{ marginBottom: '30px' }}>
            <Button
              className={styles.addButton}
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
              }}
              onClick={() => handleShowSalesPersonModal()}
            >
              Add Sales Person
            </Button>
          </Stack>

          {data?.data?.success ? (
            <Box className={styles.inviteDataWrapper}>
              <TableContainer sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ background: '#F5F4F4' }}>
                      <TableCell align="left" width="210px" className={styles.inviteTableCell}>
                        Name
                      </TableCell>
                      <TableCell align="start" width="100px" className={styles.inviteTableCell}>
                        Mobile No.
                      </TableCell>
                      <TableCell align="start" width="210px" className={styles.inviteTableCell}>
                        Email
                      </TableCell>
                      <TableCell align="start" width="300px" className={styles.inviteTableCell}>
                        Address
                      </TableCell>
                      <TableCell align="start" width="80px" className={styles.inviteTableCell}>
                        {/* Action */}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {((data?.data?.success && data?.data?.data) || []).map((item, index) => {
                      return (
                        <TableRow key={index} className={index % 2 === 1 ? styles.darkBackground : ''}>
                          <TableCell className={styles.inviteTableDataCell} align="start" justifyContent="start">
                            {item?.name}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.mobile}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.email}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.address}
                          </TableCell>

                          <TableCell
                            className={styles.inviteTableDataCell}
                            align="start"
                            sx={{ borderRight: '0px !important' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <SvgIcon
                                onClick={() => {
                                  setSalesPersonId(item?._id);
                                  handleConfirmationModal();
                                }}
                                sx={{ cursor: 'pointer' }}
                              >
                                <DeleteIcon />
                              </SvgIcon>
                            </Box>
                            {/* <DeleteIcon /> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={countData?.data?.success && countData?.data?.data ? countData?.data?.data : 0}
                rowsPerPage={limit}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          ) : (
            <Box className={styles.noDataWrapper}>
              <Typography className={styles.noDataText}>No data found</Typography>
            </Box>
          )}
        </Container>
      )}

      {isLoading && <Loader isLoaderVisible />}
      <AddSalesPersonModal
        isOpen={showSalesPersonModal}
        handleShowSalesPersonModal={handleShowSalesPersonModal}
        refetch={refetch}
        refetchCount={refetchCount}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={'Are you sure you want to delete this sales person?'}
        onConfirm={() => {
          deleteSalesPersonMutation.mutateAsync();
        }}
      />
    </Page>
  );
};

export default SalesPerson;
