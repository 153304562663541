import client from './Client';

const endPoint = '/su/whatsapp/send-message';

const sendWhatsappMessage = ({ userType, template, type, mediaId }) => {
  const token = localStorage.getItem('token');
  return client.post(
    endPoint,
    {
      userType,
      template,
      type,
      mediaId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { sendWhatsappMessage };
