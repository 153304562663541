import client from './Client';

const createGst = ({ catId, subCatId, gstSlots }) => {

  const endPoint = `/su/category/${catId}/sub-category/${subCatId}/gst`;

  const token = localStorage.getItem('token');

  const value = {
    gstSlots,
  };

  return client.put(
    endPoint,
    value,
    {
      headers: {
        Authorization: token,
      },
    });
};

export { createGst };
