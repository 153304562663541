import client from './Client';

const endPoint = '/su/order/all';

const getOrders = ({ type, page, limit }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      type,
      page,
      limit,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getOrders };
