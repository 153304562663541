import client from './Client';

const endPoint = '/su/category/';

const createCategory = (value) => {
  const token = localStorage.getItem('token');
  return client.post(endPoint, value, {
    headers: {
      Authorization: token,
    },
  });
};

export { createCategory };
