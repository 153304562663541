import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import useModal from '../../hooks/useModal';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import getRefund from '../../api/GetRefund';
import { createReturnOrder } from '../../api/CreateReturnOrder';
import styles from '../../pageStyles/pageStyles.module.scss';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import InitiateRefundModal from '../../components/InitiateRefundModal/InitiateRefundModal';
import Loader from '../../components/Loader/Loader';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import RefundModal from '../../components/RefundModal/RefundModal';
import RejectRefundModal from '../../components/RejectRefundModal/RejectRefundModal';
import OrderDetailModal from '../../components/OrderDetailModal/OrderDetailModal';
import { getTotalRefundCount } from '../../api/GetTotalRefundCount';
import { ReactComponent as DiscardIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as SettleIcon } from '../../assets/icons/right.svg';
import { userStatus } from '../../api/UserStatus';

const buttonType = ['Food', 'Product', 'Video'];
const colors = {
  Processing: { color: '#FF8A00' },
  Refunded: { color: '#09891E' },
  Rejected: { color: '#DB1212' },
  Success:{color:"#00CB20"}
};

const Refund = () => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [categoryState, setCategoryState] = useState('Food');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refundId, setRefundId] = useState();
  const [orderId, setOrderId] = useState();
  const [videoType, setVideoType] = useState();
  const [buyerName, setBuyerName] = useState();
  const [refundDetails, setRefundDetails] = useState();
  const [refundAmount, setRefundAmount] = useState();

  const {
    confirmationModal,
    handleConfirmationModal,
    initateRefundModal,
    handleInitiateRefundModal,
    refundModal,
    handleOpenRefundModal,
    rejectRefundModal,
    handleRejectRefundModal,
    orderDetailsModal,
    handleOrderDetailsModal,
  } = useModal();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onClickCatBtn = (value) => {
    setCategoryState(value);
    setPage(1);
    setRowsPerPage(10);
  };

  // Get All Refund
  const { data, isLoading, refetch } = useQuery(
    ['getRefund', categoryState, page, rowsPerPage],
    () => getRefund({ type: categoryState, page, limit: rowsPerPage }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );

  const { data: refundCount, isLoading: refundLoading } = useQuery(
    ['getTotalRefundCount', categoryState],
    () => getTotalRefundCount({ type: categoryState }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState) && Boolean(!isLoading),
    }
  );

  const createReturnOrderMutation = useMutation(
    'createReturnOrder',
    ({ orderId, itemId }) => createReturnOrder({ orderId, itemId }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
          refetch();
        }
        // hideLoader();
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  return (
    <>
      <Page title="Refund">
        {Boolean(!isLoading) && (
          <Container className={styles.container}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginBottom: '30px', width: 1000 }}
            >
              <Stack direction="row" alignItems="flex-start" gap="20px">
                {buttonType.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      className={styles.categoryState}
                      sx={{
                        background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                        color: categoryState === item ? 'white' : theme.palette.primary.main,
                        '&:hover': {
                          background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                          boxShadow: '0px 0.920487px 5px rgba(0, 0, 0, 0.15)',
                        },
                      }}
                      onClick={() => onClickCatBtn(item)}
                    >
                      {Boolean(categoryState !== item) && (
                        <Box sx={{ background: theme.palette[item.toLowerCase()].main }} className={styles.dot} />
                      )}
                      {item}
                    </Button>
                  );
                })}
              </Stack>

              <Box className={styles.filterBox}>
                <IconButton sx={{ padding: 0 }} disableRipple>
                  <FilterIcon />
                </IconButton>
              </Box>
            </Stack>

            {Boolean(data?.data?.success) && (
              <Card className={styles.card}>
                <Scrollbar>
                  <TableContainer sx={{ width: '100%' }}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ background: '#F5F4F4' }}>
                          <TableCell width="109px" className={styles.refundTableCell}>
                            Ticket No
                          </TableCell>
                          <TableCell width="120px" className={styles.refundTableCell}>
                            Order No
                          </TableCell>
                          <TableCell width="115px" align="right" className={styles.refundTableCell}>
                            Refund Value
                          </TableCell>
                          <TableCell width="104px" className={styles.refundTableCell}>
                            Date
                          </TableCell>
                          <TableCell width="136px" className={styles.refundTableCell}>
                            Order By
                          </TableCell>
                          <TableCell width="172px" className={styles.refundTableCell}>
                            Order To
                          </TableCell>
                          {data?.config?.params?.storeType === 'Product' && (
                            <TableCell width="80px" className={styles.refundTableCell}>
                              Return
                            </TableCell>
                          )}
                          <TableCell
                            width="96px"
                            className={styles.refundTableCell}
                            sx={{ borderRight: '0 !important' }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {(data?.data?.data || []).map((item, index) => {
                          return (
                            <TableRow key={index} className={styles.tableRow}>
                              <TableCell className={styles.refundTableDataCell}>
                                <Typography
                                  className={styles.linkText}
                                  onClick={() => {
                                    setRefundDetails(item?.request);
                                    handleOpenRefundModal();
                                  }}
                                >
                                  {item?.ticketNo}
                                </Typography>
                              </TableCell>
                              <TableCell
                                className={styles.refundTableDataCell}
                                sx={{
                                  color: `${theme.palette.primary.main}!important`,
                                  fontWeight: 500,
                                  cursor: 'pointer',
                                }}
                              >
                                <Typography
                                  className={styles.linkText}
                                  onClick={() => {
                                    setOrderId(item?.orderId);
                                    setVideoType(
                                      item?.request?.itemDetails?.planDuration ? 'Recorded Video Header' : 'Live Video'
                                    );
                                    handleOrderDetailsModal();
                                  }}
                                >
                                  {item?.orderNo}
                                </Typography>
                              </TableCell>

                              <TableCell className={styles.refundTableDataCell} align="right">
                                {item?.amount?.totalAmount}
                              </TableCell>

                              <TableCell className={styles.refundTableDataCell}>{item?.date}</TableCell>

                              <TableCell
                                className={styles.refundTableDataCell}
                                sx={{ color: `${theme.palette.primary.main}!important`, fontWeight: 500 }}
                              >
                                {item?.orderBy?.userName}
                              </TableCell>

                              <TableCell
                                className={styles.refundTableDataCell}
                                sx={{ color: `${theme.palette.primary.main}!important`, fontWeight: 500 }}
                              >
                                {item?.orderTo?.storeName}
                              </TableCell>
                              {data?.config?.params?.storeType === 'Product' && (
                                <TableCell
                                  className={styles.refundTableDataCell}
                                  sx={{ color: `${theme.palette.primary.main}!important`, fontWeight: 500 }}
                                >
                                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {item?.request?.itemDetails?.isReturnable ? (
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          createReturnOrderMutation.mutate({
                                            orderId: item?.orderId,
                                            itemId: item?.request?.itemDetails?.itemId,
                                          })
                                        }
                                        disabled={item?.returnInitiated}
                                      >
                                        {item?.returnInitiated ? 'Initiated' : 'Initiate'}
                                      </Button>
                                    ) : (
                                      <Typography
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderRadius: '5px',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        NA
                                      </Typography>
                                    )}
                                  </Box>
                                </TableCell>
                              )}
                              <TableCell className={styles.refundTableDataCell} sx={{ borderRight: '0 !important' }}>
                                {item?.status === 'Raised' ? (
                                  <Box
                                    className={styles.dFlex}
                                    sx={{
                                      gap: '15px !important',
                                      cursor: item?.returnInitiated ? 'not-allowed' : 'pointer',
                                    }}
                                  >
                                    <IconButton
                                      className={styles.actionIcon}
                                      onClick={() => {
                                        setRefundId(item?._id);
                                        setRefundAmount(item?.amount?.totalAmount);
                                        handleConfirmationModal();
                                      }}
                                      disabled={item?.returnInitiated}
                                    >
                                      <DiscardIcon />
                                    </IconButton>
                                    {item?.request?.itemDetails?.isReturnable ? (
                                      <IconButton
                                        className={styles.actionIcon}
                                        onClick={() => {
                                          setRefundId(item?._id);
                                          setBuyerName(item?.orderBy?.userName);
                                          setRefundAmount(item?.amount?.totalAmount);
                                          handleInitiateRefundModal();
                                        }}
                                        disabled={!item?.returnInitiated}
                                        style={{ cursor: item?.returnInitiated ? 'pointer' : 'not-allowed' }}
                                      >
                                        <SettleIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        className={styles.actionIcon}
                                        onClick={() => {
                                          setRefundId(item?._id);
                                          setBuyerName(item?.orderBy?.userName);
                                          setRefundAmount(item?.amount?.totalAmount);
                                          handleInitiateRefundModal();
                                        }}
                                      >
                                        <SettleIcon />
                                      </IconButton>
                                    )}
                                  </Box>
                                ) : (
                                  <Typography sx={{ color: colors[item?.status].color, fontSize: 14, fontWeight: 500 }}>
                                    {item?.status}
                                  </Typography>
                                  // <></>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={refundCount?.data?.data?.count ? refundCount?.data?.data?.count : 0}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            )}
          </Container>
        )}
        {isLoading && <Loader isLoaderVisible />}

        <ConfirmationModal
          isOpen={confirmationModal}
          handleConfirmationModal={handleConfirmationModal}
          modalText={'Are you sure you want to discard refund?'}
          onConfirm={() => {
            handleRejectRefundModal();
          }}
        />

        <InitiateRefundModal
          isOpen={initateRefundModal}
          handleInitiateRefundModal={handleInitiateRefundModal}
          refundId={refundId}
          refundAmount={refundAmount}
          buyerName={buyerName}
          refetch={refetch}
        />

        <RejectRefundModal
          isOpen={rejectRefundModal}
          handleRejectRefundModal={handleRejectRefundModal}
          refundId={refundId}
          refetch={refetch}
        />

        <RefundModal isOpen={refundModal} handleOpenRefundModal={handleOpenRefundModal} refundDetails={refundDetails} />

        <OrderDetailModal
          isOpen={orderDetailsModal}
          handleOrderDetailsModal={handleOrderDetailsModal}
          orderId={orderId}
          type={categoryState}
          videoType={videoType}
        />
      </Page>
    </>
  );
};

export default Refund;
