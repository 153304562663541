// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Verticaldata_foodWrapper__X2ho4,\n.Verticaldata_productWrapper__pl9-Q,\n.Verticaldata_videoWrapper__A3Hxr {\n  border-radius: 6px;\n  padding: 6px 12px;\n}\n\n.Verticaldata_foodWrapper__X2ho4 {\n  background-color: #fffaf1;\n}\n\n.Verticaldata_productWrapper__pl9-Q {\n  background-color: #f2fffb;\n}\n\n.Verticaldata_videoWrapper__A3Hxr {\n  background-color: #ffeeed;\n}\n\n.Verticaldata_label__ZU6QH {\n  color: #5c5b69;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 150%; /* 21px */\n}\n\n.Verticaldata_count__6iA56 {\n  color: #353445;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 150%; /* 24px */\n}", "",{"version":3,"sources":["webpack://./src/components/Custom/VerticalDataBox/Verticaldata.module.scss"],"names":[],"mappings":"AAAA;;;EAGE,kBAAA;EACA,iBAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,yBAAA;AAGF;;AADA;EACE,yBAAA;AAIF;;AAFA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAKF;;AAHA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAMF","sourcesContent":[".foodWrapper,\n.productWrapper,\n.videoWrapper {\n  border-radius: 6px;\n  padding: 6px 12px;\n}\n.foodWrapper {\n  background-color: #fffaf1;\n}\n.productWrapper {\n  background-color: #f2fffb;\n}\n.videoWrapper {\n  background-color: #ffeeed;\n}\n.label {\n  color: #5c5b69;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 150%; /* 21px */\n}\n.count {\n  color: #353445;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 150%; /* 24px */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"foodWrapper": "Verticaldata_foodWrapper__X2ho4",
	"productWrapper": "Verticaldata_productWrapper__pl9-Q",
	"videoWrapper": "Verticaldata_videoWrapper__A3Hxr",
	"label": "Verticaldata_label__ZU6QH",
	"count": "Verticaldata_count__6iA56"
};
export default ___CSS_LOADER_EXPORT___;
