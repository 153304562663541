import { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  useTheme,
  Box,
  IconButton,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import useModal from '../../hooks/useModal';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import StoreInfoModal from '../../components/StoreInfoModal/StoreInfoModal';
import useLoader from '../../hooks/useLoader';
import IOSSwitch from '../../components/IOSSwitch/IOSSwitch';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eyeIcon.svg';
import Loader from '../../components/Loader/Loader';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import styles from '../../pageStyles/pageStyles.module.scss';
import { getAllStores } from '../../api/GetAllStores';
import { deleteStore } from '../../api/DeleteStore';
import { storeStatus } from '../../api/StoreStatus';
import { getStoreDetail } from '../../api/GetStoreDetail';
import { getTotalStoresCount } from '../../api/GetTotalStoresCount';

export default function Store() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [storeId, setStoreId] = useState();
  const [storeType, setstoreType] = useState();

  const { storeModal, handleStoreModal, confirmationModal, handleConfirmationModal } = useModal();
  const { loader, showLoader, hideLoader } = useLoader();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const changeNavigation = (itemId, itemType) => {
    if (params?.get('env')) {
      navigate({
        pathname: `/dashboard/stores/${itemId}`,
        search: `?env=Prod&type=${itemType}`,
      });
    } else {
      navigate({
        pathname: `/dashboard/stores/${itemId}`,
        search: `?type=${itemType}`,
      });
    }
  };

  // Get All Stores
  const { data, isLoading, refetch } = useQuery(
    ['getAllStores', page, rowsPerPage],
    () => getAllStores(page, rowsPerPage),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: storesCount } = useQuery('getTotalStoresCount', () => getTotalStoresCount(), {
    refetchOnWindowFocus: false,
  });

  const { data: storeData, isLoading: storeDataLoading } = useQuery(
    ['getStoreDetails', storeId, storeModal],
    () => getStoreDetail(storeId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(storeId) && storeModal,
    }
  );
  const storeInfo = storeData?.data?.data?.[0];

  const storeStatusMutation = useMutation('storeStatus', ({ status, id }) => storeStatus({ status, id }), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        }
        refetch();
      }
      hideLoader();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  const storeDeleteMutation = useMutation('storeDelete', (id) => deleteStore(id), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        }
        refetch();
      }
      hideLoader();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (isLoading || storeDataLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, data, storeDataLoading, storeData]);

  return (
    <Page title="Businesses">
      {!loader && (
        <Container className={styles.container}>
          <Stack direction="row" justifyContent="end" width="1000px" marginBottom="30px">
            <Box className={styles.filterBox}>
              <IconButton sx={{ padding: 0 }} disableRipple>
                <FilterIcon />
              </IconButton>
            </Box>
          </Stack>
          <Card className={styles.card}>
            <Scrollbar>
              <TableContainer sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ background: '#F5F4F4' }}>
                      <TableCell
                        align="left"
                        width="270px"
                        className={styles.borderTableCell}
                        sx={{ paddingLeft: '20px !important' }}
                      >
                        Home Business
                      </TableCell>
                      <TableCell align="center" width="116px" className={styles.borderTableCell}>
                        Age Bracket
                      </TableCell>
                      <TableCell align="center" width="126px" className={styles.borderTableCell}>
                        Inception
                      </TableCell>
                      <TableCell align="center" width="85px" className={styles.borderTableCell}>
                        Vertical
                      </TableCell>
                      <TableCell align="center" width="139px" className={styles.borderTableCell}>
                        Pickup Label
                      </TableCell>
                      <TableCell align="center" width="58px" className={styles.borderTableCell}>
                        GST
                      </TableCell>
                      <TableCell align="center" width="86px" className={styles.borderTableCell}>
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        width="150px"
                        className={styles.borderTableCell}
                        sx={{ borderRight: '0px !important' }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {data?.data.success && (
                    <TableBody>
                      {(data?.data?.data || []).map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className={styles.borderTableDataCell} sx={{ paddingLeft: '20px !important' }}>
                              <Typography
                                className={styles.tableText}
                                sx={{
                                  color: `${theme.palette.primary.main} !important`,
                                  cursor: 'pointer',
                                  fontWeight: '500 !important',
                                }}
                                onClick={() => {
                                  setStoreId(item?._id);
                                  setstoreType(item?.type);
                                  handleStoreModal();
                                }}
                              >
                                {item?.name}
                              </Typography>
                              <Typography className={styles.subText}>{item?.userName}</Typography>
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              {item?.ageBracket}
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              {item?.publishedDate}
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell}>
                              <Box
                                className={styles.vertical}
                                sx={{ background: theme.palette[(item?.type).toLowerCase()].main }}
                              />
                            </TableCell>

                            <TableCell
                              className={styles.borderTableDataCell}
                              sx={{
                                paddingY: '10px',
                                paddingX: '15px',
                              }}
                              align="center"
                            >
                              <Typography
                                className={styles.tableText}
                                sx={
                                  {
                                    // color: `${theme.palette[(item?.type).toLowerCase()].main} !important`,
                                    // fontWeight: '500 !important',
                                  }
                                }
                                marginBottom="5px"
                              >
                                {item?.pickupLabel ? item?.pickupLabel : '--'}
                              </Typography>
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              <Typography className={styles.tableText}>{item?.gst}</Typography>
                            </TableCell>

                            <TableCell className={styles.borderTableDataCell} align="center">
                              <Typography
                                className={styles.tableText}
                                sx={{
                                  color: item?.status === 'Active' ? '#00CB20 !important' : '#DA1A18 !important',
                                  fontWeight: '500 !important',
                                }}
                              >
                                {item?.status}
                              </Typography>
                            </TableCell>

                            <TableCell
                              className={styles.borderTableDataCell}
                              align="center"
                              sx={{ borderRight: '0px !important' }}
                            >
                              <Stack direction="row" alignItems="center" gap="20px" justifyContent="center">
                                <IOSSwitch
                                  checked={Boolean(item?.status === 'Active')}
                                  onChange={() => {
                                    storeStatusMutation.mutateAsync({
                                      status: item?.status === 'Active' ? Boolean(true) : Boolean(false),
                                      id: item._id,
                                    });
                                    showLoader();
                                  }}
                                />

                                <IconButton
                                  sx={{ padding: '0 !important' }}
                                  disableRipple
                                  onClick={() => changeNavigation(item?._id, item?.type)}
                                >
                                  <EyeIcon />
                                </IconButton>

                                <IconButton
                                  disableRipple
                                  sx={{ padding: '0 !important' }}
                                  onClick={() => {
                                    setStoreId(item?._id);
                                    handleConfirmationModal();
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              count={Number(storesCount?.data?.data?.count)}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      )}
      {loader && <Loader isLoaderVisible={loader} />}

      <StoreInfoModal
        isOpen={Boolean(storeModal && !storeDataLoading)}
        handleStoreModal={handleStoreModal}
        storeInfo={storeInfo}
        storeType={storeType}
      />

      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={'Are you sure you wish to delete?'}
        onConfirm={() => {
          storeDeleteMutation.mutateAsync(storeId);
          showLoader();
        }}
      />
    </Page>
  );
}
