import { useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardMedia,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import getS3Bucket from "../../utils/getS3Bucket";

import { updateCategory } from '../../api/UpdateCategory';
import { createCategory } from '../../api/CreateCategory';
import { getCategoryById } from '../../api/GetCategoryById';
import styles from './CategoryModal.module.scss';
import { ReactComponent as DownArrow } from '../../assets/icons/downArrow.svg';
import { ReactComponent as AddFileIcon } from '../../assets/icons/addFile.svg';

const typeOfStore = {
  Food: ['Home Kitchen', 'Home Café', 'Pet Café', 'Home Store'],
  Product: ['Home Store', 'Home Boutique', 'Home Gallery'],
  Video: ['Home Studio', 'Home Office', 'Home Classes'],
};

const AddCategoryModal = ({ isOpen, handleCategoryModal, refetchData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [catId] = useSearchParams();

  // Schema Validation
  const CategorySchema = Yup.object().shape({
    name: Yup.string().required('Category name is required'),
    categoryIcon: Yup.string().required('Icon is required'),
    type: Yup.string().required('Business type is required'),
    typeOfStore: Yup.array().min(1, 'HBID is required'),
    foodStoreType: Yup.string().when('type', {
      is: 'Food',
      then: Yup.string().required('Food Store Type is required'),
    }),
    videoCategoryType: Yup.string().when('type', {
      is: 'Video',
      then: Yup.string().required('Video Category type is required'),
      otherwise: Yup.string(),
    }),
  });

  const { data, isLoading, refetch } = useQuery(
    ['getCategoryById', catId.get('catId')],
    () => getCategoryById(catId.get('catId')),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(catId.get('catId')),
    }
  );
  const categoryData = data?.data?.data?.[0];

  const formik = useFormik({
    initialValues: {
      name: catId.get('catId') ? categoryData?.name : '',
      type: catId.get('catId') ? categoryData?.type : '',
      categoryIcon: catId.get('catId') ? encodeURI(getS3Bucket() + categoryData?.icon) : '',
      typeOfStore: catId.get('catId') ? categoryData?.typeOfStore : [],
      foodStoreType: catId.get('catId') ? categoryData?.foodStoreType : '',
      videoCategoryType: catId.get('catId') ? categoryData?.videoCategoryType : '',
    },
    validationSchema: CategorySchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      createCategoryMutation.mutateAsync({
        name: values.name,
        type: values.type,
        categoryIcon: values.categoryIcon,
        typeOfStore: values.typeOfStore,
        foodStoreType: values.foodStoreType,
        videoCategoryType: values.videoCategoryType,
      });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, setFieldValue, resetForm } = formik;

  const returnFunc = () => {
    resetForm();
    handleCategoryModal();
    if (catId?.get('env')) {
      navigate({
        pathname: '/dashboard/category',
        search: '?env=Prod',
      });
    } else {
      navigate({
        pathname: '/dashboard/category',
        search: null,
      });
    }
  };

  const createAndUpdateCategory = async ({
    name,
    type,
    categoryIcon,
    typeOfStore,
    foodStoreType,
    videoCategoryType,
  }) => {
    const categoryId = catId.get('catId');
    const body = new FormData();
    body.append('name', name);
    if (typeof categoryIcon !== 'string') {
      body.append('icon', categoryIcon);
    }
    body.append('type', type);

    if (type === 'Food') {
      body.append('foodStoreType', foodStoreType);
    }

    body.append('typeOfStore', JSON.stringify(typeOfStore));
    if (videoCategoryType) {
      body.append('videoCategoryType', videoCategoryType);
    }
    if (categoryId) {
      return updateCategory(body, categoryId);
    }
    return createCategory(body);
  };

  const createCategoryMutation = useMutation(
    'createCategory',
    ({ name, type, categoryIcon, typeOfStore, foodStoreType, videoCategoryType }) =>
      createAndUpdateCategory({ name, type, categoryIcon, typeOfStore, foodStoreType, videoCategoryType }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        returnFunc();
        refetchData();
      },
      onError: (error) => {
        console.log('error', error);
        returnFunc();
      },
    }
  );

  return (
    <>
      <Modal
        open={isOpen}
        closeAfterTransition
        className={styles.mainContainer}
        sx={{
          '.MuiBackdrop-root': {
            background: 'none',
          },
        }}
      >
        <Slide in={isOpen} direction="up">
          <Box className={styles.modalBody}>
            <Box className={styles.titleContainer}>
              <Typography className={styles.modalTitle}>
                {catId.get('catId') ? 'Update Category' : 'Add Category'}
              </Typography>
              <Button
                className={styles.submitButton}
                sx={{
                  background: theme.palette.primary.main,
                  '&:hover': {
                    background: theme.palette.primary.main,
                  },
                }}
                disabled={Object.keys(errors)?.length}
                onClick={() => handleSubmit()}
              >
                {catId.get('catId') ? 'Update Category' : 'Create Category'}
              </Button>
            </Box>
            {
              <IconButton className={styles.closeBtn} onClick={() => returnFunc()}>
                <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
              </IconButton>
            }
            <FormikProvider value={formik}>
              <Card
                sx={{
                  borderRadius: '5px',
                  padding: '10px',
                  marginY: '30px',
                  boxShadow: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Stack spacing={3}>
                  <FormControl fullWidth>
                    <Typography className={styles.inputTitle}>Select Vertical</Typography>
                    <Select
                      labelId="type"
                      id="type"
                      value={values.type}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('typeOfStore', []);
                      }}
                      name="type"
                      error={Boolean(touched.type && errors.type)}
                      key={values.type}
                      sx={{
                        borderRadius: '5px',
                        '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                          padding: '11px',
                          borderRadius: '5px',
                        },
                        '.css-4xgxwl-MuiSelect-icon': {
                          top: 'auto !important',
                        },
                      }}
                      IconComponent={DownArrow}
                    >
                      <MenuItem value="Food">Food</MenuItem>
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Video">Video</MenuItem>
                    </Select>
                    {touched.type && errors.type && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                        {errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {Boolean(values.type === 'Video') && (
                    <FormControl fullWidth>
                      <Typography className={styles.inputTitle}>Video Category Type</Typography>
                      <Select
                        labelId="videoCategoryType"
                        id="videoCategoryType"
                        name="videoCategoryType"
                        value={values.videoCategoryType}
                        key={values.videoCategoryType}
                        onChange={(e) => handleChange(e)}
                        error={Boolean(touched.typeOfStore && errors?.typeOfStore)}
                        sx={{
                          borderRadius: '5px',
                          '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '11px',
                            borderRadius: '5px',
                          },
                          '.css-4xgxwl-MuiSelect-icon': {
                            top: 'auto !important',
                          },
                          '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                            marginLeft: 0,
                          },
                        }}
                        IconComponent={DownArrow}
                      >
                        <MenuItem value="Instructional">Instructional</MenuItem>
                        <MenuItem value="Performance">Performance</MenuItem>
                        <MenuItem value="Consultation">Consultation</MenuItem>
                      </Select>
                      {touched.videoCategoryType && errors.videoCategoryType && (
                        <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                          {errors.videoCategoryType}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}

                  <FormControl fullWidth>
                    <Typography className={styles.inputTitle}>Select HBID</Typography>
                    <Select
                      labelId="typeOfStore"
                      id="typeOfStore"
                      multiple
                      value={values.typeOfStore}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="typeOfStore"
                      error={Boolean(touched.typeOfStore && errors.typeOfStore)}
                      key={values.typeOfStore}
                      sx={{
                        borderRadius: '5px',
                        '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                          padding: '11px',
                          borderRadius: '5px',
                        },
                        '.css-4xgxwl-MuiSelect-icon': {
                          top: 'auto !important',
                        },
                      }}
                      IconComponent={DownArrow}
                    >
                      {(typeOfStore[values.type] || []).map((item, index) => {
                        return (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched.typeOfStore && errors.typeOfStore && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                        {errors.typeOfStore}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Box>
                    <Typography className={styles.inputTitle}>Category Name</Typography>
                    <TextField
                      fullWidth
                      autoComplete="name"
                      type="name"
                      focused={Boolean(values.name)}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      sx={{
                        borderRadius: 0,
                        input: {
                          padding: '11px',
                          borderRadius: 0,
                        },
                        '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                          borderRadius: '5px',
                        },
                      }}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Box>

                  {Boolean(values.type === 'Food') && (
                    <FormControl fullWidth>
                      <Typography className={styles.inputTitle}>Select Type of Food Store</Typography>
                      <Select
                        labelId="foodStoreType"
                        id="foodStoreType"
                        value={values.foodStoreType}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        name="foodStoreType"
                        error={Boolean(touched.foodStoreType && errors.foodStoreType)}
                        key={values.foodStoreType}
                        sx={{
                          borderRadius: '5px',
                          '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '11px',
                            borderRadius: '5px',
                          },
                          '.css-4xgxwl-MuiSelect-icon': {
                            top: 'auto !important',
                          },
                        }}
                        IconComponent={DownArrow}
                      >
                        <MenuItem value="Fresh Food">Fresh Food</MenuItem>
                        <MenuItem value="Packaged Food">Packaged Food</MenuItem>
                      </Select>
                      {touched.foodStoreType && errors.foodStoreType && (
                        <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>
                          {errors.foodStoreType}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}

                  <Stack spacing={3} direction="row">
                    <Box sx={{ flex: 1 }}>
                      <Typography className={styles.inputTitle}>Upload</Typography>
                      <FileUploadBtn
                        setFieldValue={setFieldValue}
                        file={values.categoryIcon}
                        errors={errors}
                        touched={touched}
                        name="categoryIcon"
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Card>
            </FormikProvider>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

const FileUploadBtn = ({ setFieldValue, file, touched, errors, name }) => {
  const theme = useTheme();

  const inputFile = useRef(null);
  const onClickAdd = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleOnChange = (e) => {
    setFieldValue(name, e.target.files[0]);
  };

  return (
    <FormControl
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        width: 120,
      }}
    >
      <Button
        onClick={onClickAdd}
        sx={{
          border: '1.05263px solid #CDCCCE',
          padding: '0px',
          overflow: 'hidden',
          width: 120,
          height: 120,
        }}
      >
        {!file && (
          <Box>
            <AddFileIcon />
            <Typography className={styles.addText}>Add Icon</Typography>
          </Box>
        )}

        {file && (
          <CardMedia component="img" height={120} image={typeof file === 'string' ? file : URL.createObjectURL(file)} />
        )}

        <input accept="image/*" type="file" onChange={handleOnChange} ref={inputFile} name={name} hidden id={name} />
      </Button>
      {touched.categoryIcon && errors.categoryIcon && (
        <FormHelperText sx={{ color: theme.palette.error.main, marginLeft: 0 }}>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default AddCategoryModal;
