import client from './Client';

const endPoint = '/su//sales-person/';

const addSalesPerson = (value) => {
  const token = localStorage.getItem('token');
  return client.post(
    endPoint,
    value,
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { addSalesPerson };
