import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import styles from './Invoices.module.scss';

const SettledInvoicesTable = ({ settledInvoices }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#F5F4F4' }}>
            <TableCell align="left" width="216px" className={styles.tableCell}>
              Invoice Number
            </TableCell>
            <TableCell align="center" width="225px" className={styles.tableCell}>
              Business Name
            </TableCell>
            <TableCell align="center" width="85px" className={styles.tableCell}>
              Vertical
            </TableCell>
            <TableCell align="center" width="118px" className={styles.tableCell}>
              Invoice Date
            </TableCell>
            <TableCell align="center" width="131px" className={styles.tableCell}>
              Order Number
            </TableCell>
            <TableCell align="center" width="113px" className={styles.tableCell}>
              Order Date
            </TableCell>
            <TableCell align="center" width="112px" className={styles.tableCell} sx={{ borderRight: '0 !important' }}>
              Value ₹
            </TableCell>
          </TableRow>
        </TableHead>

        {settledInvoices?.length && (
          <TableBody>
            {(settledInvoices || []).map((item, index) => {
              return (
                <TableRow key={index} className={styles.tableRow}>
                  <TableCell className={styles.tableDataCell}>
                    <Typography
                      className={styles.linkText}
                    >
                      {item?.invoiceNumber}
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={styles.tableDataCell}
                    align="center"
                    onClick={() => navigate(`/dashboard/invoices/${item?.storeId}`)}
                  >
                    {item?.storeName}
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    <Box
                      sx={{
                        width: 11,
                        height: 11,
                        borderRadius: '50%',
                        margin: 'auto',
                        background: theme.palette[(item?.vertical).toLowerCase()].main,
                      }}
                    />
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    {item?.invoiceDate}
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {item?.orderNumber}
                    </Typography>
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '140%',
                      }}
                    >
                      {item?.orderDate}
                    </Typography>
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '140%',
                      }}
                    >
                      {item?.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default SettledInvoicesTable;
