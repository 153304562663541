import client from "./Client";

const endPoint = '/su/carousel/';

const updateCarousel = (value, id) => {
    const token = localStorage.getItem("token");
    return client.put(endPoint + id, value, {
        headers: {
            Authorization: token
        }
    })
}

export { updateCarousel }