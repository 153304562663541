import React, { Fragment, useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import getS3Bucket from '../../utils/getS3Bucket';

import { CreateNewTemplate } from '../../api/CreateNewTemplate';
import { CreateNewMedia } from '../../api/CreateNewMedia';
import { getTemplateData } from '../../api/GetTemplateData';
import { getMediaData } from '../../api/GetMediaData';
import { sendWhatsappMessage } from '../../api/SendWhatsappMessage';
import { sendWhatsappCsvFileMessage } from '../../api/SendWhatsappCsvFileMessage';
import { deleteMedia } from '../../api/DeleteWhatsappMedia';
import { deleteTemplate } from '../../api/DeleteWhatsappTemplate';
import { getUserCount } from '../../api/GetContactsCount';
import { uploadUserContacts } from '../../api/UploadUserContacts';

import Loader from '../../components/Loader/Loader';

import styles from '../../pageStyles/pageStyles.module.scss';
import Iconify from '../../components/Iconify';
import useModal from '../../hooks/useModal';
import { ReactComponent as MaximizeImage } from '../../assets/icons/maximizeImage.svg';
import { ReactComponent as MaximizeVideo } from '../../assets/icons/maximizeVideo.svg';
import { ReactComponent as DownArrow } from '../../assets/icons/downArrow.svg';
import { ReactComponent as UploadMediaAddIcon } from '../../assets/icons/uploadMediaAddIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import { ReactComponent as ImportIcon } from '../../assets/icons/importIcon.svg';

import FullScreenMediaModal from '../../components/FullScreenMediaModal/FullScreenMediaModal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';

const Input = styled('input')({
  display: 'none',
});
const InputCsv = styled('input')({
  display: 'none',
});
const propagationTabs = ['Send Message', 'Upload Media', 'Create Template', 'Get Media & Text', 'Contact Details'];
const userType = ['Sellers', 'Buyers', 'General Users', 'Targeted Sellers', 'Targeted Buyers'];
const validImagesType = ['jpeg', 'png'];
const validVideosType = ['mp4', '3gp'];
const Propagation = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState('Send Message');
  const [expandMediaDropDown, setExpandMediaDropdown] = useState(false);
  const [expandTemplateDropDown, setExpandTemplateDropdown] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [templateType, setTemplateType] = useState('');
  const { showFullScreenMediaModal, handleFullScreenMediaModal, confirmationModal, handleConfirmationModal } =
    useModal();
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const [recipient, setRecepient] = useState([]);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [fileError, setFileError] = useState();
  const [fileName, setFileName] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateType, setNewTemplateType] = useState('');
  const [template, setTemplate] = useState([]);
  const [media, setMedia] = useState([]);
  const [mediaId, setMediaId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [meidaName, setMediaName] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [newContactsType, setNewContactsType] = useState('');
  const [userCountDataLocal, setUserCountDataLocal] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [isApiInProgres, setIsApiInProgress] = useState(false);
  const [recipientCsvFile, setRecipientCsvFile] = useState(null);
  const recipientSelected = (checkStatus, recipientText) => {
    if (checkStatus) {
      setRecepient((prev) => [...prev, recipientText]);
    } else {
      setRecepient((prev) => {
        prev.splice(prev.indexOf(recipientText), 1);
        return [...prev];
      });
    }
  };

  const checkButtonDisability = () => {
    if (currentTab === 'Create Template' && (newTemplateName === '' || newTemplateType === '')) {
      return true;
    }
    if (currentTab === 'Upload Media' && (file === null || fileError || fileName === '')) {
      return true;
    }
    if (currentTab === 'Send Message') {
      if (templateType === '') {
        return true;
      }
      if (templateType === 'Text' && (templateName === '' || (recipient?.length === 0 && recipientCsvFile === null))) {
        return true;
      }
      if (
        (templateType === 'Image' || templateType === 'Video') &&
        (templateName === '' || (recipient?.length === 0 && recipientCsvFile === null) || mediaId === '')
      ) {
        return true;
      }
    }
    if (currentTab === 'Contact Details' && (newContactsType === '' || csvFile === null)) {
      return true;
    }
    return false;
  };

  const createNewTemplateMutation = useMutation(
    'createNewTemplate',
    () => CreateNewTemplate({ templateName: newTemplateName, type: newTemplateType }),
    {
      onSuccess: ({ data }) => {
        setIsApiInProgress(false);
        if (data?.success) {
          setNewTemplateName('');
          setNewTemplateType('');
          toast.success(data?.message);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const createNewMediaMutation = useMutation(
    'createNewTemplate',
    () => CreateNewMedia({ type: fileType, media: file, fileName: fileName }),
    {
      onSuccess: ({ data }) => {
        setIsApiInProgress(false);
        if (data?.success) {
          setFile(null);
          setFileError();
          setFileType('');
          setFileName('');
          toast.success(data?.message);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const sendWhatsappMessageMutation = useMutation(
    'sendWhatsappMessage',
    () =>
      sendWhatsappMessage({
        userType: recipient,
        template: templateName,
        type: templateType,
        mediaId: templateType === 'Text' ? undefined : mediaId,
      }),
    {
      onSuccess: ({ data }) => {
        setIsApiInProgress(false);
        if (data?.success) {
          setTemplateType('');
          setTemplateName('');
          setMediaId('');
          setMediaName('');
          setRecepient([]);
          setRecipientCsvFile(null);
          setExpandMediaDropdown(false);
          setExpandTemplateDropdown(false);
          setIsApiInProgress(false);
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const sendWhatsappMessageCsvFileMutation = useMutation(
    'sendWhatsappMessage',
    () =>
      sendWhatsappCsvFileMessage({
        csvFile: recipientCsvFile,
        template: templateName,
        type: templateType,
        mediaId: templateType === 'Text' ? undefined : mediaId,
      }),
    {
      onSuccess: ({ data }) => {
        setIsApiInProgress(false);
        if (data?.success) {
          setTemplateType('');
          setTemplateName('');
          setMediaId('');
          setMediaName('');
          setRecepient([]);
          setRecipientCsvFile(null);
          setExpandMediaDropdown(false);
          setExpandTemplateDropdown(false);
          setIsApiInProgress(false);
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const executeTemplateApi = () => {
    if (
      currentTab === 'Send Message' &&
      (templateType === 'Text' || templateType === 'Video' || templateType === 'Image')
    ) {
      return true;
    }
    if (currentTab === 'Get Media & Text' && templateType === 'Template') {
      return true;
    }
    return false;
  };

  const {
    data: templateData,
    isFetching: templateFetching,
    refetch: refetchTemplate,
  } = useQuery(
    ['getTemplate', currentTab, templateType],
    () => getTemplateData(templateType === 'Template' ? undefined : templateType),
    {
      enabled: executeTemplateApi(),
    }
  );

  useEffect(() => {
    if (templateData?.data?.success) {
      setTemplate(templateData?.data?.data);
    } else {
      setTemplate([]);
    }
  }, [templateData]);

  const {
    data: mediaData,
    isLoading: mediaFetching,
    refetch: refetchMedia,
  } = useQuery(['getMedia', currentTab, templateType], () => getMediaData(templateType), {
    enabled:
      (templateType === 'Image' || templateType === 'Video') &&
      (currentTab === 'Get Media & Text' || currentTab === 'Send Message'),
  });

  useEffect(() => {
    if (mediaData?.data?.success) {
      setMedia(mediaData?.data?.data);
    } else {
      setMedia([]);
    }
  }, [mediaData]);

  const {
    data: userCountData,
    isFetching: userDataFetching,
    refetch: refetchUserData,
  } = useQuery(['getUserCount', currentTab], () => getUserCount(), {
    enabled: currentTab === 'Contact Details',
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (userCountData?.data?.success) {
      setUserCountDataLocal(userCountData?.data?.data);
    } else {
      setUserCountDataLocal({});
    }
  }, [userCountData]);

  const deleteMediaMutation = useMutation('deleteMedia', () => deleteMedia(mediaId), {
    onSuccess: ({ data }) => {
      setIsApiInProgress(false);
      setMediaId('');
      if (data?.success) {
        refetchMedia();
        toast.success(data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const deleteTemplateMutation = useMutation('deleteMedia', () => deleteTemplate(templateId), {
    onSuccess: ({ data }) => {
      setIsApiInProgress(false);
      setTemplateId('');
      if (data?.success) {
        refetchTemplate();
        toast.success(data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (file) {
      const validFileSize = Math.ceil(file?.size / 1048576);
      const fileType = file?.name?.split('.')?.pop()?.toLocaleLowerCase();
      if (validImagesType.includes(fileType)) {
        setFileType('Image');
        if (validFileSize > 5) {
          setFileError('Max 5MB for image.');
        } else {
          setFileError();
        }
      } else if (validVideosType.includes(fileType)) {
        setFileType('Video');
        if (validFileSize > 16) {
          setFileError('Max 16MB for video.');
        } else {
          setFileError();
        }
      } else {
        setFileError('Invalid file (Valid file formats: jpeg,png,mp4,3gp)');
      }
    }
  }, [file]);

  const uploadUserContactsMutation = useMutation(
    'uploadUserContacts',
    () => uploadUserContacts({ type: newContactsType, csvFile: csvFile }),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          setNewContactsType('');
          setCsvFile(null);
          refetchUserData();
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    setMediaId('');
    setMediaName('');
    setTemplateName('');
    setRecepient([]);
    setRecipientCsvFile(null);
  }, [templateType]);

  useEffect(() => {
    setExpandMediaDropdown(false);
    setExpandTemplateDropdown(false);
    setSearchText('');
    setTemplateType('');
    setFullScreenUrl('');
    setRecepient([]);
    setFile(null);
    setFileType('');
    setFileError();
    setFileName('');
    setNewTemplateName('');
    setNewTemplateType('');
    setTemplate([]);
    setMedia([]);
    setMediaId('');
    setTemplateName('');
    setMediaName('');
    setTemplateId('');
    setNewContactsType('');
    setUserCountDataLocal('');
    setCsvFile(null);
    setIsApiInProgress(false);
    setRecipientCsvFile(null);
  }, [currentTab]);

  const getHeading = (key) => {
    if (key === 'users') {
      return 'Buyers';
    }
    if (key === 'sellers') {
      return 'Sellers';
    }
    if (key === 'targetedBuyers') {
      return 'Targeted Buyers';
    }
    if (key === 'targetedSellers') {
      return 'Targeted Sellers';
    }
    if (key === 'generalUsers') {
      return 'General Users';
    }
    return '';
  };

  console.log(csvFile);

  const getTotalUsersCount = () => {
    const allKeys = Object.keys(userCountDataLocal);
    let total = 0;

    for (let i = 0; i < allKeys?.length; i += 1) {
      total += userCountDataLocal[allKeys[i]];
    }

    return total;
  };

  if (isApiInProgres || userDataFetching) {
    return <Loader isLoaderVisible />;
  }

  const noMediaFound = () => {
    let count = 0;
    for (let i = 0; i < media?.length; i += 1) {
      if (media[i]?.fileName?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase())) {
        count += 1;
      }
    }
    return Boolean(count);
  };

  const noTemplateFound = () => {
    let count = 0;
    for (let i = 0; i < template?.length; i += 1) {
      if (template[i]?.templateName?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase())) {
        count += 1;
      }
    }
    return Boolean(count);
  };

  return (
    <Box className={styles.propogationWrapper}>
      <Box className={styles.propogationTabWRapper}>
        {propagationTabs?.map((title, index) => {
          return (
            <Box key={index} className={styles.tabButton} onClick={() => setCurrentTab(title)}>
              <Typography className={`${styles.tabText} ${currentTab === title && styles.activeText}`}>
                {title}
              </Typography>
              {currentTab === title && <Box className={styles.active}> </Box>}
            </Box>
          );
        })}
      </Box>
      {currentTab === 'Send Message' && (
        <Box className={styles.propogationForm}>
          <Box className={styles.typeSection}>
            <FormControl>
              <Typography className={styles.sectionHeading}>Select Type</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                className={styles.mainRadioWrapper}
                onChange={(e) => setTemplateType(e.target.value)}
              >
                <FormControlLabel
                  value="Image"
                  control={<Radio size="small" className={styles.radioButton} checked={templateType === 'Image'} />}
                  label={<Typography className={styles.radioText}>Image</Typography>}
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value="Video"
                  control={<Radio size="small" className={styles.radioButton} checked={templateType === 'Video'} />}
                  label={<Typography className={styles.radioText}>Video</Typography>}
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value="Text"
                  control={<Radio size="small" className={styles.radioButton} checked={templateType === 'Text'} />}
                  label={<Typography className={styles.radioText}>Text</Typography>}
                  className={styles.radioWrapper}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {(templateType === 'Image' || templateType === 'Video') && (
            <Box className={styles.mediaSection}>
              <Typography className={styles.sectionHeading}>{templateType}</Typography>
              <Box
                className={styles.mediaDropdown}
                onClick={() => {
                  setExpandTemplateDropdown(false);
                  setSearchText('');
                  setExpandMediaDropdown(!expandMediaDropDown);
                }}
              >
                <Typography>{meidaName === '' ? `Select ${templateType}` : meidaName}</Typography>
                <Box className={`${styles.downArrowWrapper} ${expandMediaDropDown && styles.rotatedDownArrow}`}>
                  <DownArrow />
                </Box>
              </Box>
              <Box className={`${styles.mediaListWrapper} ${!expandMediaDropDown && styles.noHeight}`}>
                <OutlinedInput
                  value={searchText}
                  placeholder={`Search ${templateType.toLocaleLowerCase()} by name`}
                  className={styles.sectionSearch}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Box className={styles.mediaScrollList}>
                  {media?.map((mediaData, index) => {
                    if (mediaData?.fileName?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())) {
                      return (
                        <Fragment key={index}>
                          <Box className={styles.divider} />
                          <Box
                            className={styles.mediaListElement}
                            onClick={() => {
                              setMediaId(mediaData?.mediaId);
                              setMediaName(mediaData?.fileName);
                              setExpandMediaDropdown(false);
                            }}
                          >
                            <Box
                              className={styles.mediaTile}
                              onClick={() => {
                                handleFullScreenMediaModal();

                                setFullScreenUrl(mediaData?.mediaId);
                              }}
                            >
                              <Box className={styles.mediaMaximixer}>
                                {templateType === 'Image' ? <MaximizeImage /> : <MaximizeVideo />}
                              </Box>
                              <CardMedia
                                component={mediaData?.type === 'Image' ? 'img' : 'video'}
                                src={getS3Bucket() && mediaData?.mediaUrl ? getS3Bucket() + mediaData?.mediaId : ''}
                                className={styles.mediaPic}
                              />
                            </Box>

                            <Typography className={styles.mediaText}>{mediaData?.fileName}</Typography>
                          </Box>
                        </Fragment>
                      );
                    }
                    return null;
                  })}
                  {!noMediaFound() && (
                    <Box className={styles.mediaListElementNotFound}>
                      <Typography className={styles.sectionHeading}>No data found</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          {templateType !== '' && (
            <Box className={styles.templateSection}>
              <Typography className={styles.sectionHeading}>Template</Typography>
              <Box
                className={styles.templateDropdown}
                onClick={() => {
                  setExpandMediaDropdown(false);
                  setSearchText('');
                  setExpandTemplateDropdown(!expandTemplateDropDown);
                }}
              >
                <Typography>{templateName === '' ? 'Select Template' : templateName}</Typography>
                <Box className={`${styles.downArrowWrapper} ${expandTemplateDropDown && styles.rotatedDownArrow}`}>
                  <DownArrow />
                </Box>
              </Box>

              <Box className={`${styles.templateListWrapper} ${!expandTemplateDropDown && styles.noHeight}`}>
                <OutlinedInput
                  value={searchText}
                  placeholder="Search template by name"
                  className={styles.sectionSearch}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Box className={styles.templateScrollList}>
                  {template?.map((templateData, index) => {
                    if (templateData?.templateName?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())) {
                      return (
                        <Fragment key={index}>
                          <Box className={styles.divider} />
                          <Box
                            className={styles.templateListElement}
                            onClick={() => {
                              setTemplateName(templateData?.templateName);
                              setExpandTemplateDropdown(false);
                            }}
                          >
                            <Typography className={styles.mediaText}>{templateData?.templateName}</Typography>
                          </Box>
                        </Fragment>
                      );
                    }
                    return null;
                  })}
                  {!noTemplateFound() && (
                    <Box className={styles.mediaListElementNotFound}>
                      <Typography className={styles.sectionHeading}>No data found</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          {templateType !== '' && recipientCsvFile === null && (
            <Box className={styles.checkBoxSection}>
              <Typography className={styles.sectionHeading}>Select User</Typography>
              {userType?.map((userTitle, index) => {
                return (
                  <Box className={styles.checkBoxWrapper} key={index}>
                    <Checkbox
                      id={`userType${index}`}
                      size="small"
                      className={styles.checkBox}
                      onChange={(e) => recipientSelected(e.target.checked, userTitle)}
                      checked={recipient.includes(userTitle)}
                    />
                    <Typography component={'label'} htmlFor={`userType${index}`} className={styles.mediaText}>
                      {userTitle}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}

          {templateType !== '' && recipient?.length === 0 && recipientCsvFile === null && <Divider />}
          {templateType !== '' && recipient?.length === 0 && (
            <Box className={styles.typeSection}>
              <Typography className={styles.sectionHeading}>Upload Contacts</Typography>
              <Input
                accept={'.csv'}
                id={'recipientCsvUpload'}
                name={'recipientCsvUpload'}
                type={'file'}
                sx={{ display: 'none' }}
                onChange={(e) => {
                  setRecipientCsvFile(e.target.files[0]);
                  e.target.value = '';
                }}
              />
              <Box className={styles.importWrapper} htmlFor={'recipientCsvUpload'} component={'label'}>
                <ImportIcon />
                <Typography className={styles.importText}>Import CSV File</Typography>
              </Box>
              {recipientCsvFile !== null && (
                <Box className={styles.delWrapper}>
                  <Typography className={styles.importText}>{recipientCsvFile?.name}</Typography>
                  <IconButton onClick={() => setRecipientCsvFile(null)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
          {templateType !== '' && (
            <Box>
              <Button
                className={styles.sendButton}
                disabled={isApiInProgres || checkButtonDisability()}
                onClick={() => {
                  setIsApiInProgress(true);
                  if (recipientCsvFile) {
                    sendWhatsappMessageCsvFileMutation.mutateAsync();
                  } else {
                    sendWhatsappMessageMutation.mutateAsync();
                  }
                }}
              >
                Send
              </Button>
            </Box>
          )}
        </Box>
      )}

      {currentTab === 'Upload Media' && (
        <Box className={styles.propogationForm}>
          <Box className={styles.typeSection}>
            <Typography className={styles.sectionHeading}>Image / Video</Typography>
            {file === null ? (
              <IconButton component={'label'} htmlFor={'templateMediaUpload'} className={styles.imageSelector}>
                <UploadMediaAddIcon />
                <Typography className={styles.uploadText}> Upload Image / Video</Typography>
              </IconButton>
            ) : (
              <Box>
                <Box className={styles.uploadedMediaViewer}>
                  <CardMedia
                    component={fileType === 'Image' ? 'img' : 'video'}
                    src={URL.createObjectURL(file)}
                    className={styles.uploadedMedia}
                  />
                  <IconButton
                    className={styles.imageRemoveButton}
                    onClick={() => {
                      setFileType('');
                      setFileError();
                      setFile(null);
                    }}
                  >
                    <ClearOutlinedIcon
                      sx={{
                        color: theme.palette.primary.main,
                        fontSize: 20,
                      }}
                    />
                  </IconButton>
                  <Box
                    className={styles.mediaMaximixer}
                    onClick={() => {
                      setFullScreenUrl(URL.createObjectURL(file));
                      handleFullScreenMediaModal();
                    }}
                  >
                    {fileType === 'Image' ? <MaximizeImage /> : <MaximizeVideo />}
                  </Box>
                </Box>
                {fileError !== undefined && <Typography className={styles.errorMessage}>{fileError}</Typography>}
              </Box>
            )}
            <Input
              accept={['.jpeg', '.png', '.mp4', '3gp']}
              id={'templateMediaUpload'}
              type={'file'}
              sx={{ display: 'none' }}
              onChange={(e) => {
                setFile(e.target.files[0]);
                e.target.value = '';
              }}
            />
          </Box>

          <Box className={styles.typeSection}>
            <Typography className={styles.sectionHeading}>Media name</Typography>
            <OutlinedInput value={fileName} sx={{ height: '40px' }} onChange={(e) => setFileName(e.target.value)} />
          </Box>
          <Box className={styles.typeSection}>
            <Button
              className={styles.sendButton}
              disabled={isApiInProgres || checkButtonDisability()}
              onClick={() => {
                setIsApiInProgress(true);
                createNewMediaMutation.mutateAsync();
              }}
            >
              Upload
            </Button>
          </Box>
        </Box>
      )}
      {currentTab === 'Create Template' && (
        <Box className={styles.propogationForm}>
          <Box className={styles.typeSection}>
            <FormControl>
              <Typography className={styles.sectionHeading}>Select Template Type</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                className={styles.mainRadioWrapper}
                onChange={(e) => setNewTemplateType(e.target.value)}
              >
                <FormControlLabel
                  value="Image"
                  control={<Radio size="small" className={styles.radioButton} checked={newTemplateType === 'Image'} />}
                  label={<Typography className={styles.radioText}>Image</Typography>}
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value="Video"
                  control={<Radio size="small" className={styles.radioButton} checked={newTemplateType === 'Video'} />}
                  label={<Typography className={styles.radioText}>Video</Typography>}
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value="Text"
                  control={<Radio size="small" className={styles.radioButton} checked={newTemplateType === 'Text'} />}
                  label={<Typography className={styles.radioText}>Text</Typography>}
                  className={styles.radioWrapper}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className={styles.typeSection}>
            <Typography className={styles.sectionHeading}>Template name</Typography>
            <OutlinedInput
              value={newTemplateName}
              sx={{ height: '40px' }}
              onChange={(e) => setNewTemplateName(e.target.value)}
            />
          </Box>
          <Box className={styles.typeSection}>
            <Button
              className={styles.sendButton}
              disabled={checkButtonDisability()}
              onClick={() => {
                setIsApiInProgress(true);
                createNewTemplateMutation.mutateAsync();
              }}
            >
              Create
            </Button>
          </Box>
        </Box>
      )}
      {currentTab === 'Get Media & Text' && (
        <Box className={styles.propogationForm}>
          <Box className={styles.typeSection}>
            <FormControl>
              <Typography className={styles.sectionHeading}>Select Template Type</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                className={styles.mainRadioWrapper}
                onChange={(e) => setTemplateType(e.target.value)}
              >
                <FormControlLabel
                  value="Image"
                  control={<Radio size="small" className={styles.radioButton} checked={templateType === 'Image'} />}
                  label={<Typography className={styles.radioText}>Image</Typography>}
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value="Video"
                  control={<Radio size="small" className={styles.radioButton} checked={templateType === 'Video'} />}
                  label={<Typography className={styles.radioText}>Video</Typography>}
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value="Template"
                  control={<Radio size="small" className={styles.radioButton} checked={templateType === 'Template'} />}
                  label={<Typography className={styles.radioText}>Template</Typography>}
                  className={styles.radioWrapper}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {templateType !== '' && (
            <Box className={styles.typeSection}>
              {templateType === 'Image' || templateType === 'Video' ? (
                <Box className={styles.mediaSection}>
                  <Typography className={styles.sectionHeading}>{templateType}</Typography>
                  {/* <Box
                  className={styles.mediaDropdown}
                  onClick={() => {
                    setExpandTemplateDropdown(false);
                    setSearchText('');
                    setExpandMediaDropdown(!expandMediaDropDown);
                  }}
                >
                  <Typography>{meidaName === '' ? `Select ${templateType}` : meidaName}</Typography>
                  <Box className={`${styles.downArrowWrapper} ${expandMediaDropDown && styles.rotatedDownArrow}`}>
                    <DownArrow />
                  </Box>
                </Box> */}
                  {/* <Box className={`${styles.mediaListWrapper} ${!expandMediaDropDown && styles.noHeight}`}> */}
                  <Box className={`${styles.mediaListWrapper}`}>
                    <OutlinedInput
                      value={searchText}
                      placeholder={`Search ${templateType.toLocaleLowerCase()} by name`}
                      className={styles.sectionSearch}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Box className={styles.mediaScrollList}>
                      {media?.map((mediaData, index) => {
                        if (mediaData?.fileName?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())) {
                          return (
                            <Fragment key={index}>
                              <Box className={styles.divider} />
                              <Box
                                className={styles.mediaListElementDel}
                                onClick={() => {
                                  setMediaId(mediaData?.mediaId);
                                  setMediaName(mediaData?.fileName);
                                  setExpandMediaDropdown(false);
                                }}
                              >
                                <Box
                                  className={styles.mediaTile}
                                  onClick={() => {
                                    handleFullScreenMediaModal();
                                    setFullScreenUrl(mediaData?.mediaId);
                                  }}
                                >
                                  <Box className={styles.mediaMaximixer}>
                                    {templateType === 'Image' ? <MaximizeImage /> : <MaximizeVideo />}
                                  </Box>
                                  <CardMedia
                                    component={mediaData?.type === 'Image' ? 'img' : 'video'}
                                    src={getS3Bucket() && mediaData?.mediaUrl ? getS3Bucket() + mediaData?.mediaId : ''}
                                    className={styles.mediaPic}
                                  />
                                </Box>

                                <Typography className={styles.mediaText}>{mediaData?.fileName}</Typography>
                                <Box
                                  sx={{ width: '20px' }}
                                  onClick={() => {
                                    setMediaId(mediaData?.mediaId);
                                    handleConfirmationModal();
                                  }}
                                >
                                  <DeleteIcon />
                                </Box>
                              </Box>
                            </Fragment>
                          );
                        }
                        return null;
                      })}
                      {!noMediaFound() && (
                        <Box className={styles.mediaListElementNotFound}>
                          <Typography className={styles.sectionHeading}>No data found</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box className={styles.templateSection}>
                  <Typography className={styles.sectionHeading}>Template</Typography>
                  {/* <Box
                  className={styles.templateDropdown}
                  onClick={() => {
                    setExpandMediaDropdown(false);
                    setSearchText('');
                    setExpandTemplateDropdown(!expandTemplateDropDown);
                  }}
                >
                  <Typography>{templateName === '' ? 'Select Template' : templateName}</Typography>
                  <Box className={`${styles.downArrowWrapper} ${expandTemplateDropDown && styles.rotatedDownArrow}`}>
                    <DownArrow />
                  </Box>
                </Box>

                <Box className={`${styles.templateListWrapper} ${!expandTemplateDropDown && styles.noHeight}`}> */}
                  <Box className={`${styles.templateListWrapper}`}>
                    <OutlinedInput
                      value={searchText}
                      placeholder="Search template by name"
                      className={styles.sectionSearch}
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Box className={styles.templateScrollList}>
                      {template?.map((templateData, index) => {
                        if (templateData?.templateName?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())) {
                          return (
                            <Fragment key={index}>
                              <Box className={styles.divider} />
                              <Box
                                className={styles.templateListElement}
                                onClick={() => {
                                  setTemplateName(templateData?.templateName);
                                  setExpandTemplateDropdown(false);
                                }}
                              >
                                <Typography className={styles.mediaText}>{templateData?.templateName}</Typography>
                                <Box
                                  sx={{ width: '20px' }}
                                  onClick={() => {
                                    setTemplateId(templateData?._id);
                                    handleConfirmationModal();
                                  }}
                                >
                                  <DeleteIcon />
                                </Box>
                              </Box>
                            </Fragment>
                          );
                        }
                        return null;
                      })}
                      {!noTemplateFound() && (
                        <Box className={styles.mediaListElementNotFound}>
                          <Typography className={styles.sectionHeading}>No data found</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      {currentTab === 'Contact Details' && (
        <Box className={styles.propogationForm}>
          <Box className={styles.typeSection}>
            <Typography className={styles.sectionHeading}>Contacts - {getTotalUsersCount()}</Typography>
            <Box className={styles.countWraperMain}>
              {Object.keys(userCountDataLocal)?.map((key, index) => {
                return (
                  <Box key={index} className={styles.coutWrapperElement}>
                    <Typography className={styles.countHeading}>{getHeading(key)}</Typography>
                    <Typography className={styles.countText}>{userCountDataLocal[key]}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box className={styles.typeSection}>
            <FormControl>
              <Typography className={styles.sectionHeading}>Upload Contacts</Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                className={styles.mainRadioWrapper}
                onChange={(e) => setNewContactsType(e.target.value)}
              >
                <FormControlLabel
                  value="Targeted Buyers"
                  control={
                    <Radio
                      size="small"
                      className={styles.radioButton}
                      checked={newContactsType === 'Targeted Buyers'}
                    />
                  }
                  label={<Typography className={styles.radioText}>Targeted Buyers</Typography>}
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value="Targeted Sellers"
                  control={
                    <Radio
                      size="small"
                      className={styles.radioButton}
                      checked={newContactsType === 'Targeted Sellers'}
                    />
                  }
                  label={<Typography className={styles.radioText}>Targeted Sellers</Typography>}
                  className={styles.radioWrapper}
                />
              </RadioGroup>
            </FormControl>

            <Input
              accept={'.csv'}
              id={'contactsCsvUpload'}
              name={'contactsCsvUpload'}
              type={'file'}
              sx={{ display: 'none' }}
              onChange={(e) => {
                setCsvFile(e.target.files[0]);
                e.target.value = '';
              }}
            />
            <Box className={styles.importWrapper} htmlFor={'contactsCsvUpload'} component={'label'}>
              <ImportIcon />
              <Typography className={styles.importText}>Import CSV File</Typography>
            </Box>
            {csvFile !== null && (
              <Box className={styles.delWrapper}>
                <Typography className={styles.importText}>{csvFile?.name}</Typography>
                <IconButton onClick={() => setCsvFile(null)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box className={styles.typeSection}>
            <Button
              className={styles.sendButton}
              disabled={isApiInProgres || checkButtonDisability()}
              onClick={() => {
                // setIsApiInProgress(true);
                uploadUserContactsMutation.mutateAsync();
              }}
            >
              Upload
            </Button>
          </Box>
        </Box>
      )}
      <FullScreenMediaModal
        open={showFullScreenMediaModal}
        handleModal={handleFullScreenMediaModal}
        url={fullScreenUrl}
        mediaType={currentTab === 'Send Message' || currentTab === 'Get Media & Text' ? templateType : fileType}
        currentTab={currentTab}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={'Are you sure you want to delete?'}
        onConfirm={() => {
          setIsApiInProgress(true);
          if (templateType === 'Template') {
            deleteTemplateMutation.mutateAsync();
          } else {
            deleteMediaMutation.mutateAsync();
          }
        }}
      />
    </Box>
  );
};

export default Propagation;
