import { Box, Container, SvgIcon } from "@mui/material";
import styles from "./Loader.module.scss";
import { ReactComponent as LoaderIcon } from "../../assets/icons/loader.svg";

const Loader = ({ isLoaderVisible }) => {
    if (isLoaderVisible) {
        return (
            <Container className={styles.mainLayout}>
                <Box className={styles.loaderIconContainer}>
                    <SvgIcon viewBox="0 0 200 200" className={styles.loaderIcon} sx={{ fontSize: 200 }}>
                        <LoaderIcon />
                    </SvgIcon>
                </Box>
            </Container>
        );
    }
    return null;
}

export default Loader;