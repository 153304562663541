import client from "./Client";

const endPoint = '/su/order/';

const getOrder = ({ id, type }) => {
    const token = localStorage.getItem("token");

    return client.get(
        endPoint + id,
        {
            type
        },
        {
            headers: {
                Authorization: token
            }
        }
    )
}

export { getOrder }