import client from './Client';


const createSubCategory = ({ catId, name, hsnCode,typeOfStore }) => {
    const token = localStorage.getItem('token');

    const endPoint = `/su/category/${catId}/sub-category`;

    return client.put(endPoint, { name, hsnCode,typeOfStore }, {
        headers: {
            Authorization: token,
        },
    });
};

export { createSubCategory };
