import { useState } from "react";
import { useQuery } from "react-query";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import QuestionHeader from "../../components/Custom/QuestionHeader/QuestionHeader";
import HeadingForm from "../../components/Custom/HeadingForm/HeadingForm";
import Page from "../../components/Page";
import { ReactComponent as AddButton } from "../../assets/icons/addIcon.svg";
import { getAllFaqs } from "../../api/GetAllFaqs";
import Loader from "../../components/Loader/Loader";
import styles from "../../pageStyles/pageStyles.module.scss";

const Faq = () => {
    const [openForm, setOpenForm] = useState(false);

    const setShowForm = () => {
        setOpenForm(!openForm)
    }

    const { data, isLoading, refetch } = useQuery(
        "getAllFaqs",
        () => getAllFaqs(),
        {
            refetchOnWindowFocus: false
        }
    )

    const questionHeaders = data?.data?.data;

    return (
        <Page title="FAQs">
            {
                !isLoading && (
                    <>
                        <Box className={`${openForm ? styles.openQuestionHeader : styles.closeQuestionHeader}`}>
                            <Stack direction="row" alignItems="center" gap="15px">
                                <IconButton className={`${openForm ? styles.closeAddButton : styles.addBtn}`} onClick={() => setShowForm()}>
                                    <AddButton />
                                </IconButton>
                                <Typography className={styles.heading}>Add Heading</Typography>
                            </Stack>
                            <Box className={styles.formContainer}>
                                <HeadingForm setShowForm={setShowForm} refetch={refetch} />
                            </Box>
                        </Box >

                        {
                            data?.data?.success && (
                                <Box className={styles.headerContainer}>
                                    {
                                        (questionHeaders || []).map((item, index) => {
                                            return (
                                                <Box key={index}>
                                                    <QuestionHeader
                                                        headerId={item?._id}
                                                        header={item?.heading}
                                                        questions={item?.questions}
                                                        refetch={refetch}
                                                        setShowForm={setShowForm}
                                                    />
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            )
                        }

                    </>
                )
            }
            {isLoading && <Loader isLoaderVisible />}
        </Page>
    );
};

export default Faq;
