import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Button
} from '@mui/material';
import styles from './Invoices.module.scss';

const InProcessInvoicesTable = ({ inProcessInvoices, handleConfirmationModal, setId,setCNInvoiceId,handleCreditNotesModal,setCreditNotes,setInvoiceNumber,setOrderNumber,setUserId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#F5F4F4' }}>
            <TableCell align="left" width="130px" className={styles.tableCell} sx={{ paddingLeft: '15px !important' }}>
              Invoice Number
            </TableCell>
            <TableCell align="center" width="150px" className={styles.tableCell}>
              Business Name
            </TableCell>
            <TableCell align="center" width="75px" className={styles.tableCell}>
              Vertical
            </TableCell>
            <TableCell align="center" width="108px" className={styles.tableCell}>
              Invoice Date
            </TableCell>
            <TableCell align="center" width="121px" className={styles.tableCell}>
              Order Number
            </TableCell>
            <TableCell align="center" width="103px" className={styles.tableCell}>
              Order Date
            </TableCell>
            <TableCell align="center" width="102px" className={styles.tableCell}>
              Value ₹
            </TableCell>
            <TableCell
              align="center"
              width="101px"
              sx={{ borderRight: '0 !important', padding: '0 !important' }}
              className={styles.tableCell}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {inProcessInvoices?.length && (
          <TableBody>
            {(inProcessInvoices || []).map((item, index) => {
              return (
                <TableRow key={index} sx={{ '&:nth-child(even)': { backgroundColor: '#F9F9F9' } }}>
                  <TableCell
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      paddingLeft: '15px',
                      fontSize: 14,
                      borderLeft: 0,
                    }}
                  >
                    <Typography
                      className={styles.linkText}
                      onClick={() => navigate(`/dashboard/invoices/${item?.storeId}`)}
                    >
                      {item?.storeName}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      fontSize: 14,
                      fontWeight: 400,
                      borderLeft: 0,
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    align="center"
                    onClick={() => navigate(`/dashboard/invoices/${item?.storeId}`)}
                  >
                    {item?.storeName}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      fontSize: 14,
                      fontWeight: 400,
                      color: theme.palette.primary.main,
                    }}
                    align="center"
                  >
                    <Box
                      sx={{
                        width: 11,
                        height: 11,
                        borderRadius: '50%',
                        margin: 'auto',
                        background: theme.palette[(item?.vertical).toLowerCase()].main,
                      }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      fontSize: 14,
                      fontWeight: 400,
                      borderLeft: 0,
                    }}
                    align="center"
                  >
                    {item?.invoiceDate}
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                    align="center"
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {item?.orderNumber}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                    align="center"
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '140%',
                      }}
                    >
                      {item?.orderDate}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      fontSize: 14,
                      borderLeft: 0,
                    }}
                    align="center"
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '140%',
                      }}
                    >
                      {item?.value}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      padding: '0px',
                      paddingY: '20px',
                      fontSize: 14,
                      fontWeight: 500,
                      borderRight: 0,
                    }}
                  >
                    {item?.isLive ? (
                      <>
                        {item?.creditNotes?.length > 0 ? (
                          <Button
                            className={styles.enabledButton}
                            onClick={()=>{
                              setCreditNotes(item?.creditNotes)
                              setCNInvoiceId(item._id)
                              setInvoiceNumber(item.invoiceNumber)
                              setOrderNumber(item.orderNumber)
                              setUserId(item?.userId)
                              handleCreditNotesModal();
                            }}
                          >
                            Credit Notes
                          </Button>
                        ) : (
                          <Button
                            className={styles.disabledButton}
                          >
                            Credit Notes
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {item?.isRaised ? (
                          <Button
                            className={styles.enabledButton}
                            onClick={() => {
                              setId(item?._id, item?.userId);
                              handleConfirmationModal();
                            }}
                          >
                            Settle
                          </Button>
                        ) : (
                          <Button
                            className={styles.disabledButton}
                          >
                            Settle
                          </Button>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default InProcessInvoicesTable;
