import 'simplebar/src/simplebar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <HelmetProvider>
    <Helmet>
      <title>ByzWiz Super Admin</title>
      <meta name="description" content="True Social Commerce" />

      {/* <meta itemprop="name" content="ByzWiz Super Admin" />
      <meta itemprop="description" content="True Social Commerce" />
      <meta itemprop="image" content="https://test.mc.byzwiz.com/metaImage.png" /> */}

      <meta property="og:url" content="https://test.mc.byzwiz.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="ByzWiz Super Admin" />
      <meta property="og:description" content="True Social Commerce" />
      <meta property="og:image" content="https://test.mc.byzwiz.com/metaImage.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="ByzWiz Super Admin" />
      <meta name="twitter:description" content="True Social Commerce" />
      <meta name="twitter:image" content="https://test.mc.byzwiz.com/metaImage.png" />
    </Helmet>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();

reportWebVitals();
