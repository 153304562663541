import UnderConstruction from "../UnderConstruction";

const Flags = () => {
  return (
    <>
      <UnderConstruction />

    </>
  );
};

export default Flags;
