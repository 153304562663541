import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-hot-toast';
import { getCategoryAndSubCategories } from '../../api/GetCategoryAndSubCategories';
import Page from '../../components/Page';
import useModal from '../../hooks/useModal';
import useLoader from '../../hooks/useLoader';
import { deleteSubCategory } from '../../api/DeleteSubCategory';
import { getTotalCategoriesCount } from '../../api/GetTotalCategoriesCount';
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import styles from '../../pageStyles/pageStyles.module.scss';
import AddSubCategoryModal from '../../components/AddSubCategoryModal/AddSubCategoryModal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import Loader from '../../components/Loader/Loader';
import AppPagination from '../../components/AppPagination/AppPagination';

const buttonType = ['Food', 'Product', 'Video'];

const SubCategories = () => {
  const theme = useTheme();

  const [categoryState, setCategoryState] = useState('Food');
  const [selectedId, setSelectedId] = useState();
  const [page, setPage] = useState(1);

  const { subCategoryModal, handleSubCategoryModal } = useModal();

  const { loader, showLoader, hideLoader } = useLoader();

  const handlePageChange = (event, value) => {
    setSelectedId('');
    setPage(() => value);
  };

  const onClickCatBtn = (value) => {
    setCategoryState(value);
    setSelectedId('');
    setPage(1);
  };

  const expand = (index) => {
    if (selectedId === index) {
      setSelectedId('');
    } else {
      setSelectedId(index);
    }
  };

  const { data, isLoading, refetch } = useQuery(
    ['getCategoryAndSubCategories', categoryState, page],
    () =>
      getCategoryAndSubCategories({
        type: categoryState,
        page,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );

  // Get All subCategories Count
  const { data: categoriesCount } = useQuery(
    ['getTotalCategoriesCount', categoryState],
    () => getTotalCategoriesCount({ type: categoryState }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );

  return (
    <Page title="Sub-Categories">
      {Boolean(!isLoading && !loader) && (
        <Box className={styles.mainContainer}>
          <Stack
            spacing={3}
            direction="row"
            alignItems="flex-start"
            sx={{ marginBottom: '30px' }}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="flex-start" gap="20px">
              {buttonType.map((item, index) => {
                return (
                  <Button
                    key={index}
                    className={styles.categoryState}
                    sx={{
                      background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                      color: categoryState === item ? 'white' : theme.palette.primary.main,
                      '&:hover': {
                        background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                        boxShadow: '0px 0.920487px 5px rgba(0, 0, 0, 0.15)',
                      },
                    }}
                    onClick={() => onClickCatBtn(item)}
                  >
                    {Boolean(categoryState !== item) && (
                      <Box sx={{ background: theme.palette[item.toLowerCase()].main }} className={styles.dot} />
                    )}
                    {item}
                  </Button>
                );
              })}
            </Stack>

            <Button
              sx={{
                width: 200,
                height: 40,
                background: '#FFF',
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '5px',
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
                fontSize: 18,
                lineHeight: '140%',
                fontWeight: 500,
              }}
              onClick={() => {
                handleSubCategoryModal();
              }}
            >
              Add Sub-Category
            </Button>
          </Stack>

          <Box className={styles.minHeight}>
            {data?.data &&
              data?.data.success &&
              (data?.data?.data || []).map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    className={styles.accordion}
                    expanded={selectedId === index}
                    sx={{
                      background: 'transparent',
                      '&.MuiAccordion-root::before': {
                        content: 'none',
                      },
                      '&.MuiAccordion-root': {
                        borderRadius: 0,
                        margin: '0px 0px 20px 0px',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      className={styles.accordionSummary}
                      expandIcon={<ExpandMore sx={{ color: theme.palette.primary.main, cursor: 'pointer' }} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{
                        '&.Mui-expanded': {
                          minHeight: 0,
                          outline: 'none',
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                          margin: '12px 0',
                          outline: 'none',
                        },
                      }}
                      onClick={() => expand(index)}
                    >
                      {item?.name}
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordiondetails}>
                      <TableContainer
                        sx={{
                          width: '1000px',
                          padding: '0px 15px 20px 15px',
                        }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell width="450px" className={styles.tableCell} align="left">
                                Sub-Category
                              </TableCell>

                              <TableCell width="150px" className={styles.tableCell} align="center">
                                HSN/SAC
                              </TableCell>
                              <TableCell width="260px" className={styles.tableCell} align="center">
                                GST
                              </TableCell>
                              <TableCell width="140px" className={styles.tableCell} align="center">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(item?.subCategories || []).map((itemId, indexId) => (
                              <TableRow key={indexId} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
                                <TableCell className={styles.tableDataCell}>{itemId?.name}</TableCell>

                                <TableCell className={styles.tableDataCell} align="center">
                                  {itemId?.hsnCode}
                                </TableCell>

                                <TableCell className={styles.tableDataCell} align="center">
                                  {itemId?.gst?.gstSlots?.length ? (
                                    (itemId?.gst?.gstSlots || []).map((ite, ind) => {
                                      return (
                                        <Stack
                                          key={ind}
                                          direction="row"
                                          gap="15px"
                                          justifyContent="center"
                                          marginTop="5px"
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: 14,
                                              lineHeight: '132%',
                                            }}
                                          >
                                            {ite?.priceRange?.maximum
                                              ? `${ite?.priceRange?.minimum} - ${ite?.priceRange?.maximum}`
                                              : `	≥ ${ite?.priceRange?.minimum}`}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: 14,
                                              lineHeight: '132%',
                                            }}
                                          >
                                            {ite?.value}%
                                          </Typography>
                                        </Stack>
                                      );
                                    })
                                  ) : (
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        lineHeight: '132%',
                                      }}
                                    >
                                      N/A
                                    </Typography>
                                  )}
                                  {}
                                </TableCell>
                                <TableCell className={styles.tableDataCell} align="center">
                                  <MenuButton
                                    catId={item._id}
                                    subCatId={itemId._id}
                                    showLoader={showLoader}
                                    hideLoader={hideLoader}
                                    refetch={refetch}
                                    handleSubCategoryModal={handleSubCategoryModal}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Box>
        </Box>
      )}

      {Boolean(categoriesCount?.data?.data?.count > 1) && (
        <AppPagination page={page} totalItem={categoriesCount?.data?.data?.count} handlePageChange={handlePageChange} />
      )}

      {Boolean(isLoading || loader) && <Loader isLoaderVisible />}

      <AddSubCategoryModal
        isOpen={subCategoryModal}
        handleSubCategoryModal={handleSubCategoryModal}
        refetchData={refetch}
      />
    </Page>
  );
};

const MenuButton = ({ catId, subCatId, refetch, handleSubCategoryModal, showLoader, hideLoader }) => {
  const navigate = useNavigate();
  const { confirmationModal, handleConfirmationModal } = useModal();
  const [params] = useSearchParams();

  const onClickEdit = () => {
    if (params?.get('env')) {
      navigate({
        pathname: '/dashboard/subcategories',
        search: `?env=Prod&subCatId=${subCatId}&catId=${catId}`,
      });
    } else {
      navigate({
        pathname: '/dashboard/subcategories',
        search: `?subCatId=${subCatId}&catId=${catId}`,
      });
    }
    handleSubCategoryModal();
  };
  const subCategoryDeleteMutation = useMutation(
    'deleteSubCategory',
    ({ catId, subCatId }) => deleteSubCategory({ catId, subCatId }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
          refetch();
          hideLoader();
        }
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );
  return (
    <Box>
      <IconButton onClick={onClickEdit}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => handleConfirmationModal()}>
        <DeleteIcon />
      </IconButton>
      {confirmationModal && (
        <ConfirmationModal
          isOpen={confirmationModal}
          handleConfirmationModal={handleConfirmationModal}
          modalText={'Are you sure you wish to delete?'}
          onConfirm={() => {
            subCategoryDeleteMutation.mutateAsync({ catId, subCatId });
            showLoader();
          }}
        />
      )}
    </Box>
  );
};

export default SubCategories;
