import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import styles from './Payments.module.scss';

const PayInTable = ({ payIn }) => {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#F5F4F4' }}>
            <TableCell align="left" width="121px" className={styles.tableCell}>
              Order No
            </TableCell>
            <TableCell width="169px" className={styles.tableCell}>
              Order Date
            </TableCell>
            <TableCell width="160px" className={styles.tableCell}>
              Invoice Number
            </TableCell>
            <TableCell width="230px" className={styles.tableCell}>
              Home Business
            </TableCell>
            <TableCell width="200px" className={styles.tableCell}>
              Customer
            </TableCell>
            <TableCell width="150px" className={styles.tableCell}>
              Amount ₹
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(payIn || []).map((item, index) => {
            return (
              <TableRow key={index} className={styles.tableRow}>
                <TableCell className={styles.tableDataCell}>{item?.orderNumber}</TableCell>
                <TableCell className={styles.tableDataCell}>
                  {moment(item?.createdAt).format('DD-MM-YYYY - hh:mm A')}
                </TableCell>
                <TableCell className={styles.tableDataCell} sx={{ color: `{theme.palette.primary.main}` }}>
                  <Typography className={styles.linkText}>{item?.invoiceNumber}</Typography>
                </TableCell>

                <TableCell className={styles.tableDataCell}>
                  <Typography className={styles.linkText}>{item?.paymentTo}</Typography>
                </TableCell>

                <TableCell className={styles.tableDataCell}>
                  <Typography className={styles.linkText}>{item?.paymentBy}</Typography>
                </TableCell>

                <TableCell className={styles.tableDataCell}>{item?.amount?.toFixed(2)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayInTable;
