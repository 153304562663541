import moment from 'moment';
import { Avatar, Box, Button, Modal, Slide, Typography, useTheme, IconButton, Stack, Divider } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import getS3Bucket from '../../utils/getS3Bucket';
import styles from './UserInfoModal.module.scss';

const UserInfoModal = ({ userInfo, role, isOpen, handleModalState, setDocUrl, handleFullScreenMediaModal }) => {
  const theme = useTheme();

  return (
    <>
      <Modal
        open={isOpen}
        closeAfterTransition
        className={styles.mainContainer}
        sx={{
          '.MuiBackdrop-root': {
            background: 'none',
          },
        }}
      >
        <Slide direction="up" in={isOpen}>
          <Box className={styles.modalBody}>
            <Box
              className={styles.mainContent}
              sx={{
                background: role === 'Seller' ? '#F4F3FF' : '',
              }}
            >
              {
                <IconButton className={styles.closeBtn} onClick={handleModalState}>
                  <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
                </IconButton>
              }
              <Box className={styles.contentContainer}>
                <Box className={styles.dFlex}>
                  <Typography className={styles.title}>Name</Typography>
                  <Typography className={styles.value} sx={{ fontWeight: '600 !important' }}>
                    {userInfo?.name}
                  </Typography>
                </Box>

                {userInfo?.isStoreAdmin && userInfo?.parent?.isParentAccountable && (
                  <Box className={styles.dFlex}>
                    <Typography className={styles.title}>Parent</Typography>
                    <Typography className={styles.value}>{userInfo?.parent?.info?.name}</Typography>
                  </Box>
                )}

                {userInfo?.email && (
                  <Box className={styles.dFlex}>
                    <Typography className={styles.title}>Email</Typography>
                    <Typography className={styles.value}>{userInfo?.email}</Typography>
                  </Box>
                )}

                {userInfo?.isStoreAdmin && (
                  <Box className={styles.dFlex}>
                    <Typography className={styles.title}>DOB</Typography>
                    <Typography className={styles.value}>{userInfo?.dob}</Typography>
                  </Box>
                )}

                {userInfo?.mobile && (
                  <Box className={styles.dFlex}>
                    <Typography className={styles.title}>Mob No</Typography>
                    <Typography className={styles.value}>{userInfo?.mobile}</Typography>
                  </Box>
                )}

                {userInfo?.registered && <Box className={styles.dFlex}>
                  <Typography className={styles.title}>Registered</Typography>
                  <Typography className={styles.value}>{moment(userInfo?.registered).format('DD-MM-YYYY')}</Typography>
                </Box>}

                {userInfo?.byzcode && (
                  <Box className={styles.dFlex}>
                    <Typography className={styles.title}>ByzCode</Typography>
                    <Typography
                      className={styles.value}
                      sx={{ color: theme.palette.primary.main, fontWeight: '500 !important' }}
                    >
                      {userInfo?.byzcode}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Avatar
                  src={getS3Bucket() && userInfo?.profilePic ? encodeURI(getS3Bucket() + userInfo?.profilePic) : ''}
                  sx={{
                    width: 178,
                    height: 178,
                    borderRadius: '8px',
                  }}
                />

                <Button
                  className={styles.btn}
                  sx={{
                    background: theme.palette.primary.main,
                    '&:hover': {
                      background: theme.palette.primary.main,
                    },
                  }}
                >
                  Track
                </Button>
              </Box>
            </Box>

            {userInfo?.businesses?.length && (
              <Box className={styles.businessContent}>
                <Stack direction="row" gap="20px" alignItems="center">
                  <Typography className={styles.businessText}>Home Businesses</Typography>
                  <Box className={styles.businessCount}>
                    <Typography className={styles.businessText}>{userInfo?.businesses?.length}</Typography>
                  </Box>
                </Stack>
                <Box className={styles.dGrid}>
                  {(userInfo?.businesses || []).map((item, index) => {
                    return (
                      <Stack
                        key={index}
                        direction="row"
                        gap="20px"
                        borderRight={index % 2 === 0 && userInfo?.businesses?.length > 1 ? '1px solid #CACACA' : 'none'}
                        paddingTop={index > 1 ? '20px' : '0'}
                      >
                        <Box
                          className={styles.storeType}
                          sx={{ backgroundColor: theme.palette[(item?.type).toLowerCase()].main }}
                        />
                        <Box>
                          <Typography className={styles.storeName}>{item?.name}</Typography>
                          <Typography className={styles.publishedDate}>{item?.publishedDate}</Typography>
                        </Box>
                      </Stack>
                    );
                  })}
                </Box>
              </Box>
            )}

            {(userInfo?.panNumber || userInfo?.aadhar || userInfo?.voter) && (
              <>
                <Divider
                  flexItem
                  sx={{
                    borderBottom: '1px solid #CACACA',
                    marginBottom: '20px',
                    marginX: '20px',
                  }}
                />

                <Box
                  className={styles.businessContent}
                  sx={{
                    paddingTop: '0 !important',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack direction="column" gap="10px">
                    <Typography className={styles.businessText}>KYC</Typography>
                    {userInfo?.panNumber && (
                      <Stack direction="row" gap="40px">
                        <Typography className={styles.panText}>PAN No</Typography>
                        <Typography className={styles.panNo}>{userInfo?.panNumber}</Typography>
                      </Stack>
                    )}
                    {userInfo?.aadhar && (
                      <Stack direction="row" gap="40px">
                        <Typography className={styles.panText}>Aadhar No</Typography>
                        <Typography className={styles.panNo}>{userInfo?.aadhar}</Typography>
                      </Stack>
                    )}
                    {userInfo?.voter && (
                      <Stack direction="row" gap="40px">
                        <Typography className={styles.panText}>Voter ID</Typography>
                        <Typography className={styles.panNo}>{userInfo?.voter}</Typography>
                      </Stack>
                    )}
                  </Stack>
                  {userInfo?.doc && <Typography
                    className={styles.value}
                    sx={{ color: theme.palette.primary.main, fontWeight: '500 !important', cursor: 'pointer' }}
                    onClick={() => {
                      setDocUrl(userInfo?.doc);
                      handleFullScreenMediaModal();
                    }}
                  >
                    View Image
                  </Typography>}
                </Box>
              </>
            )}

            {role === 'Seller' && (
              <>
                <Divider
                  flexItem
                  sx={{
                    borderBottom: '1px solid #CACACA',
                    marginBottom: '20px',
                    marginX: '20px',
                  }}
                />

                <Box className={styles.businessContent} sx={{ paddingTop: '0 !important' }}>
                  <Typography className={styles.businessText} sx={{ paddingBottom: '15px' }}>
                    FSSAI Details
                  </Typography>
                  <Stack direction="column" gap="10px">
                    <Stack direction="row" gap="40px">
                      <Typography className={styles.panText}>Verified</Typography>
                      <Typography className={styles.panNo}>{userInfo?.fssaiNumber ? 'Yes' : 'No'}</Typography>
                    </Stack>
                    {userInfo?.fssaiNumber && (
                      <Stack direction="row" gap="30px">
                        <Typography className={styles.panText}>FSSAI No</Typography>
                        <Typography className={styles.panNo}>{userInfo?.fssaiNumber}</Typography>
                      </Stack>
                    )}
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default UserInfoModal;
