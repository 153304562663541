import client from "./Client";

const endPoint = '/su/store/headers/'

const getInventories = ({
    _id,
    type,
    videoType,
}) => {
    const token = localStorage.getItem('token')

    const value = {};
    value.type = type;

    if (type === "Video") {
        value.videoType = videoType;
    }

    return client.get(
        endPoint + _id,
        value,
        {
            headers: {
                Authorization: token
            }
        }
    )
}

export { getInventories }
