import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import useModal from '../../../hooks/useModal';
import useLoader from '../../../hooks/useLoader';
import Page from '../../../components/Page';
import Loader from '../../../components/Loader/Loader';
import ViewItemModal from '../../../components/ViewItemModal/ViewItemModal';
import { ReactComponent as LeftArrow } from '../../../assets/icons/leftArrow.svg';
import { ReactComponent as DownArrow } from '../../../assets/icons/downArrow.svg';
import styles from '../../../pageStyles/pageStyles.module.scss';
import InventoryTable from '../../../components/Custom/InventoryTable/InventoryTable';
import { ViewProductItemModal } from '../../../components/ViewItemModal/ViewProductItemModal';
import ViewVideoLiveGroupModal from '../../../components/ViewLiveGroupModal/ViewLiveGroupModal';
import ViewRecordedVideoModal from '../../../components/ViewRecordedVideoModal/ViewRecordedVideoModal';
import { getHeadersItem } from '../../../api/GetHeadersItem';
import { getInventories } from '../../../api/GetInventory';
import { getItem } from '../../../api/GetItem';

const videoTypes = ['Live One-On-One', 'Live Groups', 'Pre-Recorded Content'];

const MultipleItems = {
  Food: 'Food Items',
  Product: 'Products',
  LiveVideo: 'Listings',
  PreRecorded: 'Videos',
};

const Items = {
  Food: 'Food Item',
  Product: 'Product',
  LiveVideo: 'Listing',
  PreRecorded: 'Video',
};

const StoreInverntory = () => {
  const [itemId, setItemId] = useState();
  const [headerId, setHeadeId] = useState();
  const [videoType, setVideoType] = useState('One on One');
  const [activeTab, setActiveTab] = useState(videoTypes[0]);
  const [getItemToggle, setGetItemToggle] = useState(false);
  const theme = useTheme();
  const { id } = useParams();
  const [type] = useSearchParams();
  const navigate = useNavigate();
  const { loader, hideLoader, showLoader } = useLoader();
  const {
    viewItemModal,
    handleViewItemModal,
    viewProductModal,
    handleViewProductModal,
    viewVideoModal,
    handleViewVideoModal,
    viewRecordedModal,
    handleViewRecordedModal,
  } = useModal();

  const setId = (value) => {
    setItemId(value);
  };

  const setSelectedHeaderId = (id) => {
    if (headerId === id) {
      setHeadeId('');
    } else {
      setHeadeId(id);
    }
  };

  const setVideoSessionType = (value) => {
    setActiveTab(value);
    if (value === 'Live One-On-One') {
      setVideoType('One on One');
    } else if (value === 'Live Groups') {
      setVideoType('Group');
    } else if (value === 'Pre-Recorded Content') {
      setVideoType('Recorded Video');
    }
  };

  const getItemsListing = (item, type, videoType) => {
    if (item > 1) {
      if (type === 'Video') {
        return MultipleItems[videoType === 'Recorded Video' ? 'PreRecorded' : 'LiveVideo'];
      }
      return MultipleItems[type];
    }

    if (item <= 1) {
      if (type === 'Video') {
        return Items[videoType === 'Recorded Video' ? 'PreRecorded' : 'LiveVideo'];
      }
      return Items[type];
    }
  };

  const openModal = () => {
    if (type.get('type') === 'Food') {
      handleViewItemModal();
    } else if (type.get('type') === 'Product') {
      handleViewProductModal();
    } else if (type.get('type') === 'Video') {
      if (videoType === 'Recorded Video') {
        handleViewRecordedModal();
      } else {
        handleViewVideoModal();
      }
    }
  };

  const changeNavigation = () => {
    if (type?.get('env')) {
      navigate({
        pathname: '/dashboard/stores',
        search: '?env=Prod',
      });
    } else {
      navigate('/dashboard/stores');
    }
  };

  const { data, isLoading } = useQuery(
    ['getInventories', id, type.get('type'), videoType],
    () => getInventories({ _id: id, type: type.get('type'), videoType }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );
  const inventories = data?.data?.data;

  const { data: items, isLoading: loadingItems } = useQuery(
    ['getHeadersItem', headerId, type.get('type')],
    () => getHeadersItem({ id: headerId, type: type.get('type'), videoType }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(headerId),
    }
  );
  const headersItems = items?.data?.data;

  const { data: itemData, isLoading: loadingItemInfo } = useQuery(
    ['getItem', itemId, getItemToggle],
    () => getItem({ id: itemId, type: type.get('type'), videoType, storeId: id }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(itemId),
    }
  );
  const itemInfo = itemData?.data?.data?.[0];

  useEffect(() => {
    if (viewItemModal) setGetItemToggle(!getItemToggle);
  }, [viewItemModal]);
  useEffect(() => {
    if (viewProductModal) setGetItemToggle(!getItemToggle);
  }, [viewProductModal]);
  useEffect(() => {
    if (viewVideoModal) setGetItemToggle(!getItemToggle);
  }, [viewVideoModal]);
  useEffect(() => {
    if (viewRecordedModal) setGetItemToggle(!getItemToggle);
  }, [viewRecordedModal]);

  useEffect(() => {
    if (isLoading || loadingItems || loadingItemInfo) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, data, loadingItems, items, loadingItemInfo, itemData]);

  console.log(type);

  return (
    <Page title="Inventory">
      {!loader && (
        <Container className={styles.container}>
          <Box className={styles.dFlex} sx={{ marginBottom: '30px' }}>
            <IconButton className={styles.icon} onClick={() => changeNavigation()}>
              <LeftArrow />
            </IconButton>
            <Typography className={styles.linkText} onClick={() => changeNavigation()}>
              Back
            </Typography>
          </Box>

          {type.get('type') === 'Video' && (
            <Box className={styles.tabMainContainer}>
              {videoTypes.map((item, index) => {
                return (
                  <Box className={styles.tab} key={index}>
                    <Typography
                      className={styles.tabText}
                      onClick={() => setVideoSessionType(item)}
                      sx={{ color: activeTab === item ? theme.palette.video.main : 'rgba(36, 36, 36, 0.8)' }}
                    >
                      {item}
                    </Typography>
                    {activeTab === item && (
                      <Box
                        className={styles.tabIndicator}
                        sx={{
                          backgroundColor: theme.palette.video.main,
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          )}

          {data?.data?.success && (
            <Box className={styles.inventoriesContainer}>
              {(inventories || []).map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    expanded={headerId === item?._id}
                    className={styles.accordion}
                    sx={{
                      '&.MuiAccordion-root': {
                        margin: 0,
                      },
                      '&.Mui-expanded': {
                        minHeight: 0,
                        outline: 'none',
                        paddingY: 0,
                      },
                      '& .MuiAccordionSummary-content.Mui-expanded': {
                        margin: '12px 0',
                        outline: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className={styles.accordionSummary}
                      onClick={() => setSelectedHeaderId(item?._id)}
                    >
                      <Box className={styles.columnFlex}>
                        <Box className={styles.dFlex} sx={{ justifyContent: 'space-between', flex: 1 }}>
                          <Box>
                            <Box className={styles.dFlex} sx={{ gap: '20px !important' }}>
                              <Typography className={styles.name}>{item?.name}</Typography>
                              <Box className={styles.dFlex}>
                                <Typography className={styles.listing}>
                                  {`${item?.itemsCount} ${getItemsListing(
                                    item?.itemsCount,
                                    type.get('type'),
                                    videoType
                                  )}`}
                                </Typography>
                                <IconButton
                                  disableRipple
                                  className={`
                                  ${headerId === item?._id ? styles.downArrowIcon : styles.arrowIcon}`}
                                >
                                  <DownArrow />
                                </IconButton>
                              </Box>
                            </Box>
                            {item?.description && (
                              <Typography className={styles.headerDescription}>{item?.description}</Typography>
                            )}
                          </Box>
                          <Box
                            sx={{
                              border: `1px solid ${theme.palette[type.get('type').toLocaleLowerCase()].main}`,
                              color: theme.palette[type.get('type').toLocaleLowerCase()].main,
                              background: theme.palette[type.get('type').toLocaleLowerCase()].light,
                            }}
                            className={styles.categoryBtn}
                          >
                            {item?.categoryName}
                          </Box>
                        </Box>
                        {videoType === 'Recorded Video' && (
                          <Box className={styles.dFlex} sx={{ justifyContent: 'space-between', flex: 1 }}>
                            <Typography className={styles.planType}>
                              {`₹ 
                                                                                ${item?.plan?.price} 
                                                                                -
                                                                                 ${item?.plan?.duration} 
                                                                                 ${
                                                                                   item?.plan?.duration === 1
                                                                                     ? 'month'
                                                                                     : 'months'
                                                                                 }
                                                                                 `}
                            </Typography>
                            <Typography className={styles.isVisible}>
                              Visible -{' '}
                              <Typography component={'span'} className={styles.visibility}>
                                {item?.isVisible ? 'Yes' : 'No'}
                              </Typography>
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Boolean(items?.data?.success) && (
                        <InventoryTable
                          inventoryData={headersItems}
                          setId={setId}
                          openModal={openModal}
                          type={type.get('type')}
                          videoType={videoType}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </Container>
      )}

      {loader && <Loader isLoaderVisible={loader} />}

      {Boolean(viewItemModal && !loadingItemInfo) && (
        <ViewItemModal
          isOpen={viewItemModal}
          handleViewItemModal={handleViewItemModal}
          FoodItemDetail={itemInfo}
          isSuccess={itemData?.data?.success}
        />
      )}

      {Boolean(viewProductModal && !loadingItemInfo) && (
        <ViewProductItemModal
          isOpen={viewProductModal}
          handleViewProductModal={handleViewProductModal}
          ProductDetail={itemInfo}
          isSuccess={itemData?.data?.success}
        />
      )}

      {Boolean(viewVideoModal && !loadingItemInfo) && (
        <ViewVideoLiveGroupModal
          isOpen={viewVideoModal}
          handleViewVideoModal={handleViewVideoModal}
          LiveVideoData={itemInfo}
          isSuccess={itemData?.data?.success}
        />
      )}

      {Boolean(viewRecordedModal && !loadingItemInfo) && (
        <ViewRecordedVideoModal
          isOpen={viewRecordedModal}
          handleViewRecordedModal={handleViewRecordedModal}
          recordedVideo={itemInfo}
          isSuccess={itemData?.data?.success}
        />
      )}
    </Page>
  );
};

export { StoreInverntory };
