import client from "./Client";

const endPoint = '/su/faq/question/';

const deleteQuestion = ({ headerId, qId }) => {
    const token = localStorage.getItem("token");
    return client.delete(
        `${endPoint + headerId}/${qId}`,
        {},
        {
            headers: {
                Authorization: token
            }
        })
}

export { deleteQuestion }