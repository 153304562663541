import client from './Client';

const endPoint = '/su/user/all';

const getAllUsers = ({ page, limit }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      page,
      limit,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getAllUsers };
