import * as Yup from 'yup';

const gstRegEx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

const addStateGstValidationSchema = Yup.object().shape({
  gstNo: Yup.string().required('Required').matches(gstRegEx, 'Invalid'),
  state: Yup.string().required('Required'),
  fssaiNumber: Yup.number('Invalid')
    .required('Required')
    .test({
      name: 'fssaiNumberLength',
      message: 'Invalid format',
      test: (value) => {
        if (String(value).length === 14) {
          return true;
        }
        return false;
      },
    }),
  address: Yup.string().required('Required'),
});

export default addStateGstValidationSchema;
