import client from './Client';

const endPoint = '/su/order/total';

const getTotalOrdersCount = ({ type }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      type,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getTotalOrdersCount };
