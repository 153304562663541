// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_mainLayout__ERY3A {\n  min-height: 100vh;\n  background: white;\n  position: fixed;\n  width: 100%;\n  top: 0;\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  padding-left: 300px;\n}\n\n.Loader_loadericoncontainer__J1L\\+X {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,MAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACF","sourcesContent":[".mainLayout {\n  min-height: 100vh;\n  background: white;\n  position: fixed;\n  width: 100%;\n  top: 0;\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  padding-left: 300px;\n}\n\n.loadericoncontainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainLayout": "Loader_mainLayout__ERY3A",
	"loadericoncontainer": "Loader_loadericoncontainer__J1L+X"
};
export default ___CSS_LOADER_EXPORT___;
