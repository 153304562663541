import client from "./Client";

const deleteSubCategory = ({ catId, subCatId }) => {
    const endPoint = `/su/category/${catId}/sub-category/${subCatId}`;

    const token = localStorage.getItem("token");

    return client.delete(endPoint, {}, {
        headers: {
            Authorization: token
        }
    })
}

export { deleteSubCategory }