import client from "./Client";

const endPoint = '/su/transaction/';


const settleTransaction = ({ id, userId }) => {

    const token = localStorage.getItem("token");
    return client.post(
        endPoint + id,
        {
            userId
        },
        {
            headers: {
                Authorization: token
            }
        }
    )
}

export { settleTransaction }