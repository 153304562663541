import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const FoodOrderCard = ({ listOfItems }) => {
    return (
        <>
            <TableContainer sx={{
                border: 0
            }}>
                <Table sx={{ border: 0 }}>

                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    width: 298,
                                    border: 0,
                                    paddingY: "10px",
                                    paddingX: "0px",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: "#3E3E3E",

                                }}>Item Name</TableCell>
                            <TableCell
                                sx={{
                                    width: 95,
                                    border: 0,
                                    paddingX: "0px",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: "#3E3E3E",
                                }}>Qty</TableCell>
                            <TableCell
                                sx={{
                                    border: 0,
                                    paddingX: "0px",
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: "18px",
                                    color: "#3E3E3E",
                                }}
                                align="right">Amount ₹</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            (listOfItems || []).map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell
                                            sx={{
                                                border: 0,
                                                paddingY: "2px",
                                                paddingX: "0px",
                                                fontWeight: 400,
                                                fontSize: 14,
                                                lineHeight: "18px",
                                                color: " #5E5E5E",
                                            }}>{item?.itemName}</TableCell>
                                        <TableCell
                                            sx={{
                                                border: 0,
                                                paddingY: "2px",
                                                paddingX: "0px",
                                                fontWeight: 400,
                                                fontSize: 14,
                                                lineHeight: "18px",
                                                color: " #5E5E5E",
                                                paddingLeft: "10px"
                                            }}>{item?.quantity}</TableCell>
                                        <TableCell
                                            sx={{
                                                border: 0,
                                                paddingY: "2px",
                                                paddingX: "0px",
                                                fontWeight: 400,
                                                fontSize: 14,
                                                lineHeight: "18px",
                                                color: " #5E5E5E",
                                            }} align="right">{item?.amount}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>

                </Table>
            </TableContainer >
        </>
    )
}

export default FoodOrderCard;