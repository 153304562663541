import UnderConstruction from '../UnderConstruction';

const Financial = () => {
  return (
    <>
    <UnderConstruction />

    </>
  );
};

export default Financial;
