import * as Yup from "yup";
import { useMutation } from "react-query";
import { Button, Stack, TextField, useTheme } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { toast } from "react-hot-toast";
import styles from "./HeadingForm.module.scss"
import { AddHeading } from "../../../api/AddHeading";
import { UpdateHeading } from "../../../api/UpdateHeading";

const HeadingForm = ({ header, headerId, setShowForm, refetch }) => {
    const theme = useTheme();

    const headerValidation = Yup.object({
        heading: Yup.string().required("Question header is required")
    })

    const initialValues = {
        heading: headerId ? header : ""
    }

    const formik = useFormik({
        initialValues,
        validationSchema: headerValidation,
        onSubmit: (values, { resetForm }) => {
            if (headerId) {
                updateHeadingMutation.mutateAsync({ heading: values.heading, id: headerId })
            } else {
                addHeadingMutation.mutateAsync({ heading: values.heading })
            }
        }
    })

    const { handleSubmit, errors, touched, resetForm, getFieldProps, values, setFieldValue } = formik;

    const addHeadingMutation = useMutation(
        "addHeading",
        ({ heading }) => AddHeading({ heading }),
        {
            onSuccess: ({ data }) => {
                if (data) {
                    if (!data?.success) {
                        toast.error(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    } else if (data?.success) {
                        toast.success(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    }
                    resetForm();
                    refetch();
                    setShowForm();
                }
            },
            onError: (error) => {
                console.log('error', error)
                resetForm();
            },
        }
    )

    const updateHeadingMutation = useMutation(
        "updateHeading",
        ({ heading, id }) => UpdateHeading({ heading, id }),
        {
            onSuccess: ({ data }) => {
                if (data) {
                    if (!data?.success) {
                        toast.error(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    } else if (data?.success) {
                        toast.success(data?.message, {
                            position: 'bottom-center',
                            duration: 2000,
                        });
                    }
                    resetForm();
                    refetch();
                    setShowForm();
                }
            },
            onError: (error) => {
                console.log('error', error)
                resetForm();
            },
        }
    )


    return (
        <FormikProvider value={formik}>
            <TextField
                fullWidth
                autoComplete="heading"
                type="heading"
                focused={Boolean(values.heading)}
                autoFocus={Boolean(values.heading)}
                {...getFieldProps('heading')}
                error={Boolean(touched.heading && errors.heading)}
                helperText={touched.heading && errors.heading}
                sx={{
                    borderRadius: 0,
                    input: {
                        padding: '11px',
                        borderRadius: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                        borderRadius: '5px',
                    },
                    ".css-16d5wub-MuiFormHelperText-root.Mui-error": {
                        marginLeft: "0 !important"
                    }
                }}
            />
            <Stack direction="row" justifyContent="end" gap="10px" marginTop={errors?.heading ? "0px" : "15px"}>
                <Button
                    className={styles.btn}
                    onClick={() => {
                        resetForm();
                        setShowForm();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className={styles.btn}
                    sx={{
                        color: "#FFF",
                        background: theme.palette.primary.main,
                        "&:hover": {
                            color: "#FFF",
                            background: theme.palette.primary.main,
                        }
                    }}
                    onClick={handleSubmit}
                >
                    {headerId ? "Update" : "Save"}
                </Button>
            </Stack>
        </FormikProvider>
    )
}

export default HeadingForm;