import { create } from 'apisauce';

const client = create({
  // baseURL: 'http://localhost:5000',
  baseURL: window?.location?.search?.includes('env=Prod')
    ? process.env.REACT_APP_PROD_AUTH_SERVER
    : process.env.REACT_APP_AUTH_SERVER,
});

export default client;
