import { Avatar, Box, CardMedia, IconButton, Modal, Slide, Typography, useTheme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import getS3Bucket from "../../utils/getS3Bucket";
import styles from './ViewRecordedVideo.module.scss';

const ViewRecordedVideoModal = ({ isOpen, handleViewRecordedModal, recordedVideo, isSuccess }) => {
  const theme = useTheme();
  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      disableAutoFocus
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
      className={styles.mainContainer}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <IconButton className={styles.closeBtn} onClick={() => handleViewRecordedModal()}>
            <ClearOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
                fontSize: 30,
              }}
            />
          </IconButton>

          <Typography className={styles.title}> View Pre Recorded Content </Typography>

          {isSuccess && (
            <Box className={styles.videoMainContainer}>
              <Box className={styles.contentSection}>
                <Typography className={styles.contentTitle}>Album Name</Typography>
                <Typography className={styles.contentDesc}>{recordedVideo?.headerName}</Typography>
              </Box>

              <Box className={styles.contentSection}>
                <Typography className={styles.contentTitle}>Video Title</Typography>
                <Typography className={styles.contentDesc}>{recordedVideo?.title}</Typography>
              </Box>

              <Box className={styles.contentSection}>
                <Typography className={styles.contentTitle}>Uploaded Video</Typography>

                <CardMedia
                  component="video"
                  height={215}
                  image={
                    getS3Bucket() && recordedVideo?.video
                      ? getS3Bucket() + recordedVideo?.video
                      : ''
                  }
                  className={styles.video}
                  controls
                />
                <Typography className={styles.contentTitle} sx={{ marginTop: '20px' }}>
                  Access
                </Typography>
                <Typography className={styles.contentDesc}>
                  <Typography component="span" className={styles.planType} sx={{ color: theme.palette.primary.main }}>
                    {recordedVideo?.access}
                  </Typography>
                </Typography>
              </Box>
              <Box className={styles.contentSection}>
                <Typography className={styles.contentTitle}>Video Thumbnail</Typography>
                <Avatar
                  src={
                    getS3Bucket() && recordedVideo?.thumbnail
                      ? getS3Bucket() + recordedVideo?.thumbnail
                      : ''
                  }
                  alt={recordedVideo?.thumbnail}
                  className={`${styles.videoThumbnail} ${styles.animatedBackground}`}
                />
              </Box>
              <Box className={styles.contentSection}>
                <Typography className={styles.contentTitle}>Listing description</Typography>
                <Typography className={styles.contentDesc}>{recordedVideo?.description}</Typography>
              </Box>
              {recordedVideo?.searchKeywords?.[0] && (
                <Box className={{ ...styles.contentSection, border: 'none' }}>
                  <Typography className={styles.contentTitle}>Keywords</Typography>
                  <Box className={styles.keywordsContainer}>
                    {recordedVideo?.searchKeywords?.[0]?.split(',')?.map((item, index) => {
                      return (
                        <Typography className={styles.keywords} key={index}>
                          {item}
                        </Typography>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

export default ViewRecordedVideoModal;
