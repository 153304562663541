import client from './Client';

const CreateNewTemplate = ({ templateName, type }) => {
  const endPoint = '/su/whatsapp/template';

  const token = localStorage.getItem('token');

  const value = {
    templateName,
    type,
  };

  return client.post(endPoint, value, {
    headers: {
      Authorization: token,
    },
  });
};

export { CreateNewTemplate };
