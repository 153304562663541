import { useEffect, useState } from 'react';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
import { fNumber } from '../../../utils/formatNumber';
import { BaseOptionChart } from '../../../components/chart';

const CHART_HEIGHT = 170;
const LEGEND_HEIGHT = 44;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  width: 230,
  height: CHART_HEIGHT,
  marginTop: '10px',
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    width: '230px',
    alignContent: 'start',
    justifyContent: 'space-between',
    position: 'relative !important',
    top: `210px !important`,
    '& .apexcharts-legend-series': {
      margin: '2px 5px !important',
    },
  },
}));

export default function AppCurrentVisits({ chartColors, chartData, title }) {
  const theme = useTheme();
  const [showGraph, setShowGraph] = useState(true);

  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  useEffect(() => {
    if (chartSeries[0] === 0 && chartSeries[1] === 0 && chartSeries[2] === 0) {
      setShowGraph(false);
    }
  }, [chartSeries]);

  return (
    <Box
      sx={{
        width: '230px',
        height: '300px',
      }}
    >
      <Typography
        sx={{
          color: '#5C5B69',
          fontSize: '17px',
          fontWeight: '500',
          textTransform: 'capitalize',
          marginBottom: '5px',
        }}
      >
        {title}
      </Typography>
      <Card
        sx={{
          height: '268px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '10px',
          boxShadow: 'none',
          border: '1px solid #D2D0E9',
          background: '#FFF',
        }}
      >
        {showGraph ? (
          <ChartWrapperStyle dir="ltr">
            <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={200} />
          </ChartWrapperStyle>
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                lineHeight: '140%',
                color: '#5F5F5F',
                textTransform: 'capitalize',
              }}
            >
              No Data available.
            </Typography>
          </Box>
        )}
      </Card>
    </Box>
  );
}
