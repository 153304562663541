import { useRoutes } from 'react-router-dom';
import RouteConfig from './RouterConfig';

function Router() {
  const token = localStorage.getItem('token');
  const routes = token ? RouteConfig.filter((x) => x?.isAuth) : RouteConfig.filter((x) => !x?.isPrivate);

  return useRoutes(routes);
}

export default Router;
