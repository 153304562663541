import { Box, CardMedia, Stack, Typography } from '@mui/material';
import getS3Bucket from "../../../utils/getS3Bucket";
import styles from './CarouselCard.module.scss';



const CarouselCard = ({ carouselData }) => {
  return (
    <Box className={styles.mainBox}>
      <Box className={styles.carouselCard}>
        <Stack direction="row">
          <Box>
            <Typography className={styles.title}>Dish of the day</Typography>
            <Typography className={styles.item}>Strawberry Cheesecake</Typography>
          </Box>
          <CardMedia
            component={'img'}
            image={
              getS3Bucket() && carouselData?.image
                ?
                getS3Bucket() + carouselData?.image
                :
                ""
            }
            sx={{
              width: 82,
              height: 78,
              borderRadius: "50%",
              zIndex: 111,
            }}
          />
        </Stack>
        <Box className={styles.bg} />
      </Box>
      <Typography className={styles.urlText}>{carouselData?.url}</Typography>
    </Box>
  );
};

export default CarouselCard;
