import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, Divider, Typography } from '@mui/material';
import ToggleSwitch from './ToggleSwitch/ToggleSwitch';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
}));

const ListItemIconStyle = styled(ListItemIcon)({});

const NavItem = ({ item, active }) => {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, activeIcon, divider } = item;

  const activeRootStyle = {
    color: `${theme.palette.primary.main}`,
  };

  const listStyle = {
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  };
  const location = window.location.search;

  return (
    <>
      <ListItemStyle
        component={RouterLink}
        to={location ? `${path}?env=Prod` : path}
        sx={{ ...(isActiveRoot && activeRootStyle), ...listStyle }}
      >
        <ListItemIconStyle sx={{ minWidth: 'fit-content' }}>{isActiveRoot ? activeIcon : icon}</ListItemIconStyle>
        <ListItemText primary={title} />
      </ListItemStyle>
      {divider && <Divider flexItem sx={{ borderBottom: '1px solid #E6E6E6' }} />}
    </>
  );
};

export default function NavSection({ navConfig }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box>
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginX: '30px' }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
