import client from './Client';

const endPoint = '/su/whatsapp/send-message-with-file';

const sendWhatsappCsvFileMessage = ({ csvFile, template, type, mediaId }) => {
  const token = localStorage.getItem('token');
  const value = new FormData();
  value.append('csvFile', csvFile);
  value.append('template', template);
  value.append('type', type);
  if (mediaId) {
    value.append('mediaId', mediaId);
  }
  return client.post(
    endPoint,value,
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { sendWhatsappCsvFileMessage };
