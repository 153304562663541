import client from './Client';

const endPoint = '/su/whatsapp/media';

const getMediaData = (templateType) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    { type: templateType },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getMediaData };
