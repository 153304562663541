import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { toast } from 'react-hot-toast';
import styles from './MarginModal.module.scss';
import { getMarginById } from '../../api/GetMarginById';
import { createMargin } from '../../api/CreateMargin';
import { updateMargin } from '../../api/UpdateMargin';
import { ReactComponent as DownArrow } from '../../assets/icons/downArrow.svg';

const AddMarginModal = ({ isOpen, handleMarginModal, refetchData }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [marginId] = useSearchParams();

  const marginSchema = Yup.object().shape({
    value: Yup.number().required('Margin Value is required'),
    segment: Yup.string().required('Segment is required'),
  });

  const { data: marginIdData } = useQuery(
    ['getMarginById', marginId.get('marginId')],
    () => getMarginById(marginId.get('marginId')),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(marginId.get('marginId')),
    }
  );

  const formik = useFormik({
    initialValues: {
      value: marginId.get('marginId') ? marginIdData?.data?.data?.[0]?.margin?.value : '',
      segment: marginId.get('marginId') ? marginId.get('marginId') : '',
    },
    validationSchema: marginSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (marginId.get('marginId')) {
        updateMarginMutation.mutateAsync({
          value: Number(values.value),
          type: values.segment,
          id: marginId.get('marginId'),
        });
      } else {
        createMarginMutation.mutateAsync({
          value: Number(values.value),
          type: values.segment,
        });
      }
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, setFieldValue, resetForm } = formik;

  const returnFunc = () => {
    resetForm();
    handleMarginModal();
    if (marginId?.get('env')) {
      navigate({
        pathname: '/dashboard/margin',
        search: '?env=Prod',
      });
    } else {
      navigate({
        pathname: '/dashboard/margin',
        search: null,
      });
    }
  };

  const createMarginMutation = useMutation('createMargin', ({ value, type }) => createMargin({ val: value, type }), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        }
        refetchData();
        returnFunc();
      }
    },
    onError: (error) => {
      console.log('error', error);
      returnFunc();
      resetForm();
    },
  });

  const updateMarginMutation = useMutation(
    'updateMargin',
    ({ value, type, id }) => updateMargin({ val: value, type, id }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
          refetchData();
          returnFunc();
        }
      },
      onError: (error) => {
        console.log('error', error);
        returnFunc();
        resetForm();
      },
    }
  );

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          {
            <IconButton className={styles.closeBtn} onClick={() => returnFunc()}>
              <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
            </IconButton>
          }
          <Box className={styles.titleContainer}>
            <Typography className={styles.modalTitle}>
              {marginId.get('marginId') ? 'Update Margin' : 'Add Margin'}
            </Typography>
            <Button
              className={styles.submitButton}
              sx={{
                background: theme.palette.primary.main,
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}
              disabled={Object.keys(errors)?.length}
              onClick={() => handleSubmit()}
            >
              {marginId.get('marginId') ? 'Update Margin' : 'Assign Margin'}
            </Button>
          </Box>
          <FormikProvider value={formik}>
            <Card
              sx={{
                borderRadius: '5px',
                padding: '10px',
                marginY: '30px',
                boxShadow: 'none',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <Typography className={styles.inputTitle}>Select Vertical</Typography>
                  <Select
                    labelId="segment"
                    id="segment"
                    name="segment"
                    value={values.segment}
                    key={values.segment}
                    focused={values.segment}
                    onChange={handleChange}
                    error={Boolean(touched.segment && errors.segment)}
                    sx={{
                      borderRadius: '5px',
                      '.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                        padding: '11px',
                        borderRadius: '5px',
                      },
                      '.css-4xgxwl-MuiSelect-icon': {
                        top: 'auto !important',
                      },
                      '.css-1rctjok-MuiFormHelperText-root': {
                        marginLeft: 0,
                      },
                    }}
                    IconComponent={DownArrow}
                  >
                    <MenuItem value="Food">
                      <Stack direction="row" alignItems="center" gap="12px">
                        <Box className={styles.circle} sx={{ background: theme.palette.food.main }} />
                        Food
                      </Stack>
                    </MenuItem>
                    <MenuItem value="Product">
                      <Stack direction="row" alignItems="center" gap="12px">
                        <Box className={styles.circle} sx={{ background: theme.palette.product.main }} />
                        Product
                      </Stack>
                    </MenuItem>
                    <MenuItem value="Video">
                      <Stack direction="row" alignItems="center" gap="12px">
                        <Box className={styles.circle} sx={{ background: theme.palette.video.main }} />
                        Video
                      </Stack>
                    </MenuItem>
                  </Select>
                  {touched.segment && errors.segment && (
                    <FormHelperText
                      sx={{
                        color: theme.palette.error.main,
                        marginLeft: 0,
                      }}
                    >
                      {errors.segment}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box>
                  <Typography className={styles.inputTitle}>Margin Value %</Typography>
                  <TextField
                    fullWidth
                    autoComplete="value"
                    type="number"
                    name="value"
                    {...getFieldProps('value')}
                    error={Boolean(touched.value && errors.value)}
                    helperText={touched.value && errors.value}
                    focused={values.value}
                    sx={{
                      borderRadius: 0,
                      input: {
                        padding: '11px',
                        borderRadius: 0,
                      },
                      '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                        borderRadius: '5px',
                      },
                      '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                        marginLeft: 0,
                        color: theme.palette.error.main,
                      },
                    }}
                  />
                </Box>
              </Stack>
            </Card>
          </FormikProvider>
        </Box>
      </Slide>
    </Modal>
  );
};

export default AddMarginModal;
