import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
  Stack,
  IconButton,
} from '@mui/material';
import { useQuery } from 'react-query';
import useModal from '../../hooks/useModal';
import OrdersInfoModal from '../../components/OrdersInfoModal/OrdersInfoModal';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getOrders } from '../../api/GetOrders';
import { getTotalOrdersCount } from '../../api/GetTotalOrdersCount';
import styles from '../../pageStyles/pageStyles.module.scss';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import Loader from '../../components/Loader/Loader';
import useLoader from '../../hooks/useLoader';

const buttonType = ['Food', 'Product', 'Video'];

const statusColor = {
  Received: '#525252',
  'In Process': '#525252',
  Fulfilled: '#00CB20',
  Rejected: '#DA1A18',
};

const Orders = () => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [categoryState, setCategoryState] = useState('Food');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [id, setId] = useState('');

  const { loader, showLoader, hideLoader } = useLoader();

  const { orderModal, handleOrdersModal } = useModal();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onClickCatBtn = (value) => {
    setCategoryState(value);
    setPage(1);
    setRowsPerPage(10);
  };

  // Get All Orders Data
  const { data, isLoading } = useQuery(
    ['getOrders', categoryState, page, rowsPerPage],
    () => getOrders({ type: categoryState, page, limit: rowsPerPage }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );
  const orderData = data?.data?.data;

  // Get Total Orders Count
  const { data: ordersCount } = useQuery(
    ['getTotalOrdersCount', categoryState],
    () => getTotalOrdersCount({ type: categoryState }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Page title="Orders">
        {!isLoading && (
          <Container className={styles.container}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: '30px' }}>
              <Stack direction="row" alignItems="flex-start" gap="20px">
                {buttonType.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      className={styles.categoryState}
                      sx={{
                        background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                        color: categoryState === item ? 'white' : theme.palette.primary.main,
                        '&:hover': {
                          background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                          boxShadow: '0px 0.920487px 5px rgba(0, 0, 0, 0.15)',
                        },
                      }}
                      onClick={() => onClickCatBtn(item)}
                    >
                      {Boolean(categoryState !== item) && (
                        <Box sx={{ background: theme.palette[item.toLowerCase()].main }} className={styles.dot} />
                      )}
                      {item}
                    </Button>
                  );
                })}
              </Stack>

              <Box className={styles.filterBox}>
                <IconButton sx={{ padding: 0 }} disableRipple>
                  <FilterIcon />
                </IconButton>
              </Box>
            </Stack>

            <Card className={styles.card}>
              <Scrollbar>
                <TableContainer sx={{ width: '100%' }}>
                  <Table>
                    <TableHead sx={{ background: "#F4F4F4" }}>
                      <TableRow className={styles.tableRow}>
                        <TableCell
                          align="left"
                          width="160px"
                          className={styles.borderTableCell}
                          sx={{ paddingLeft: '15px !important' }}
                        >
                          Order No
                        </TableCell>
                        <TableCell align="center" width="309px" className={styles.borderTableCell}>
                          Business Name
                        </TableCell>
                        <TableCell align="center" width="125px" className={styles.borderTableCell}>
                          Order Value
                        </TableCell>
                        <TableCell align="center" width="198px" className={styles.borderTableCell}>
                          Customer Name
                        </TableCell>
                        <TableCell align="center" width="111px" className={styles.borderTableCell}>
                          Status
                        </TableCell>
                        <TableCell
                          align="center"
                          width="97px"
                          className={styles.borderTableCell}
                          sx={{ borderRight: '0 !important' }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {data?.data?.success && (
                      <TableBody>
                        {(orderData || []).map((item, index) => {
                          return (
                            <TableRow key={index} sx={{ '&:nth-child(even)': { backgroundColor: '#F1F1F1' } }}>
                              <TableCell className={styles.tableCellStyle} sx={{ paddingLeft: '15px !important' }}>
                                <Typography
                                  sx={{ color: theme.palette.primary.main, fontSize: 14, lineHeight: '140%' }}
                                >
                                  {item?.orderNo}
                                </Typography>
                              </TableCell>

                              <TableCell
                                className={styles.tableCellStyle}
                                sx={{ color: theme.palette.primary.main }}
                                align="center"
                              >
                                {item?.businessName}
                              </TableCell>

                              <TableCell
                                className={styles.tableCellStyle}
                                align="center"
                                sx={{ fontWeight: '400 !important' }}
                              >
                                {item?.orderValue}
                              </TableCell>

                              <TableCell className={styles.tableCellStyle} align="center">
                                <Typography sx={{ fontSize: 14, color: theme.palette.primary.main }}>
                                  {item?.customerName}
                                </Typography>
                              </TableCell>

                              <TableCell className={styles.tableCellStyle} align="center">
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    lineHeight: '140%',
                                    color: `${statusColor[item?.status]} !important`,
                                  }}
                                >
                                  {item?.status}
                                </Typography>
                              </TableCell>

                              <TableCell align="center" className={styles.btnCell}>
                                <Typography
                                  className={styles.viewButton}
                                  sx={{ color: theme.palette.primary.main }}
                                  onClick={() => {
                                    setId(item?._id);
                                    handleOrdersModal();
                                  }}
                                >
                                  View
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={ordersCount?.data?.data?.count ? ordersCount.data.data.count : 0}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        )}
        <OrdersInfoModal isOpen={orderModal} handleOrdersModal={handleOrdersModal} id={id} type={categoryState} />

        {isLoading && <Loader isLoaderVisible={loader} />}
      </Page>
    </>
  );
};

export default Orders;
