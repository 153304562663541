import client from './Client';

const endPoint = '/su/category/add/margin';

const createMargin = ({ val, type }) => {
  const token = localStorage.getItem('token');

  return client.put(
    endPoint,
    {
      type,
      margin: {
        value: val,
      },
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { createMargin };
