// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Info_infoBox__H9Nz9 {\n  width: 220px;\n  background: #f9f9f9;\n  padding: 10px 15px;\n  border-radius: 6px;\n}\n\n.Info_label__-58WY {\n  color: #5c5b69;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 150%;\n}\n\n.Info_count__qqJkW {\n  color: #353445;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 150%;\n}", "",{"version":3,"sources":["webpack://./src/components/Custom/Info/Info.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".infoBox {\n  width: 220px;\n  background: #f9f9f9;\n  padding: 10px 15px;\n  border-radius: 6px;\n}\n\n.label {\n  color: #5c5b69;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 150%;\n}\n\n.count {\n    color:  #353445;\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 150%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": "Info_infoBox__H9Nz9",
	"label": "Info_label__-58WY",
	"count": "Info_count__qqJkW"
};
export default ___CSS_LOADER_EXPORT___;
