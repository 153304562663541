import client from './Client';

const endPoint = '/su/user/delete/';

const deleteUser = (id) => {
  //   const { client } = useClient(process.env.NEXT_PUBLIC_AUTH_SERVER || "");
  const token = localStorage.getItem('token');
  return client.delete(
    endPoint + id,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { deleteUser };
