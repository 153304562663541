import client from './Client';

const endPoint = 'su/invoice/all';

const getInvoices = ({ status, page, limit }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      status,
      page,
      limit,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getInvoices };
