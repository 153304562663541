import client from './Client';

const endPoint = '/auth/su/login';

const login = (email, password) => {
  //   const { client } = useClient(process.env.AUTH_SERVER || '');
  return client.post(endPoint, {
    email,
    password,
  });
};

export { login };
