import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  CardMedia,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import useLoader from '../../hooks/useLoader';
import useModal from '../../hooks/useModal';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import CarouselCard from '../../components/Custom/CarouselCard/CarouselCard';
import Page from '../../components/Page';
import Loader from '../../components/Loader/Loader';
import { ReactComponent as AddFileIcon } from '../../assets/icons/addFile.svg';
import { updateCarousel } from '../../api/updateCarousel';
import { createCarousel } from '../../api/CreateCarousel';
import { getCarousels } from '../../api/GetCarousels';
import { deleteCarousel } from '../../api/DeleteCarousel';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import styles from '../../pageStyles/pageStyles.module.scss';

const Carousel = () => {
  const theme = useTheme();
  const [carouselId] = useSearchParams();
  const [id, setId] = useState();
  const { confirmationModal, handleConfirmationModal } = useModal();
  const { loader, showLoader, hideLoader } = useLoader();

  const carouselValidation = Yup.object({
    carouselImage: Yup.string().required('Image is Required'),
    url: Yup.string().required('Url is Required'),
  });

  const { data, isLoading, refetch } = useQuery('getCarousels', () => getCarousels(), {
    refetchOnWindowFocus: false,
  });
  const carouselData = data?.data?.data;

  const initialValues = {
    carouselImage: '',
    url: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: carouselValidation,
    onSubmit: (values, { resetForm }) => {
      createUpdateCarouselMutation.mutateAsync({
        carouselImage: values.carouselImage,
        url: values.url,
      });
    },
  });
  const { handleSubmit, handleChange, errors, touched, values, getFieldProps, setFieldValue, resetForm } = formik;

  const createAndUpdateCarousel = async ({ carouselImage, url }) => {
    const cId = carouselId.get('carouselId');
    const body = new FormData();
    body.append('image', carouselImage);
    body.append('url', url);
    if (cId) {
      return updateCarousel(body, cId);
    }
    return createCarousel(body);
  };

  const createUpdateCarouselMutation = useMutation(
    'createUpdateCarousel',
    ({ carouselImage, url }) => createAndUpdateCarousel({ carouselImage, url }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        resetForm();
        refetch();
        hideLoader();
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  const deleteCarouselMutation = useMutation('deleteCarousel', (id) => deleteCarousel(id), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        }
      }
      resetForm();
      refetch();
      hideLoader();
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return (
    <Page title="Carousel">
      {!isLoading && !loader && (
        <>
          <Stack>
            <Typography className={styles.title}>carousel</Typography>
            <Box className={styles.inputBoxContainer}>
              <FormikProvider value={formik}>
                <Box>
                  <Typography className={styles.inputTitle}>Image</Typography>
                  <FileUploadBtn
                    setFieldValue={setFieldValue}
                    file={values.carouselImage}
                    errors={errors}
                    touched={touched}
                    name="carouselImage"
                  />
                </Box>

                <Box>
                  <Typography className={styles.inputTitle}>URL</Typography>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="url"
                    focused={Boolean(values.url)}
                    {...getFieldProps('url')}
                    error={Boolean(touched.url && errors.url)}
                    helperText={touched.url && errors.url}
                    sx={{
                      width: 400,
                      height: 36,
                      borderRadius: 0,
                      input: {
                        padding: '11px',
                        borderRadius: 0,
                      },
                      '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                        borderRadius: '5px',
                      },
                      '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                        marginLeft: 0,
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Typography className={styles.inputTitle} sx={{ opacity: 0 }}>
                    Upload
                  </Typography>
                  <Button
                    sx={{
                      width: 120,
                      height: 36,
                      background: theme.palette.primary.main,
                      color: '#FFF',
                      borderRadius: '5px',
                      fontSize: 16,
                      fontWeight: 500,
                      lineHeight: '132%',
                      padding: '10px 18px',
                      '&:hover': {
                        background: theme.palette.primary.main,
                      },
                      '&:disabled': {
                        background: '#E3E3E3',
                      },
                    }}
                    disabled={Object.keys(errors)?.length}
                    onClick={handleSubmit}
                  >
                    Upload
                  </Button>
                </Box>
              </FormikProvider>
            </Box>
          </Stack>
          {data?.data?.success && (
            <Box className={styles.gridContainer}>
              {(carouselData || []).map((item, index) => {
                return (
                  <Stack direction="row" gap="10px" key={index}>
                    <CarouselCard key={index} carouselData={item} />
                    <Stack direction="column" justifyContent="end" paddingBottom="10px">
                      <IconButton
                        sx={{ padding: '0 !important' }}
                        onClick={() => {
                          setId(item?._id);
                          handleConfirmationModal();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                );
              })}
            </Box>
          )}
        </>
      )}
      {(isLoading || loader) && <Loader isLoaderVisible />}

      {confirmationModal && (
        <ConfirmationModal
          isOpen={confirmationModal}
          handleConfirmationModal={handleConfirmationModal}
          modalText={'Are you sure you wish to delete?'}
          onConfirm={() => {
            deleteCarouselMutation.mutateAsync(id);
            showLoader();
          }}
        />
      )}
    </Page>
  );
};

const FileUploadBtn = ({ setFieldValue, file, touched, errors, name }) => {
  const theme = useTheme();

  const inputFile = useRef(null);
  const onClickAdd = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleOnChange = (e) => {
    setFieldValue(name, e.target.files[0]);
  };

  return (
    <FormControl className={styles.formControl}>
      <Button
        onClick={onClickAdd}
        className={styles.fileBtn}
        sx={{
          borderColor: touched.carouselImage && errors.carouselImage ? `${theme.palette.error.main} !important` : '',
        }}
      >
        {!file && (
          <Box>
            <AddFileIcon />
            <Typography className={styles.addText}>Add Image</Typography>
          </Box>
        )}

        {file && (
          <CardMedia component="img" height={120} image={typeof file === 'string' ? file : URL.createObjectURL(file)} />
        )}

        <input accept="image/*" type="file" onChange={handleOnChange} ref={inputFile} name={name} hidden id={name} />
      </Button>
      {touched.carouselImage && errors.carouselImage && (
        <FormHelperText sx={{ color: theme.palette.error.main }} className={styles.helperText}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Carousel;
