import { Box, Pagination } from '@mui/material';
import styles from './AppPagination.module.scss';

const AppPagination = ({ totalItem = 1, page = 1, handlePageChange }) => {
  if (Math.ceil(totalItem / 10) > 1) {
    return (
      <Box className={styles.paginationContainer}>
        <Pagination
          count={Math.ceil(totalItem / 10)}
          shape="rounded"
          onChange={handlePageChange}
          page={page}
          color="primary"
          sx={{
            '.css-ms1j6l-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover': {
              background: '#8880F6',
            },
            '.css-ms1j6l-MuiButtonBase-root-MuiPaginationItem-root:hover': {
              border: '0.5px solid #cacaca',
            },
            '.css-ms1j6l-MuiButtonBase-root-MuiPaginationItem-root': {
              border: '0.5px solid #cacaca',
            },
          }}
        />
      </Box>
    );
  }
  return null;
};
export default AppPagination;
