import client from './Client';

const endPoint = '/su/refund/reject/';

const RejectRefund = ({ refundId, reason }) => {
  const token = localStorage.getItem('token');

  return client.put(
    endPoint + refundId,
    { reason },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export default RejectRefund;
