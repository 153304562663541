import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  IconButton,
  Typography,
  Container,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import AddStateGstModal from '../../components/AddStateGstModal/AddStateGstModal';
import { getStateGst } from '../../api/GetStateGst';
import { deleteStateGst } from '../../api/DeleteStateGst';
import Scrollbar from '../../components/Scrollbar';
import useModal from '../../hooks/useModal';
import Page from '../../components/Page';
import Loader from '../../components/Loader/Loader';
import styles from '../../pageStyles/pageStyles.module.scss';
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon.svg';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';

const StateGST = () => {
  const theme = useTheme();
  const [stateGstId, setStateGstId] = useState('');
  const { showAddStateGstModal, handleShowStateGstModal, confirmationModal, handleConfirmationModal } = useModal();

  const { data, isLoading, refetch } = useQuery(['getStateGst'], () => getStateGst(), {
    refetchOnWindowFocus: false,
  });


  const handleEdit = (id) => {
    setStateGstId(id);
    handleShowStateGstModal();
  };

  const handleDelete = (id) => {
    setStateGstId(id);
    handleConfirmationModal();
  };

  const deleteStateGstMutation = useMutation('addStateGst', () => deleteStateGst(stateGstId), {
    onSuccess: ({ data }) => {
      if (data) {
        if (!data?.success) {
          toast.error(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
        } else if (data?.success) {
          toast.success(data?.message, {
            position: 'bottom-center',
            duration: 2000,
          });
          setStateGstId('');
          refetch();
        }
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return (
    <Page title="Gst">
      {!isLoading && (
        <Container className={styles.container}>
          <Stack direction="row" justifyContent="flex-end" width="1000px" sx={{ marginBottom: '30px' }}>
            <Button
              className={styles.addButton}
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
              }}
              onClick={() => handleShowStateGstModal()}
            >
              Add GST
            </Button>
          </Stack>

          {data?.data?.success ? (
            <Box className={styles.inviteDataWrapper}>
              <TableContainer sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ background: '#F5F4F4' }}>
                      <TableCell
                        align="left"
                        width="120px"
                        className={styles.inviteTableCell}
                        sx={{ paddingLeft: '20px !important' }}
                      >
                        Date
                      </TableCell>
                      <TableCell align="start" width="165px" className={styles.inviteTableCell}>
                        State
                      </TableCell>
                      <TableCell align="start" width="155px" className={styles.inviteTableCell}>
                        GST No.
                      </TableCell>
                      <TableCell align="start" width="155px" className={styles.inviteTableCell}>
                      FSSAI no.
                      </TableCell>
                      <TableCell align="start" width="265px" className={styles.inviteTableCell}>
                      Address
                      </TableCell>
                      <TableCell align="start" width="140px" className={styles.inviteTableCell}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {((data?.data?.success && data?.data?.data) || []).map((item, index) => {
                      return (
                        <TableRow key={index} className={index % 2 === 1 ? styles.darkBackground : ''}>
                          <TableCell className={styles.inviteTableDataCell} align="start" justifyContent="start">
                            {item?.createdAt?.slice(0, 10)}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.state}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.gstNo}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.fssaiNumber}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="end">
                            {item?.address}
                          </TableCell>
                          <TableCell
                            className={styles.inviteTableDataCell}
                            align="start"
                            sx={{ borderRight: '0px !important' }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                              }}
                            >
                              <IconButton onClick={() => handleEdit(item?._id)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(item?._id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box className={styles.noDataWrapper}>
              <Typography className={styles.noDataText}>No data found</Typography>
            </Box>
          )}
        </Container>
      )}

      {isLoading && <Loader isLoaderVisible />}
      <AddStateGstModal
        isOpen={showAddStateGstModal}
        handleStateGstModal={handleShowStateGstModal}
        stateGstId={stateGstId}
        setStateGstId={setStateGstId}
        refetch={refetch}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={'Are you sure you wish to delete?'}
        onConfirm={() => {
          deleteStateGstMutation.mutateAsync();
        }}
      />
    </Page>
  );
};

export default StateGST;
