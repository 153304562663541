import client from "./Client"

const endPoint = 'su/faq/question/edit/'

const updateQuestion = (value, headerId, questionId) => {
    const token = localStorage.getItem("token");

    return client.put(
        `${endPoint + headerId}/${questionId}`,
        value,
        {
            headers: {
                Authorization: token
            }
        }
    )
}

export { updateQuestion }