import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const logo = (
    <svg width="66" height="20" viewBox="0 0 66 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.62692 7.57312C10.6524 7.06089 11.2491 6.01356 11.2491 4.68077C11.2491 2.33645 9.57917 0.879333 6.8937 0.879333H0.223789C0.164609 0.879333 0.107837 0.902774 0.0658971 0.944528C0.023957 0.986282 0.000263025 1.04295 0 1.10213V15.2098C0 15.2692 0.0235764 15.3261 0.065545 15.368C0.107514 15.41 0.164437 15.4336 0.223789 15.4336H6.31882C9.77213 15.4336 11.6758 13.887 11.6758 11.0772C11.6758 8.93575 10.5519 7.98887 9.62692 7.57312ZM2.59695 3.39572C2.59721 3.33654 2.62091 3.27987 2.66285 3.23812C2.70479 3.19636 2.76156 3.17292 2.82074 3.17293H6.48691C7.9848 3.17293 8.65219 3.6951 8.65219 4.86974C8.65219 6.04439 7.98878 6.62823 6.67986 6.62823H2.82074C2.76156 6.62823 2.70479 6.60479 2.66285 6.56304C2.62091 6.52128 2.59721 6.46462 2.59695 6.40544V3.39572ZM6.48691 13.1698H2.82074C2.76138 13.1698 2.70447 13.1463 2.6625 13.1043C2.62053 13.0623 2.59695 13.0054 2.59695 12.946V9.10682C2.59721 9.04764 2.62091 8.99097 2.66285 8.94922C2.70479 8.90747 2.76156 8.88402 2.82074 8.88402H6.7276C7.79284 8.88402 9.06098 9.22916 9.06098 10.8782C9.05601 12.3393 8.12007 13.1738 6.48691 13.1738V13.1698Z"
        fill="#8880F6"
      />
      <path
        d="M22.3302 15.4635C22.3302 15.4893 22.3302 15.5152 22.3302 15.542V18.7905C22.3302 18.8783 22.2953 18.9625 22.2332 19.0247C22.1711 19.0868 22.0869 19.1217 21.999 19.1217H14.2868C14.2401 19.1216 14.1943 19.1092 14.154 19.0858C14.1137 19.0624 14.0802 19.0288 14.057 18.9884L12.9997 17.1563C12.9765 17.1159 12.9643 17.0701 12.9644 17.0235C12.9645 16.9769 12.9769 16.9311 13.0003 16.8908C13.0237 16.8505 13.0572 16.817 13.0976 16.7938C13.1381 16.7705 13.1839 16.7584 13.2305 16.7584H19.795V14.3147C19.1982 15.0825 18.124 15.5351 16.82 15.5351C14.3335 15.5351 12.7192 13.9089 12.7192 11.3915V4.76633C12.7192 4.70698 12.7428 4.65006 12.7848 4.60809C12.8268 4.56612 12.8837 4.54254 12.943 4.54254H15.0317C15.0908 4.54254 15.1475 4.56601 15.1893 4.6078C15.231 4.64958 15.2545 4.70625 15.2545 4.76534V11.0941C15.2545 12.6726 15.9159 13.4076 17.3353 13.4076C18.8769 13.4076 19.7979 12.5413 19.7979 11.0941V4.76633C19.7979 4.70698 19.8215 4.65006 19.8635 4.60809C19.9055 4.56612 19.9624 4.54254 20.0217 4.54254H22.1104C22.1703 4.54254 22.2277 4.56633 22.2701 4.60867C22.3124 4.65101 22.3362 4.70844 22.3362 4.76832L22.3302 15.4635Z"
        fill="#8880F6"
      />
      <path
        d="M51.2659 0.878417H48.9903C48.9404 0.878456 48.8921 0.895106 48.8528 0.925732C48.8135 0.956358 48.7856 0.99921 48.7734 1.0475L46.3495 10.6028L43.8869 1.04651C43.8743 0.998485 43.8463 0.955953 43.8071 0.925547C43.7678 0.895141 43.7197 0.878569 43.67 0.878417H41.3188C41.2686 0.87839 41.22 0.89526 41.1806 0.926302C41.1413 0.957344 41.1136 1.00075 41.1019 1.04949L38.7815 10.5839L36.3437 1.04651C36.3313 0.9984 36.3033 0.955771 36.264 0.925339C36.2248 0.894906 36.1765 0.878399 36.1268 0.878417H33.7447C33.7103 0.878547 33.6764 0.88661 33.6456 0.901979C33.6148 0.917348 33.588 0.939608 33.5672 0.967031C33.5464 0.994453 33.5323 1.0263 33.5258 1.06009C33.5193 1.09389 33.5207 1.12872 33.5299 1.16188L37.4378 15.2656C37.4508 15.3128 37.479 15.3544 37.518 15.384C37.557 15.4137 37.6046 15.4297 37.6536 15.4297H39.7711C39.8204 15.4296 39.8682 15.4133 39.9072 15.3833C39.9462 15.3532 39.9743 15.3112 39.987 15.2636L42.5143 5.66751L45.0008 15.2626C45.0134 15.3105 45.0415 15.3529 45.0807 15.3832C45.12 15.4134 45.1681 15.4298 45.2177 15.4297H47.3989C47.4478 15.4296 47.4953 15.4135 47.5343 15.3838C47.5733 15.3542 47.6015 15.3127 47.6147 15.2656L51.4857 1.16089C51.4951 1.12737 51.4964 1.09213 51.4897 1.058C51.4829 1.02386 51.4683 0.991784 51.4469 0.964326C51.4256 0.936868 51.3981 0.914795 51.3666 0.899877C51.3352 0.884959 51.3007 0.877609 51.2659 0.878417Z"
        fill="#8880F6"
      />
      <path
        d="M54.9361 0.908569H52.8514C52.7278 0.908569 52.6276 1.00876 52.6276 1.13236V3.46374C52.6276 3.58734 52.7278 3.68753 52.8514 3.68753H54.9361C55.0597 3.68753 55.1599 3.58734 55.1599 3.46374V1.13236C55.1599 1.00876 55.0597 0.908569 54.9361 0.908569Z"
        fill="#8880F6"
      />
      <path
        d="M54.9371 4.54321H52.8484C52.7892 4.54347 52.7326 4.56716 52.6908 4.6091C52.6491 4.65104 52.6256 4.70782 52.6256 4.767V15.2095C52.6255 15.2388 52.6312 15.2679 52.6423 15.2951C52.6534 15.3222 52.6698 15.3469 52.6905 15.3677C52.7112 15.3885 52.7358 15.405 52.7629 15.4162C52.79 15.4275 52.8191 15.4333 52.8484 15.4333H54.9371C54.9669 15.4339 54.9966 15.4286 55.0243 15.4177C55.052 15.4067 55.0773 15.3904 55.0986 15.3695C55.1199 15.3487 55.1368 15.3238 55.1484 15.2963C55.1599 15.2688 55.1659 15.2393 55.1659 15.2095V4.766C55.1657 4.73627 55.1597 4.70687 55.1481 4.6795C55.1365 4.65214 55.1195 4.62736 55.0982 4.60662C55.0769 4.58588 55.0517 4.56959 55.024 4.5587C54.9964 4.54781 54.9668 4.54255 54.9371 4.54321Z"
        fill="#8880F6"
      />
      <path
        d="M65.1756 13.2799H59.4764L65.0055 6.83078C65.0579 6.76932 65.0897 6.69293 65.0963 6.61243C65.1029 6.53192 65.084 6.45138 65.0423 6.3822L64.0477 4.72915C64.0137 4.67238 63.9656 4.6254 63.9081 4.59279C63.8506 4.56019 63.7856 4.54309 63.7194 4.54315H56.7571C56.6977 4.54315 56.6408 4.56673 56.5989 4.6087C56.5569 4.65067 56.5333 4.70759 56.5333 4.76694V6.42696C56.5336 6.48614 56.5573 6.5428 56.5992 6.58456C56.6411 6.62631 56.6979 6.64975 56.7571 6.64975H61.9291L56.3841 13.267C56.3327 13.3282 56.3018 13.404 56.2955 13.4837C56.2893 13.5634 56.3081 13.643 56.3493 13.7115L57.2703 15.2433C57.3044 15.2999 57.3525 15.3468 57.41 15.3794C57.4675 15.4119 57.5324 15.4291 57.5985 15.4293H65.1716C65.2009 15.4293 65.23 15.4235 65.2571 15.4122C65.2842 15.4009 65.3088 15.3844 65.3295 15.3636C65.3502 15.3429 65.3666 15.3182 65.3777 15.291C65.3888 15.2639 65.3945 15.2348 65.3944 15.2055V13.5027C65.3944 13.4443 65.3714 13.3882 65.3305 13.3465C65.2896 13.3049 65.2339 13.2809 65.1756 13.2799Z"
        fill="#8880F6"
      />
      <path
        d="M32.2529 13.2799H26.5538L32.0829 6.83078C32.1353 6.76932 32.167 6.69293 32.1736 6.61243C32.1803 6.53192 32.1614 6.45138 32.1197 6.3822L31.125 4.72915C31.0911 4.67238 31.043 4.6254 30.9855 4.59279C30.9279 4.56019 30.8629 4.54309 30.7968 4.54315H23.8355C23.7761 4.54315 23.7192 4.56673 23.6772 4.6087C23.6353 4.65067 23.6117 4.70759 23.6117 4.76694V6.42696C23.6119 6.48614 23.6356 6.5428 23.6776 6.58456C23.7195 6.62631 23.7763 6.64975 23.8355 6.64975H29.0075L23.4625 13.267C23.4111 13.3282 23.3802 13.404 23.3739 13.4837C23.3677 13.5634 23.3865 13.643 23.4277 13.7115L24.3487 15.2433C24.3828 15.2999 24.4309 15.3468 24.4884 15.3794C24.5459 15.4119 24.6108 15.4291 24.6769 15.4293H32.2499C32.2793 15.4293 32.3083 15.4235 32.3354 15.4122C32.3625 15.4009 32.3871 15.3844 32.4078 15.3636C32.4285 15.3429 32.4449 15.3182 32.4561 15.291C32.4672 15.2639 32.4729 15.2348 32.4727 15.2055V13.5027C32.4727 13.4441 32.4497 13.3879 32.4085 13.3462C32.3674 13.3045 32.3115 13.2807 32.2529 13.2799Z"
        fill="#8880F6"
      />
    </svg>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink to="/" style={{ height: '20px' }}>
      {logo}
    </RouterLink>
  );
}
