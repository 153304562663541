import {
  Box,
  CardMedia,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import useModal from '../../hooks/useModal';
import getS3Bucket from '../../utils/getS3Bucket';
import { getInvites } from '../../api/GetInvites';
import { inviteAction } from '../../api/InviteAction';
import Page from '../../components/Page';
import styles from '../../pageStyles/pageStyles.module.scss';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as Disapprove } from '../../assets/icons/cross.svg';
import { ReactComponent as Approve } from '../../assets/icons/right.svg';
import Scrollbar from '../../components/Scrollbar';
import FullScreenCarouselModal from '../../components/FullScreenCarouselModal/FullScreenCarouselModal';

const InvitationRequests = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isApproved, setIsApproved] = useState(false);

  const { data, refetch } = useQuery(
    ['getTemplate', isApproved, page, limit],
    () => getInvites(isApproved, page, limit),
    {}
  );
  const [mediaData, setMediaData] = useState([]);
  const { showFullScreenCarouselModal, handleFullScreenCarouselModal } = useModal();
  const inviteActionMutation = useMutation('inviteAction', ({ id, isApproved }) => inviteAction({ isApproved, id }), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        refetch();
        if (data?.message === 'Invite code approved successfully') {
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      } else {
        toast.error(data?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    setPage(1);
  }, [isApproved]);

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getBackgroundColour = (vertical) => {
    if (vertical === 'Food') {
      return '#FFB30E';
    }
    if (vertical === 'Product') {
      return '#2FC29A';
    }
    if (vertical === 'Video') {
      return '#FF6962';
    }
    return '#FFF';
  };

  return (
    <Page title="InvitationRequests">
      <Container className={styles.container}>
        <Stack direction="row" justifyContent="space-between" width="1000px" marginBottom="30px">
          <Box className={styles.tabContainer}>
            <Box className={!isApproved ? styles.activeTab : styles.inActiveTab} onClick={() => setIsApproved(false)}>
              <Typography className={!isApproved ? styles.activeTabText : styles.inActiveTabText}>Pending</Typography>
            </Box>
            <Box className={isApproved ? styles.activeTab : styles.inActiveTab} onClick={() => setIsApproved(true)}>
              <Typography className={isApproved ? styles.activeTabText : styles.inActiveTabText}>Completed</Typography>
            </Box>
          </Box>
          <Box className={styles.filterBox}>
            <IconButton sx={{ padding: 0 }} disableRipple>
              <FilterIcon />
            </IconButton>
          </Box>
        </Stack>
        {data?.data.success ? (
          <Box className={styles.inviteDataWrapper}>
            <Scrollbar>
              <TableContainer sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ background: '#F5F4F4' }}>
                      <TableCell
                        align="left"
                        width="178px"
                        className={styles.inviteTableCell}
                        sx={{ paddingLeft: '20px !important' }}
                      >
                        Name
                      </TableCell>
                      <TableCell align="start" width="167px" className={styles.inviteTableCell}>
                        Email
                      </TableCell>
                      <TableCell align="start" width="79px" className={styles.inviteTableCell}>
                        Vertical
                      </TableCell>
                      <TableCell align="start" width="112px" className={styles.inviteTableCell}>
                        Mobile No.
                      </TableCell>
                      <TableCell align="start" width="264px" className={styles.inviteTableCell}>
                        Business Description
                      </TableCell>
                      <TableCell align="start" width="104px" className={styles.inviteTableCell}>
                        Media
                      </TableCell>
                      <TableCell align="start" width="90px" className={styles.inviteTableCell}>
                        Approval
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(data?.data?.data?.data?.data || []).map((item, index) => {
                      return (
                        <TableRow key={index} className={index % 2 === 1 ? styles.darkBackground : ''}>
                          <TableCell className={styles.inviteTableDataCell} align="start" justifyContent="start">
                            {item?.name}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.email}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="end">
                            <Box
                              sx={{
                                height: '14px',
                                width: '14px',
                                borderRadius: '14px',
                                background: getBackgroundColour(item?.vertical),
                                margin: 'auto',
                              }}
                            />
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.mobile}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.businessDescription}
                          </TableCell>
                          <TableCell className={styles.inviteTableDataCell} align="start">
                            {item?.vertical !== 'Video' && (
                              <Tooltip title="Tap to view" arrow>
                                <Box
                                  className={styles.inviteMediaWrapper}
                                  onClick={() => {
                                    setMediaData(item?.images);
                                    handleFullScreenCarouselModal();
                                  }}
                                >
                                  <CardMedia
                                    component={'img'}
                                    image={getS3Bucket() && item?.images[0] ? getS3Bucket() + item?.images[0] : ''}
                                    className={styles.inviteMedia}
                                  />
                                  {item?.images?.length > 1 && (
                                    <Box className={styles.inviteImagesCount}>
                                      <Typography className={styles.inviteMediaCount}>
                                        {item?.images?.length}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell
                            className={styles.inviteTableDataCell}
                            align="start"
                            sx={{ borderRight: '0px !important', color: item?.isApproved ? '#00CB20' : '#DB1212' }}
                          >
                            {item?.isApproved === undefined ? (
                              <Box className={styles.actionWrapper}>
                                <IconButton
                                  onClick={() => {
                                    inviteActionMutation.mutateAsync({
                                      id: item?._id,
                                      isApproved: false,
                                    });
                                  }}
                                >
                                  <Disapprove />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    inviteActionMutation.mutateAsync({
                                      id: item?._id,
                                      isApproved: true,
                                    });
                                  }}
                                >
                                  <Approve />
                                </IconButton>
                              </Box>
                            ) : (
                              <>{item?.isApproved ? 'Approved' : 'Rejected'}</>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              count={data?.data?.data?.data?.total}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        ) : (
          <Box className={styles.noDataWrapper}>
            <Typography className={styles.noDataText}>No data found</Typography>
          </Box>
        )}
      </Container>
      <FullScreenCarouselModal
        open={showFullScreenCarouselModal}
        handleModal={handleFullScreenCarouselModal}
        mediaData={mediaData}
        mediaType={'Image'}
      />
    </Page>
  );
};
export default InvitationRequests;
