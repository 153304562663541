import client from './Client';

const endPoint = '/su/gst/';

const getStateGst = () => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getStateGst };
