import client from './Client';



const SettleCreditNote = ({ invoiceId,crNoteId ,userId}) => {
  const token = localStorage.getItem('token');
  const endPoint = `/su/transaction/${invoiceId}/crId/${crNoteId}`;
  return client.put(
    endPoint,
    {userId},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export default SettleCreditNote;