import { useState, useRef ,useEffect} from "react";
import { Editor } from "@tinymce/tinymce-react";



const EditorField = ({ setFieldValue, initalAnswer }) => {
    const [content, setContent] = useState("");
    const [text, setText] = useState();

    const onEditorChange = (a, editor) => {
        setContent(a);
        setText(editor.getContent({ format: "html" }));
        setFieldValue(text)
    };

    // useEffect(()=>{
    //     console.log("running ...")
    //     if(initalAnswer){
    //         setContent(initalAnswer)
    //     }
    // },[initalAnswer])

    return (
        <Editor
            // initialValue={initalAnswer}
            onEditorChange={onEditorChange}
            value={content}
            toolbar='bold italic underline code alignleft aligncenter alignright alignjustify bullist numlist outdent indent'
            plugins='mentions advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media paste code help wordcount'
            init={{
                height: 500,
                width: "100%",
                menubar: false,
                content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                emoticons_append: {
                    custom_mind_explode: {
                        keywords: ["brain", "mind", "explode", "blown"],
                        char: "🤯"
                    }
                }
            }}
        />
    )
}

export { EditorField }