import client from './Client';

const endPoint = '/su/category/margin';

const getMarginById = (id) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    { type: id },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getMarginById };
