import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import styles from './Verticaldata.module.scss';

const VerticalDataBox = ({ vertical, count }) => {
  const theme = useTheme();
  return (
    <Box
      className={
        (vertical === 'Food' && styles.foodWrapper) ||
        (vertical === 'Product' && styles.productWrapper) ||
        (vertical === 'Video' && styles.videoWrapper)
      }
    >
      <Box sx={{
        display:"flex",
        alignItems:"center",
        gap:"8px"
      }}>
        <Box
          sx={{
            width: 11,
            height: 11,
            borderRadius: '50%',
            background: theme.palette[vertical.toLowerCase()].main,
          }}
        />
        <Typography className={styles.label}>{vertical}</Typography>
      </Box>
      <Typography className={styles.count}>{count}</Typography>
    </Box>
  );
};

export { VerticalDataBox };
