import * as Yup from 'yup';

const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const aadharNo = /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/;

const addSalesPersonValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  mobile: Yup.string().required('Required').matches(mobileRegex, 'Invalid'),
  email: Yup.string().required('Required').email('Invalid'),
  address: Yup.string().required('Required'),
  aadharNo: Yup.number('Invalid')
    .required('Required')
    .test({
      name: 'aadharNoNumberLength',
      message: 'Invalid',
      test: (value) => {
        if (String(value).length === 12) {
          return true;
        }
        return false;
      },
    }),
});

export default addSalesPersonValidationSchema;
