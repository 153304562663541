import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import styles from './StoreInvoice.module.scss';

const InProcessStoreInvoicesTable = ({ inProcessInvoices, handleConfirmationModal, setId }) => {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#F5F4F4' }}>
            <TableCell align="left" width="241px" className={styles.tableCell}>
              Invoice Number
            </TableCell>
            <TableCell align="center" width="150px" className={styles.tableCell}>
              Invoice Date
            </TableCell>
            <TableCell align="center" width="150px" className={styles.tableCell}>
              Order Number
            </TableCell>
            <TableCell align="center" width="150px" className={styles.tableCell}>
              Order Date
            </TableCell>
            <TableCell align="center" width="150px" className={styles.tableCell}>
              Value ₹
            </TableCell>
            <TableCell align="center" width="150px" className={styles.tableCell}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(inProcessInvoices || []).map((item, index) => {
            return (
              <TableRow key={index} sx={{ '&:nth-child(even)': { backgroundColor: '#F1F1F1' } }}>
                <TableCell className={styles.tableDataCell}>
                  <Typography className={styles.linkText}>{item?.invoiceNumber}</Typography>
                </TableCell>

                <TableCell className={styles.tableDataCell} align="center">
                  {item?.invoiceDate}
                </TableCell>

                <TableCell className={styles.tableDataCell} align="center">
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {item?.orderNumber}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableDataCell} align="center">
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: '140%',
                    }}
                  >
                    {item?.orderDate}
                  </Typography>
                </TableCell>

                <TableCell className={styles.tableDataCell} align="center">
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: '140%',
                    }}
                  >
                    {item?.value}
                  </Typography>
                </TableCell>

                <TableCell align="center" className={styles.tableDataCell}>
                  <Typography
                    className={styles.btn}
                    sx={{ color: theme.palette.primary.main }}
                    onClick={() => {
                      setId(item?._id, item?.userId);
                      handleConfirmationModal();
                    }}
                  >
                    Settle
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InProcessStoreInvoicesTable;
