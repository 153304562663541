import client from './Client';

const CreateNewMedia = ({ type, media, fileName }) => {
  const endPoint = '/su/whatsapp/upload-media';

  const token = localStorage.getItem('token');

  const value = new FormData();
  value.append('type', type);
  value.append('media', media);
  value.append('fileName', fileName);

  return client.put(endPoint, value, {
    headers: {
      Authorization: token,
    },
  });
};

export { CreateNewMedia };
