import client from './Client';

const endPoint = '/su/refund/accept/';

const initiateRefund = ({ refundId, reason, amount, isSellerFault }) => {
  const token = localStorage.getItem('token');

  return client.put(
    endPoint + refundId,
    { reason, amount, isSellerFault },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { initiateRefund };
