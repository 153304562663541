import client from "./Client";

const endPoint = '/su/carousel/all';

const getCarousels = () => {
    const token = localStorage.getItem("token");

    return client.get(endPoint, {}, {
        headers: {
            Authorization: token
        }
    })
}

export { getCarousels }