import client from './Client';

const endPoint = '/su/category/';

const getCategoryById = (id) => {
  //   const { client } = useClient(process.env.AUTH_SERVER || '');
  const token = localStorage.getItem('token');

  return client.get(
    endPoint + id,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getCategoryById };
