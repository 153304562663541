import { Box, Stack, Typography, useTheme } from '@mui/material';
import styles from './StoreDetailStyles.module.scss';

const operationMode = {
  "Recorded Video": "Pre-Recorded Video Content",
  "Group": "Live Video: Group Sessions",
  "One on One": "Live Video: One On One Sessions",
}

const StoreGeneralInfo = ({ storeType, storeInfo }) => {
  const theme = useTheme();

  return (
    <Box className={styles.generalInfo}>
      <Box className={styles.dFlex}>
        <Typography className={styles.title}>Home Business Name</Typography>
        <Typography className={styles.value}>{storeInfo?.name}</Typography>
      </Box>

      <Box className={styles.dFlex}>
        <Typography className={styles.title}>Business Descripton</Typography>
        <Typography className={styles.value} sx={{ width: 375 }}>
          {storeInfo?.description}
        </Typography>
      </Box>

      {
        storeInfo?.foodType?.length && (
          <Box className={styles.dFlex}>
            <Typography className={styles.title}>Food Type</Typography>
            <Typography className={styles.value}>{(storeInfo?.foodType || []).join(' , ')}</Typography>
          </Box>
        )
      }

      {
        storeInfo?.categories?.length && (
          <Box className={styles.dFlex} sx={{ alignItems: 'center' }}>
            <Typography className={styles.title}>{storeType} Categories</Typography>
            <Box className={`${styles.value} ${styles.dGrid}`}>
              {(storeInfo?.categories || []).map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      color: `${theme.palette[(storeType).toLowerCase()].main} !important`,
                      border: `1px solid ${theme.palette[(storeType).toLowerCase()].main}`,
                      borderRadius: '2px',
                      padding: '5px 10px',
                      background:
                        storeType === 'Food' ? '#FFF5E0' : theme.palette[(storeType).toLowerCase()].light,
                      textAlign: "center"
                    }}
                    className={styles.value}
                  >
                    {item}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )
      }

      {storeType === 'Food' && (
        <Box className={styles.dFlex}>
          <Typography className={styles.title}>Cuisine Defined</Typography>
          <Typography className={styles.value}>{storeInfo?.cuisineDefined === true ? 'Yes' : 'No'}</Typography>
        </Box>
      )}

      {storeInfo?.cuisineDefined && (
        <Box className={styles.dFlex} sx={{ alignItems: 'center' }}>
          <Typography className={styles.title}>Selected Cuisine</Typography>
          <Typography component={'div'} className={styles.value}>
            <Box
              sx={{
                color: theme.palette[(storeType).toLowerCase()].main,
                border: `1px solid ${theme.palette[(storeType).toLowerCase()].main}`,
                borderRadius: '2px',
                padding: '5px 10px',
                background:
                  storeType === 'Food' ? '#FFF5E0' : theme.palette[(storeType).toLowerCase()].light,
                  textAlign: "center"
              }}
            >
              {storeInfo?.selectedCuisine}
            </Box>
          </Typography>
        </Box>
      )}

      {
        Boolean(storeType === "Video" && storeInfo?.modeOfOperation?.length) && (
          <Box className={styles.dFlex}>
            <Typography className={styles.title} >Mode of Operation</Typography>
            <Stack component={'div'} direction="column" gap="5px">
              {(storeInfo?.modeOfOperation || []).map((item, index) => {
                return (
                  <Typography key={index} className={styles.value}>
                    {operationMode[item]}
                  </Typography>
                );
              })}
            </Stack>
          </Box>
        )}

      {
        Boolean(storeType === "Video" && storeInfo?.contentLanguage?.length) && (
          <Box className={styles.dFlex}>
            <Typography className={styles.title}>Content Language</Typography>
            <Box className={`${styles.value} ${styles.dGrid}`}>
              {(storeInfo?.contentLanguage || []).map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      color: `${theme.palette[(storeType).toLowerCase()].main} !important`,
                      border: `1px solid ${theme.palette[(storeType).toLowerCase()].main}`,
                      borderRadius: '2px',
                      padding: '5px 10px',
                      background:
                        storeType === 'Food' ? '#FFF5E0' : theme.palette[(storeType).toLowerCase()].light,
                        textAlign: "center"
                    }}
                    className={styles.value}
                  >
                    {item?.name}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )
      }
    </Box>
  );
};

export default StoreGeneralInfo;
