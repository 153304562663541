import { Box, CardMedia, Divider, Typography } from '@mui/material';
import getS3Bucket from "../../../utils/getS3Bucket";
import styles from './StoreDetailStyles.module.scss';

const StoreMedia = ({ bannerImage, introVideo }) => {
  return (
    <Box className={styles.mediaContainer}>
      {bannerImage && (
        <Box className={styles.content}>
          <Typography className={styles.title}>Banner Image</Typography>
          <CardMedia
            component={'img'}
            image={getS3Bucket() && bannerImage ? getS3Bucket() + bannerImage : ''}
            sx={{ width: 650, height: 200, borderRadius: '5px' }}
          />
        </Box>
      )}

      {introVideo && (
        <>
          {(introVideo && bannerImage) && <Divider className={styles.divider} />}
          <Box className={styles.content}>
            <Typography className={styles.title}>Introduction Video </Typography>
            <CardMedia
              component={'video'}
              image={getS3Bucket() && introVideo ? getS3Bucket() + introVideo : ''}
              controls
              autoPlay
              sx={{ width: 450, height: 200, borderRadius: '5px' }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default StoreMedia;
