import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Box, CardMedia, Divider, IconButton, Modal, Slide, Stack, Typography, useTheme, Button } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import getS3Bucket from "../../utils/getS3Bucket";

import FoodOrderCard from '../Custom/FoodOrderCard/FoodOrderCard';
import ProductOrderCard from '../Custom/ProductOrderCard/ProductOrderCard';
import { getOrder } from '../../api/GetOrder';
import styles from './OrdersInfo.module.scss';
import VideoOrderCard from '../Custom/VideoOrderCard/VideoOrderCard';

const statusColor = {
  Received: '#525252',
  'In Process': '#525252',
  Fulfilled: '#00CB20',
  Rejected: '#DA1A18',
};

const OrdersInfoModal = ({ isOpen, handleOrdersModal, id, type }) => {
  const theme = useTheme();

  const { data, isLoading } = useQuery(['getOrder', id], () => getOrder({ id, type }), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id) && Boolean(type),
  });
  const orderDetail = data?.data?.data;

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide direction="up" in={isOpen}>
        <Box className={styles.modalBody}>
          <IconButton onClick={() => handleOrdersModal()} className={styles.closeBtn}>
            <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
          </IconButton>
          {data?.data?.success &&
            (orderDetail || []).map((item, index) => {
              return (
                <Box key={index}>
                  <Box className={styles.orderHeader}>
                    <Stack direction="row" alignItems="center" gap="15px">
                      <Box
                        className={styles.circle}
                        sx={{ background: theme.palette[type.toLocaleLowerCase()].main }}
                      />
                      <Typography className={styles.orderno}>Order no- {item?.orderNo}</Typography>
                    </Stack>
                    <Divider
                      flexItem
                      sx={{
                        borderRight: '1px solid #CACACA',
                        marginX: '80px',
                      }}
                      orientation="vertical"
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        className={styles.orderno}
                        sx={{
                          textAlign: 'center',
                          color: statusColor[item?.orderStatus],
                        }}
                      >
                        {item?.orderStatus}
                      </Typography>
                    </Box>
                    <Divider
                      flexItem
                      sx={{
                        borderRight: '1px solid #CACACA',
                        marginX: '80px',
                      }}
                      orientation="vertical"
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        className={styles.orderno}
                        sx={{
                          textAlign: 'center',
                          color: statusColor[item?.orderStatus],
                        }}
                      >
                        Order Value - ₹{item?.orderValue}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={styles.body}>
                    <Box className={styles.orderBody}>
                      <Box className={styles.leftBody}>
                        <Box className={styles.titleContainer}>
                          <Typography className={styles.title}>Order Details</Typography>
                        </Box>
                        <Box className={styles.orderDetail}>
                          <Box
                            sx={{
                              borderBottom: '1px solid #cacaca',
                              marginX: '20px',
                              paddingBottom: '10px',
                            }}
                          >
                            {type === 'Food' && <FoodOrderCard listOfItems={item?.orderDetails?.foodItems} />}
                            {type === 'Product' && (
                              <ProductOrderCard listOfProductItems={item?.orderDetails?.productItems} />
                            )}
                            {type === 'Video' && <VideoOrderCard videoItems={item?.orderDetails?.videoItems} />}
                          </Box>
                          <Box className={styles.bill}>
                            <Stack direction="column" gap="5px">
                              {Boolean(item?.orderDetails?.sellerAmount) && (
                                <Stack direction="row" justifyContent="space-between">
                                  <Typography className={styles.subTitle}>Seller Amount</Typography>
                                  <Typography className={styles.value}>
                                    ₹ {item?.orderDetails?.sellerAmount?.toFixed(2)}
                                  </Typography>
                                </Stack>
                              )}
                              {Boolean(item?.orderDetails?.gst) && (
                                <Stack direction="row" justifyContent="space-between">
                                  <Typography className={styles.subTitle}>GST</Typography>
                                  <Typography className={styles.value}>
                                    ₹ {item?.orderDetails?.gst?.toFixed(2)}
                                  </Typography>
                                </Stack>
                              )}
                              {Boolean(item?.orderDetails?.deliveryCharge) && (
                                <Stack direction="row" justifyContent="space-between">
                                  <Typography className={styles.subTitle}>Delivery Charges</Typography>
                                  <Typography className={styles.value}>
                                    ₹ {item?.orderDetails?.deliveryCharge?.toFixed(2)}
                                  </Typography>
                                </Stack>
                              )}
                              {Boolean(item?.orderDetails?.discount) && (
                                <Stack direction="row" justifyContent="space-between">
                                  <Typography className={styles.subTitle}>Discount</Typography>
                                  <Typography className={styles.value}>
                                    ₹ {item?.orderDetails?.discount?.toFixed(2)}
                                  </Typography>
                                </Stack>
                              )}
                              {Boolean(item?.orderDetails?.margin) && (
                                <Stack direction="row" justifyContent="space-between">
                                  <Typography className={styles.subTitle}>Margin</Typography>
                                  <Typography className={styles.value}>
                                    ₹ {item?.orderDetails?.margin?.toFixed(2)}
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Box>
                        </Box>
                      </Box>

                      <Box className={styles.rightBody}>
                        <Box className={styles.titleContainer} sx={{ borderTopRightRadius: '10px' }}>
                          <Typography className={styles.title}>Customer Details</Typography>
                        </Box>
                        <Stack direction="column" justifyContent="space-between">
                          <Box className={styles.customerData}>
                            <Stack direction="row" gap="20px">
                              <Box
                                className={item?.customerDetails?.businessCount ? styles.cntCircle : styles.simpleImage}
                                sx={{
                                  background: theme.palette.primary.main,
                                }}
                              >
                                <CardMedia
                                  component={'img'}
                                  image={
                                    getS3Bucket() && item?.customerDetails?.profilePic
                                      ? getS3Bucket() + item?.customerDetails?.profilePic
                                      : ''
                                  }
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                  }}
                                  objectFit="contain"
                                />

                                {Boolean(
                                  item?.customerDetails?.businessCount || item?.customerDetails?.businessCount !== 0
                                ) && (
                                  <Typography
                                    component={'div'}
                                    className={styles.messageCnt}
                                    sx={{
                                      background: theme.palette.primary.main,
                                    }}
                                  >
                                    {item?.customerDetails?.businessCount}
                                  </Typography>
                                )}
                              </Box>
                              <Typography
                                className={styles.userDetail}
                                sx={{
                                  fontWeight: '500 !important',
                                  color: '#3E3E3E !important',
                                }}
                              >
                                {item?.customerDetails?.name}
                              </Typography>
                            </Stack>
                            <Typography className={styles.userDetail}>
                              {`
                                ${
                                  item?.customerDetails?.address?.address1
                                    ? `${item?.customerDetails?.address?.address1},`
                                    : ''
                                }
                                ${
                                  item?.customerDetails?.address?.address2
                                    ? `${item?.customerDetails?.address?.address2},`
                                    : ''
                                }${
                                item?.customerDetails?.address?.lanmark
                                  ? `${item?.customerDetails?.address?.lanmark},`
                                  : ''
                              }${
                                item?.customerDetails?.address?.area ? `${item?.customerDetails?.address?.area},` : ''
                              }${
                                item?.customerDetails?.address?.city ? `${item?.customerDetails?.address?.city},` : ''
                              }${
                                item?.customerDetails?.address?.state ? `${item?.customerDetails?.address?.state},` : ''
                              }${
                                item?.customerDetails?.address?.pincode
                                  ? `${item?.customerDetails?.address?.pincode},`
                                  : ''
                              }
                             `}
                            </Typography>
                            {Boolean(item?.customerDetails?.mobile) && (
                              <Stack direction="row" gap="10px">
                                <Typography className={styles.userDetail}>Mob. No.</Typography>
                                <Typography className={styles.userDetail} sx={{ fontWeight: 500 }}>
                                  {item?.customerDetails?.mobile}
                                </Typography>
                              </Stack>
                            )}
                            {Boolean(item?.customerDetails?.byzcode) && (
                              <Stack direction="row" gap="10px">
                                <Typography className={styles.userDetail}>ByzCode </Typography>
                                <Typography
                                  className={styles.userDetail}
                                  sx={{
                                    color: `${theme.palette.primary.main} !important`,
                                  }}
                                >
                                  {item?.customerDetails?.byzcode}
                                </Typography>
                              </Stack>
                            )}
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    <Box className={styles.totalBox}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        width={502}
                        paddingX={'20px'}
                        paddingY={'10px'}
                        borderRight={'1px solid #cacaca'}
                      >
                        <Typography className={styles.buyer}>Buyer Amount</Typography>
                        <Typography className={styles.buyer}>₹ {item?.orderValue?.toFixed(2)}</Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="end" width={454} paddingX={'20px'} paddingY={'10px'}>
                        <Button
                          sx={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            fontSize: 12,
                            fontWeight: 400,
                            lineHeight: '18px',
                            borderRadius: '3px',
                            color: '#5E5E5E',
                          }}
                        >
                          View Order History
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Slide>
    </Modal>
  );
};

export default OrdersInfoModal;
