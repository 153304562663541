import client from "./Client";

const endPoint = '/su/user/';

const getUserDetails = (id, role) => {
    const token = localStorage.getItem("token");

    return client.get(
        endPoint + id,
        {
            role
        },
        {
            headers: {
                Authorization: token
            }
        }
    )
}

export { getUserDetails }