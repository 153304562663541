import client from "./Client";

const updateSubCateory = ({ subCatId, catId, name, hsnCode,typeOfStore }) => {

    const token = localStorage.getItem("token");

    const endPoint = `/su/category/${catId}/sub-category/${subCatId}`;

    return client.put(endPoint, { name, hsnCode,typeOfStore }, {
        headers: {
            Authorization: token,
        },
    });
}

export { updateSubCateory }