import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as UnderConstructionIcon } from '../../assets/images/underConstruction.svg';

const UnderConstruction = () => {
  return (
    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <UnderConstructionIcon />
    </Box>
  );
};

export default UnderConstruction;
