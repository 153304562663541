import {
  Box,
  CardMedia,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import getS3Bucket from '../../../utils/getS3Bucket';
import styles from './InventoryTable.module.scss';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eyeIcon.svg';
import { ReactComponent as Unlocked } from '../../../assets/icons/unlocked.svg';
import { ReactComponent as Locked } from '../../../assets/icons/locked.svg';

const Quantity = {
  'Pack of': 'Pack of',
  g: 'gm',
  kg: 'kg',
  mL: 'ml',
  L: 'litres',
  inch: 'Inches',
  cm: 'cm',
  m: 'm',
  'Full Portion': 'Full Portion',
  'Half Portion': 'Half Portion',
  'Per Piece': 'Per Piece',
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
};


const InventoryTable = ({ inventoryData, setId, openModal, type, videoType }) => {
  const getPriceRange = (priceArray) => {
    const min = Math.min(...priceArray);
    const max = Math.max(...priceArray);

    if (min === max) {
      return min;
    }
    return `${min} - ${max}`;
  };

  const setSessionType = (typeOfListing) => {
    if (!typeOfListing) {
      typeOfListing = 'Course';
    }

    if (typeOfListing === 'Independent Classes') {
      typeOfListing = 'Classes';
    }

    return `Multiple Sessions - ${typeOfListing}`;
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ borderSpacing: '0 20px' }}>
          <TableHead>
            <TableRow>
              <TableCell width="46px" align="center" className={styles.tableCell} sx={{ paddingLeft: '0 !important' }}>
                No.
              </TableCell>

              <TableCell width="80px" className={styles.tableCell}>
                Image
              </TableCell>

              <TableCell width="202px" className={styles.tableCell}>
                Title
              </TableCell>

              <TableCell width="293px" className={styles.tableCell}>
                Description
              </TableCell>

              <TableCell width="194px" className={styles.tableCell}>
                {type !== 'Video' ? 'Price ₹' : 'Type'}
              </TableCell>
              {type !== 'Video' && (
                <TableCell width="84px" align="center" className={styles.tableCell}>
                  Visible
                </TableCell>
              )}

              {videoType === 'Recorded Video' && (
                <TableCell width="84px" align="center" className={styles.tableCell}>
                  Access
                </TableCell>
              )}

              <TableCell width="61px" align="center" className={styles.tableCell}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(inventoryData || []).map((item, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    borderBottom: '1px solid #cacaca',
                    ':last-child': { borderBottom: '0 !important' },
                  }}
                >
                  <TableCell className={styles.tableDataCell}>{index + 1}</TableCell>

                  <TableCell align="center" className={styles.tableDataCell}>
                    <CardMedia
                      component={'img'}
                      image={
                        getS3Bucket() && item?.image
                          ? getS3Bucket() + item?.image
                          : ''
                      }
                      className={styles.image}
                    />
                  </TableCell>

                  <TableCell className={styles.tableDataCell}>{item?.name}</TableCell>

                  <TableCell className={`${styles.tableDataCell} ${styles.description}`}>{item?.description}</TableCell>

                  {type !== 'Video' && (
                    <TableCell className={styles.tableDataCell}>
                      {type === 'Food'
                        ? (item?.price || []).map((ite, ind) => {
                          return (
                            <Stack key={ind} direction="row" gap="10px" alignItems="center">
                              <Typography className={styles.price}>{ite?.price}</Typography>

                              {ite?.unit === 'Pack of' && (
                                <Typography className={styles.value}>
                                  {`Pack of (${ite?.value ? ite?.value : ''})`}
                                </Typography>
                              )}
                              {ite?.unit !== 'Pack of' && (
                                <Typography className={styles.value}>
                                  {`(${ite?.value ? ite?.value : ''} ${Quantity[ite?.unit]})`}
                                </Typography>
                              )}
                            </Stack>
                          );
                        })
                        : getPriceRange(item?.price)}
                    </TableCell>
                  )}
                  {type === 'Video' && (
                    <TableCell className={styles.tableDataCell}>
                      <Box className={styles.typeOfSession}>
                        {item?.session === 'Multiple' ? setSessionType(item?.typeOfListing) : 'Single Session'}
                      </Box>
                    </TableCell>
                  )}

                  {type !== 'Video' && (
                    <TableCell align="center" className={styles.tableDataCell}>
                      {item?.visible ? 'Yes' : 'No'}
                    </TableCell>
                  )}

                  {videoType === 'Recorded Video' && (
                    <TableCell align="center" className={styles.tableDataCell}>
                      {item?.access === 'Locked' ? <Locked /> : <Unlocked />}
                    </TableCell>
                  )}

                  <TableCell align="center" className={styles.tableDataCell}>
                    <IconButton
                      className={styles.icon}
                      onClick={() => {
                        setId(type !== 'Video' ? item?.groupId : item?._id);
                        openModal();
                      }}
                    >
                      <EyeIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default InventoryTable;
