import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import styles from './StoreInvoice.module.scss';

const SettledStoreInvoicesTable = ({ settledInvoices }) => {
  const theme = useTheme();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ background: '#F5F4F4' }}>
              <TableCell align="left" width="250px" className={styles.tableCell}>
                Invoice Number
              </TableCell>
              <TableCell align="center" width="190px" className={styles.tableCell}>
                Invoice Date
              </TableCell>
              <TableCell align="center" width="190px" className={styles.tableCell}>
                Order Number
              </TableCell>
              <TableCell align="center" width="190px" className={styles.tableCell}>
                Order Date
              </TableCell>
              <TableCell align="right" width="190px" className={styles.tableCell} sx={{ borderRight: '0 !important' }}>
                Value ₹
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(settledInvoices || []).map((item, index) => {
              return (
                <TableRow key={index} className={styles.tableRow}>
                  <TableCell className={styles.tableDataCell}>
                    <Typography className={styles.linkText}>{item?.invoiceNumber}</Typography>
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    {item?.invoiceDate}
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    <Typography
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      {item?.orderNumber}
                    </Typography>
                  </TableCell>

                  <TableCell className={styles.tableDataCell} align="center">
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '140%',
                      }}
                    >
                      {item?.orderDate}
                    </Typography>
                  </TableCell>

                  <TableCell align="right" className={styles.tableDataCell}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: '140%',
                      }}
                    >
                      {item?.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SettledStoreInvoicesTable;
