import client from "./Client";

const endPoint = '/su/faq/headings';

const getAllFaqs = () => {
    const token = localStorage.getItem("token");
    return client.get(endPoint, {}, {
        headers: {
            Authorization: token
        }
    }
    )
}

export { getAllFaqs }
