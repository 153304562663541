import client from './Client';

const endPoint = '/su/refund/order/';

const getOrderDetails = ({ orderId, type, videoType }) => {
  const token = localStorage.getItem('token');

  const body = { type };

  if (videoType && type === 'Video') {
    body.videoType = videoType;
  }

  return client.get(endPoint + orderId, body, {
    headers: {
      Authorization: token,
    },
  });
};

export { getOrderDetails };
