// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeadingForm_btn__s9aGH {\n  border: 1px solid;\n  border-radius: 4px;\n  width: 100px;\n  height: 36px;\n}", "",{"version":3,"sources":["webpack://./src/components/Custom/HeadingForm/HeadingForm.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AACF","sourcesContent":[".btn {\n  border: 1px solid;\n  border-radius: 4px;\n  width: 100px;\n  height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "HeadingForm_btn__s9aGH"
};
export default ___CSS_LOADER_EXPORT___;
