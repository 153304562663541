import client from './Client';

const endPoint = '/su/store/items/';

const getHeadersItem = ({ id, type, videoType }) => {
  const token = localStorage.getItem('token');

  const value = {};
  value.type = type;

  if (type === 'Video') {
    value.videoType = videoType;
  }

  return client.get(endPoint + id, value, {
    headers: {
      Authorization: token,
    },
  });
};

export { getHeadersItem };
