import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Box, Card, Container, IconButton, Stack, Typography, useTheme, TablePagination } from '@mui/material';
import useModal from '../../hooks/useModal';
import useLoader from '../../hooks/useLoader';
import Page from '../../components/Page';
import Loader from '../../components/Loader/Loader';
import InProcessInvoicesTable from '../../components/Custom/InvoicesTable/InProcessInvoiceTable';
import SettledInvoicesTable from '../../components/Custom/InvoicesTable/SettledInvoiceTable';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { getInvoices } from '../../api/GetInvoices';
import { settleTransaction } from '../../api/SettleTransaction';
import { getTotalInvoicesCount } from '../../api/GetTotalnvoicesCount';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import styles from '../../pageStyles/pageStyles.module.scss';
import CreditNotesModal from '../../components/CreditNotesModal/CreditNotesModal';
import SettleCreditNote from '../../api/SettleCreditNote';

const invoicesType = ['Invoices Under Process', 'Invoices Settled'];

const Invoices = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(invoicesType[0]);
  const [invoiceId, setInvoiceId] = useState();
  const [userId, setUserId] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const {
    confirmationModal,
    handleConfirmationModal,
    showCreditNotesModal,
    handleCreditNotesModal,
    creditNotesConfimationModal,
    handleCreditNotesConfirmationModal,
  } = useModal();
  const { loader, showLoader, hideLoader } = useLoader();
  const [creditNotes, setCreditNotes] = useState([]);
  const setId = (invoiceId, userId) => {
    setInvoiceId(invoiceId);
    setUserId(userId);
  };
  const [cnInvoiceId, setCNInvoiceId] = useState('');
  const [crNoteId, setCrNoteId] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const handleTabChange = (item) => {
    setActiveTab(item);
    setPage(1);
    setRowsPerPage(10);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  // All Invoices Data
  const { data, isLoading, refetch } = useQuery(
    ['getInvoices', activeTab, page, rowsPerPage],
    () =>
      getInvoices({
        status: activeTab === 'Invoices Under Process' ? 'Under Process' : 'Settled',
        page,
        limit: rowsPerPage,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(activeTab),
    }
  );
  const invoiceData = data?.data?.data;

  // Get Toal Invoices Count
  const { data: invoicesCount } = useQuery(
    ['getTotalInvoicesCount', activeTab],
    () => getTotalInvoicesCount({ type: activeTab === 'Invoices Under Process' ? 'Under Process' : 'Settled' }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const settleInvoiceMutation = useMutation(
    'settleTransaction',
    ({ invoiceId, userId }) => settleTransaction({ id: invoiceId, userId }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        refetch();
        hideLoader();
      },
      onError: (error) => {
        console.log('error', error);
        refetch();
      },
    }
  );

  const settleCreditNoteMutation = useMutation(
    'SettleCreditNote',
    ({ invoiceId, crNoteId, userId }) => SettleCreditNote({ invoiceId, crNoteId, userId }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
            setUserId('');
            handleCreditNotesModal();
            refetch();
          } else {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, data]);

  return (
    <Page title="Invoices">
      {!loader && (
        <Container className={styles.container}>
          <Stack direction="row" justifyContent="end" width="1000px" marginBottom="30px">
            <Box className={styles.filterBox}>
              <IconButton sx={{ padding: 0 }} disableRipple>
                <FilterIcon />
              </IconButton>
            </Box>
          </Stack>

          <Box className={styles.invoiceTypeBox}>
            {invoicesType.map((item, index) => {
              return (
                <Box className={styles.tab} key={index}>
                  <Typography
                    className={styles.tabText}
                    onClick={() => handleTabChange(item)}
                    sx={{ color: activeTab === item ? theme.palette.primary.main : 'rgba(36, 36, 36, 0.8)' }}
                  >
                    {item}
                  </Typography>
                  {activeTab === item && (
                    <Box
                      className={styles.tabIndicator}
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>

          {data?.data?.success && (
            <Card className={styles.invoiceCard}>
              {activeTab === 'Invoices Under Process' && (
                <InProcessInvoicesTable
                  inProcessInvoices={invoiceData}
                  handleConfirmationModal={handleConfirmationModal}
                  setId={setId}
                  handleCreditNotesModal={handleCreditNotesModal}
                  setCreditNotes={setCreditNotes}
                  setCNInvoiceId={setCNInvoiceId}
                  setInvoiceNumber={setInvoiceNumber}
                  setOrderNumber={setOrderNumber}
                  setUserId={setUserId}
                />
              )}
              {activeTab === 'Invoices Settled' && <SettledInvoicesTable settledInvoices={invoiceData} />}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={invoicesCount?.data?.data?.count ? invoicesCount?.data?.data?.count : 0}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </Container>
      )}

      {loader && <Loader isLoaderVisible={loader} />}
      <CreditNotesModal
        isOpen={showCreditNotesModal}
        handleCreditNotesModal={handleCreditNotesModal}
        creditNotes={creditNotes}
        handleConfirmationModal={handleCreditNotesConfirmationModal}
        setCrNoteId={setCrNoteId}
        invoiceNumber={invoiceNumber}
        orderNumber={orderNumber}
      />
      <ConfirmationModal
        isOpen={confirmationModal}
        handleConfirmationModal={handleConfirmationModal}
        modalText={'Are you sure you want to settle Invoice?'}
        onConfirm={() => {
          settleInvoiceMutation.mutateAsync({ invoiceId, userId });
          showLoader();
        }}
      />
      <ConfirmationModal
        isOpen={creditNotesConfimationModal}
        handleConfirmationModal={handleCreditNotesConfirmationModal}
        modalText={'Are you sure you want to settle Credit Note?'}
        onConfirm={() => {
          settleCreditNoteMutation.mutateAsync({ invoiceId: cnInvoiceId, crNoteId: crNoteId, userId: userId });
        }}
      />
    </Page>
  );
};

export default Invoices;
