import { useEffect } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation } from 'react-query';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FormikProvider, useFormik } from 'formik';
import { initiateRefund } from '../../api/InitiateRefund';
import styles from './InitiateRefundModalStyles.module.scss';

const isSellerDefaultOpions = [
  {
    label: 'Yes',
  },
  {
    label: 'No',
  },
];

const InitiateRefundModal = ({ isOpen, handleInitiateRefundModal, refundId, refundAmount, buyerName, refetch }) => {
  const theme = useTheme();

  const initiateRefundValidation = Yup.object({
    amount: Yup.number()
      .test('numberChcek', 'Invalid input', (value) => {
        if (value) {
          if (value > 0) {
            return true;
          }
          return false;
        }
        return false;
      })
      .test('check if > refundAmount', 'Invalid Amount', (value, context) => {
        const refundAmount = context.parent.refundAmount;
        if (value) {
          if (value > refundAmount) {
            return false;
          }
          return true;
        }
        return false;
      })
      .required('Required'),
    reason: Yup.string().min(50, 'Minimum 50 Characters'),
    isSellerFault: Yup.string().required('required'),
  });

  const initialValues = {
    refundAmount,
    amount: '',
    isSellerFault: undefined,
    refundAll: false,
    reason: '',
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      initiateRefundMutation.mutateAsync({
        refundId,
        amount: values.amount,
        reason: values.reason?.length ? values.reason : undefined,
        isSellerFault: Boolean(values.isSellerFault === 'Yes'),
      });
    },
    validationSchema: initiateRefundValidation,
  });

  const { values, errors, touched, getFieldProps, handleSubmit, handleChange, setFieldValue, resetForm } = formik;

  const initiateRefundMutation = useMutation(
    'intiateRefund',
    ({ amount, reason, refundId, isSellerFault }) => initiateRefund({ amount, reason, refundId, isSellerFault }),
    {
      onSuccess: ({ data }) => {
        if (data) {
          if (!data?.success) {
            toast.error(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          } else if (data?.success) {
            toast.success(data?.message, {
              position: 'bottom-center',
              duration: 2000,
            });
          }
        }
        resetForm();
        refetch();
        handleInitiateRefundModal();
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  useEffect(() => {
    if (values.refundAll) {
      setFieldValue('amount', refundAmount);
    } else {
      setFieldValue('amount', '');
    }
  }, [values.refundAll]);

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <IconButton className={styles.closeBtn} onClick={() => handleInitiateRefundModal()}>
            <ClearOutlinedIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
          </IconButton>
          <Typography className={styles.modalTitle}>Initiate Refund</Typography>
          <Box className={styles.contentContainer}>
            <Box>
              <Typography className={styles.label}>Receiver</Typography>
              <Typography className={styles.value}>{buyerName}</Typography>
            </Box>

            <Box>
              <Typography className={styles.label}>Sender</Typography>
              <Typography className={styles.value}>ByzWiz Infratech</Typography>
            </Box>

            <FormikProvider value={formik}>
              <Box>
                <Typography className={styles.label}>Is the Seller Responsible for Defaulter?</Typography>
                <RadioGroup name="isSellerFault" onChange={handleChange} disableRipple>
                  <Box className={styles.dFlex}>
                    {(isSellerDefaultOpions || []).map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item.label}
                          control={<Radio className={styles.radio} disableRipple />}
                          label={<Typography className={styles.value}>{item?.label}</Typography>}
                        />
                      );
                    })}
                  </Box>
                </RadioGroup>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={styles.label}>Refund Amount</Typography>
                <TextField
                  fullWidth
                  type="number"
                  focused={Boolean(values.amount)}
                  placeholder="Enter Amount"
                  {...getFieldProps('amount')}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                  className={styles.input}
                  sx={{
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: 0,
                    },
                    '.css-14tu57j-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '5px',
                    },
                  }}
                />
                <Box className={styles.checkboxContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox {...getFieldProps('refundAll')} className={styles.checkBox} onChange={handleChange} />
                    }
                    label={
                      <Typography className={styles.labelText}>{`Total Refund Amount : ${refundAmount}`}</Typography>
                    }
                  />
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={styles.label}>Refund Reason</Typography>

                <TextField
                  fullWidth
                  focused={Boolean(values.reason)}
                  {...getFieldProps('reason')}
                  placeholder="Describe Reason"
                  error={Boolean(touched.reason && errors.reason)}
                  helperText={touched.reason && errors.reason}
                  sx={{
                    borderRadius: '5px',
                    '.css-zrvlb7-MuiInputBase-root-MuiOutlinedInput-root': {
                      padding: '10px 0 20px 10px',
                      width: '100%',
                      minHeight: 100,
                      maxHeight: 100,
                      alignItems: 'flex-start',
                      fontSize: 14,
                      fontWeight: 400,
                      color: '#242424',
                      textArea: {
                        height: 90,
                        '&::-webkit-scrollbar': {
                          width: 5,
                        },

                        '&::-webkit-scrollbar-thumb': {
                          background: theme.palette.primary.main,
                        },
                      },
                      borderRadius: '5px',
                    },
                    '.css-16d5wub-MuiFormHelperText-root.Mui-error': {
                      marginLeft: 0,
                    },
                  }}
                  multiline
                  inputProps={{
                    maxLength: 400,
                  }}
                  maxRows={4}
                />
              </Box>
            </FormikProvider>

            <Button
              className={styles.sbtn}
              sx={{
                background: theme.palette.primary.main,
                color: '#FFF',
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
              }}
              disabled={Object.keys(errors)?.length}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default InitiateRefundModal;
