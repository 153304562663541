import client from './Client';

const endPoint = '/su/invite/approve/';

const inviteAction = ({ id, isApproved }) => {
  const token = localStorage.getItem('token');

  return client.post(
    endPoint + id,
    { isApproved },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { inviteAction };
