import client from './Client';

const getGst = ({ catId, subCatId }) => {

  const endPoint = `/su/category/${catId}/sub-category/${subCatId}/gst`;

  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getGst };
