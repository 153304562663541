import client from './Client';


const updateGst = ({ catId, subCatId, gstSlots }) => {

  const endPoint = `/su/category/${catId}/sub-category/${subCatId}/gst/update`;

  const token = localStorage.getItem('token');
  return client.put(
    endPoint,
    {
      gstSlots,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { updateGst };
