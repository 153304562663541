import client from './Client';

const endPoint = '/su/category/margin';

const updateMargin = ({ val, type, id }) => {
  const token = localStorage.getItem('token');
  return client.put(
    endPoint,
    {
      type,
      margin: {
        value: val,
      },
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { updateMargin };
