import { Box, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import styles from "./ProductOrderCard.module.scss"

const ProductOrderCard = ({ listOfProductItems }) => {
    return (
        <>
            <TableContainer sx={{
                border: 0
            }}>
                <Table sx={{ border: 0 }}>

                    <TableHead>
                        <TableRow>
                            <TableCell className={styles.th} width={298}>Item Name</TableCell>
                            <TableCell className={styles.th} width={95}>Qty</TableCell>
                            <TableCell className={styles.th} align="right">Amount ₹</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            (listOfProductItems || []).map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell className={styles.td}>
                                            <Stack direction="row" alignItems="flex-start">
                                                <Box sx={{ flexBasis: item?.color?.name ? "42%" : "70%" }}>{item?.itemName}</Box>
                                                {item?.color?.name &&
                                                    <>
                                                        <Divider className={styles.divider} flexItem />
                                                        <Stack direction="row" alignItems="center" gap="6px">
                                                            <Box
                                                                sx={{
                                                                    width: 12,
                                                                    height: 12,
                                                                    backgroundColor: item?.color?.hex,
                                                                    borderRadius: "2px"
                                                                }}
                                                            />
                                                            <Typography className={styles.value}>{item?.color?.name}</Typography>
                                                        </Stack>
                                                    </>
                                                }
                                                {item?.size &&
                                                    <>
                                                        <Divider className={styles.divider} flexItem />
                                                        <Typography className={styles.value}>{item?.size}</Typography>
                                                    </>
                                                }
                                            </Stack>
                                        </TableCell>
                                        <TableCell className={`${styles.td} ${styles.value}`} sx={{ paddingLeft: "10px !important" }}>{item?.quantity}</TableCell>
                                        <TableCell className={`${styles.td} ${styles.value}`} align="right">{item?.amount}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>

                </Table>
            </TableContainer >
        </>
    )
}

export default ProductOrderCard;