import client from './Client';

const endPoint = '/su/category/gst/total';

const getTotalGstCount = ({ type }) => {
  const token = localStorage.getItem('token');

  return client.get(
    endPoint,
    {
      type,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getTotalGstCount };
