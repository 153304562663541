import { Box, Typography } from '@mui/material';
import styles from './Info.module.scss';

const validRupeeLabels = ['Average Spend', 'GMV', 'Revenue', 'Seller Payout', 'Logistics Payout', 'GST Payout', 'Invoices Total'];

const Info = ({ label, count, percentageAndAverage }) => {
  const showRupee = (label) => {
    if (validRupeeLabels.includes(label)) return '₹ ';
  };

  return (
    <Box className={styles.infoBox}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={styles.label}>{label}</Typography>
        {label === 'Businesses' && <Typography className={styles.label}>Avg.</Typography>}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={styles.count}>
          {showRupee(label)}
          {count}
        </Typography>
        {percentageAndAverage && (
          <Typography className={styles.count}>
            {percentageAndAverage === 'NaN' ? '--' : percentageAndAverage}
            {label === 'Sellers' && ' %'}
            {label === 'Buyers' && ' %'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export { Info };
