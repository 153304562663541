import client from "./Client";

const endPoint = '/su/faq/question/add/';

const addQuestion = (value, headerId) => {
    const token = localStorage.getItem("token");
    return client.put(endPoint + headerId, value, {
        headers: {
            Authorization: token
        }
    })
}

export { addQuestion }