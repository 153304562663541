import { useQuery } from 'react-query';
import {
  Box,
  CardMedia,
  IconButton,
  Modal,
  Slide,
  Typography,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { ClearOutlined } from '@mui/icons-material';
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import moment from 'moment';
import getS3Bucket from "src/utils/getS3Bucket";

import styles from './OrderDetailModal.module.scss';
import { getOrderDetails } from '../../api/OrderDetails';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as VegIcon } from '../../assets/icons/vegIcon.svg';
import { ReactComponent as NonVegIcon } from '../../assets/icons/nonVegSelectedIcon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/downArrow.svg';

const Quantity = {
  'Pack of': 'Pack of',
  g: 'gm',
  kg: 'kg',
  mL: 'ml',
  L: 'litres',
  inch: 'Inches',
  cm: 'cm',
  m: 'm',
  'Full Portion': 'Full Portion',
  'Half Portion': 'Half Portion',
  'Per Piece': 'Per Piece',
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
};

const OrderDetailModal = ({ isOpen, handleOrderDetailsModal, orderId, type, videoType }) => {
  const theme = useTheme();

  const { data, isLoading } = useQuery(
    ['getOrderDetails', orderId],
    () => getOrderDetails({ orderId, type, videoType }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(orderId && type),
    }
  );

  const orderDetails = data?.data?.data;

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      className={styles.mainContainer}
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
    >
      <Slide in={isOpen} direction="up">
        <Box className={styles.modalBody}>
          <IconButton className={styles.closeBtn} onClick={() => handleOrderDetailsModal()}>
            <ClearOutlined sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
          </IconButton>
          <Typography className={styles.modalTitle}>Order Details </Typography>

          <Box className={styles.mainContentBox}>
            {/* StoreInfo Box */}
            <Box className={styles.storeInfoBox}>
              <Typography className={styles.orderFromText}>Ordered From</Typography>
              <Typography className={styles.storeNameText}>{orderDetails?.storeId?.name}</Typography>
              <Box className={styles.addressBox}>
                <LocationIcon />
                <Typography className={styles.addressText}>
                  {`${orderDetails?.storeId?.address?.area},${orderDetails?.storeId?.address?.city}`}
                </Typography>
              </Box>
            </Box>

            {/* List Of Items */}
            {Boolean(type === 'Food' && orderDetails?.listOfFoodItems?.length) && (
              <Box className={styles.listOfItems}>
                {(orderDetails?.listOfFoodItems || []).map((item, index) => {
                  return (
                    <Box
                      className={styles.foodItemsSection}
                      sx={{
                        borderBottom:
                          orderDetails?.listOfFoodItems?.length - 1 !== index
                            ? '1px dashed #cacaca'
                            : '1px solid #cacaca',

                        margin: orderDetails?.listOfFoodItems?.length - 1 !== index ? '20px 0' : '20px 0 0 0',
                      }}
                      key={index}
                    >
                      {/* Image and Item Name */}
                      <Box className={styles.imageAndNameBox}>
                        <Swiper
                          className={styles.swiper}
                          modules={[Pagination, Autoplay]}
                          autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                          pagination={{
                            clickable: true,
                          }}
                        >
                          {(item?.itemId?.images || []).map((img, ind) => {
                            return (
                              <SwiperSlide key={ind}>
                                <CardMedia
                                  component={'img'}
                                  image={
                                    getS3Bucket() && img ? getS3Bucket() + img : ''
                                  }
                                  className={styles.itemImages}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>

                        <Typography className={styles.itemName}>{item?.itemName}</Typography>
                      </Box>

                      {/* Quantity & Price  */}
                      <Box className={styles.quantityAndPriceBox}>
                        <Box className={styles.dGrid}>
                          <Typography className={styles.label}>Quantity</Typography>
                          <Typography className={styles.label}>Unit</Typography>
                          <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                            Price ₹
                          </Typography>
                        </Box>
                        <Box className={styles.dGrid}>
                          <Typography className={styles.value}>{item?.quantity}</Typography>
                          <Typography className={styles.value}>
                            {`${item?.itemId?.weightAttribute?.packageWeight?.value} ${Quantity[item?.itemId?.weightAttribute?.packageWeight?.unit]
                              }`}
                          </Typography>
                          <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                            {item?.price?.toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>

                      {/* Customization */}
                      {Boolean(item?.customization?.length) && (
                        <Box className={styles.customizationBox}>
                          <Typography className={styles.customizationText}>Customization</Typography>

                          <Box className={styles.showColFlex}>
                            {(item?.customization || []).map((cust, cInd) => {
                              return (
                                <Box className={styles.colFlex} key={cInd}>
                                  <Typography className={styles.label}>{cust?.title}</Typography>
                                  {(cust.listOfItems || []).map((x, i) => {
                                    return (
                                      <Box className={styles.spaceBetween} key={i}>
                                        <Box className={styles.dFlex}>
                                          {x.type === 'Veg' ? <VegIcon /> : <NonVegIcon />}
                                          <Typography className={styles.cText}>{x.name}</Typography>
                                        </Box>
                                        <Typography className={styles.cText}>
                                          {x?.price ? `₹ ${x.price?.toFixed(2)}` : ''}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}

            {Boolean(type === 'Product' && orderDetails?.listOfProductItems?.length) && (
              <Box className={styles.listOfItems}>
                {(orderDetails?.listOfProductItems || []).map((item, index) => {
                  return (
                    <Box
                      className={styles.foodItemsSection}
                      sx={{
                        borderBottom:
                          orderDetails?.listOfProductItems?.length - 1 !== index
                            ? '1px dashed #cacaca'
                            : '1px solid #cacaca',

                        margin: orderDetails?.listOfProductItems?.length - 1 !== index ? '20px 0' : '20px 0 0 0',
                      }}
                      key={index}
                    >
                      <Box className={styles.imageAndNameBox}>
                        <Swiper
                          className={styles.swiper}
                          modules={[Pagination, Autoplay]}
                          autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                          pagination={{
                            clickable: true,
                          }}
                        >
                          {(item?.itemId?.images || []).map((img, ind) => {
                            return (
                              <SwiperSlide key={ind}>
                                <CardMedia
                                  component={'img'}
                                  image={
                                    getS3Bucket() && img ? getS3Bucket() + img : ''
                                  }
                                  className={styles.itemImages}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>

                        <Typography className={styles.itemName}>{item?.itemName}</Typography>
                      </Box>

                      {/* If Attribute is there with SIZE & COLOR */}
                      {Boolean(item?.itemId?.attribute?.color && item?.itemId?.attribute?.size) && (
                        <Box className={styles.quantityAndPriceBox}>
                          <Box className={styles.dGridProduct}>
                            <Typography className={styles.label}>Quantity</Typography>
                            <Typography className={styles.label}>Size</Typography>
                            <Typography className={styles.label}>Color</Typography>
                            <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                              Price ₹
                            </Typography>
                          </Box>
                          <Box className={styles.dGridProduct}>
                            <Typography className={styles.value}>{item?.quantity}</Typography>
                            <Typography className={styles.value}>
                              {item?.itemId?.attribute?.size?.name && <>{item?.itemId?.attribute?.size?.name}</>}
                              {item?.itemId?.attribute?.size.value && <>{item?.itemId?.attribute?.size.value}</>}
                              {item?.itemId?.attribute?.size?.weight && (
                                <>
                                  {`${item?.itemId?.attribute?.size?.weight?.value} ${Quantity[item?.itemId?.weightAttribute?.itemWeight?.unit]
                                    }`}
                                </>
                              )}
                              {item?.itemId?.attribute?.size?.volume && (
                                <>
                                  {`
                                    ${item?.itemId?.attribute?.size?.volume?.value}
                                      ${Quantity[item?.itemId?.weightAttribute?.itemWeight?.unit]}
                                      `}
                                </>
                              )}
                              {item?.itemId?.attribute?.size.dimension && (
                                <>
                                  {`${item?.itemId?.attribute?.size.dimension?.length}x${item?.itemId?.attribute?.size.dimension?.breadth
                                    }${item?.itemId?.attribute?.size.dimension?.height
                                      ? `x${item?.itemId?.attribute?.size.dimension?.height}`
                                      : ''
                                    }${Quantity[item?.itemId?.weightAttribute?.itemWeight?.unit]}
                                        `}
                                </>
                              )}
                            </Typography>
                            <Box className={styles.dFlex} sx={{ gap: '5px !important' }}>
                              <Box
                                className={styles.colorBox}
                                sx={{ background: item?.itemId?.attribute?.color?.hex }}
                              />
                              <Typography className={styles.value}>{item?.itemId?.attribute?.color?.name}</Typography>
                            </Box>
                            <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                              {item?.price?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                      {/* If Only COLOR Attribute is There */}
                      {Boolean(item?.itemId?.attribute?.color && !item?.itemId?.attribute?.size) && (
                        <Box className={styles.quantityAndPriceBox}>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.label}>Quantity</Typography>
                            <Typography className={styles.label}>Color</Typography>
                            <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                              Price ₹
                            </Typography>
                          </Box>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.value}>{item?.quantity}</Typography>
                            <Box className={styles.dFlex} sx={{ gap: '5px !important' }}>
                              <Box
                                className={styles.colorBox}
                                sx={{ background: `${item?.itemId?.attribute?.color?.hex}` }}
                              />
                              <Typography className={styles.value}>{item?.itemId?.attribute?.color?.name}</Typography>
                            </Box>
                            <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                              {item?.price?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {/* If Only SIZE Attribute is There */}
                      {Boolean(item?.itemId?.attribute?.size && !item?.itemId?.attribute?.color) && (
                        <Box className={styles.quantityAndPriceBox}>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.label}>Quantity</Typography>
                            <Typography className={styles.label}>Size</Typography>
                            <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                              Price ₹
                            </Typography>
                          </Box>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.value}>{item?.quantity}</Typography>
                            <Typography className={styles.value}>
                              {item?.itemId?.attribute?.size?.name && <>{item?.itemId?.attribute?.size?.name}</>}
                              {item?.itemId?.attribute?.size.value && <>{item?.itemId?.attribute?.size.value}</>}
                              {item?.itemId?.attribute?.size?.weight && (
                                <>
                                  {`${item?.itemId?.attribute?.size?.weight?.value} ${Quantity[item?.itemId?.weightAttribute?.itemWeight?.unit]
                                    }`}
                                </>
                              )}
                              {item?.itemId?.attribute?.size?.volume && (
                                <>
                                  {`
                                    ${item?.itemId?.attribute?.size?.volume?.value}
                                      ${Quantity[item?.itemId?.weightAttribute?.itemWeight?.unit]}
                                      `}
                                </>
                              )}
                              {item?.itemId?.attribute?.size.dimension && (
                                <>
                                  {`${item?.itemId?.attribute?.size.dimension?.length}x${item?.itemId?.attribute?.size.dimension?.breadth
                                    }${item?.itemId?.attribute?.size.dimension?.height
                                      ? `x${item?.itemId?.attribute?.size.dimension?.height}`
                                      : ''
                                    }${Quantity[item?.itemId?.weightAttribute?.itemWeight?.unit]}
                                        `}
                                </>
                              )}
                            </Typography>
                            <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                              {item?.price?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                      {/* If weightAttribute is there  */}
                      {Boolean(item?.itemId?.weightAttribute) && (
                        <Box className={styles.quantityAndPriceBox}>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.label}>Quantity</Typography>
                            <Typography className={styles.label}>UOM</Typography>
                            <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                              Price ₹
                            </Typography>
                          </Box>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.value}>{item?.quantity}</Typography>
                            <Typography className={styles.value}>
                              {item?.itemId?.weightAttribute?.itemWeight?.unit === 'Pack of'
                                ? `Pack of (${item?.itemId?.weightAttribute?.itemWeight?.value})`
                                : `${item?.itemId?.weightAttribute?.itemWeight?.value
                                  ? item?.itemId?.weightAttribute?.itemWeight?.value
                                  : ''
                                }
                                      ${item?.itemId?.weightAttribute?.itemWeight?.unit
                                  ? Quantity[item?.itemId?.weightAttribute?.itemWeight?.unit]
                                  : ''
                                }`}
                            </Typography>
                            <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                              {item?.price?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                      {/* If Product is Unique */}
                      {Boolean(!item?.itemId?.attribute && !item?.itemId?.weightAttribute) && (
                        <Box className={styles.quantityAndPriceBox}>
                          <Box className={styles.spaceBetween}>
                            <Typography className={styles.label}>Quantity</Typography>
                            <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                              Price ₹
                            </Typography>
                          </Box>
                          <Box className={styles.spaceBetween}>
                            <Typography className={styles.value}>{item?.quantity}</Typography>
                            <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                              {item?.price?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}

            {Boolean(type === 'Video' && orderDetails?.listOfVideoItems?.length) && (
              <Box className={styles.listOfItems}>
                {(orderDetails?.listOfVideoItems || []).map((item, index) => {
                  return (
                    <Box
                      key={index}
                      className={styles.foodItemsSection}
                      sx={{
                        borderBottom:
                          orderDetails?.listOfVideoItems?.length - 1 !== index
                            ? '1px dashed #cacaca'
                            : '1px solid #cacaca',

                        margin: orderDetails?.listOfVideoItems?.length - 1 !== index ? '20px 0' : '20px 0 0 0',
                      }}
                    >
                      <Box className={styles.imageAndNameBox}>
                        <CardMedia
                          component={'img'}
                          image={
                            getS3Bucket() && item?.thumbnail
                              ? getS3Bucket() + item?.thumbnail
                              : ''
                          }
                          className={styles.itemImages}
                        />
                        <Typography className={styles.itemName}>{item?.itemName}</Typography>
                      </Box>

                      {item?.planDuration ? (
                        <Box className={styles.quantityAndPriceBox}>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.label}>Duration</Typography>
                            <Typography className={styles.label}>Type</Typography>
                            <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                              Price ₹
                            </Typography>
                          </Box>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.value}>{`${item?.planDuration} Months`}</Typography>
                            <Typography className={styles.value}>{'Album'}</Typography>
                            <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                              {item?.price?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box className={styles.quantityAndPriceBox}>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.label}>Total Slots</Typography>
                            <Typography className={styles.label}>Type</Typography>
                            <Typography className={styles.label} sx={{ textAlign: 'right' }}>
                              Price ₹
                            </Typography>
                          </Box>
                          <Box className={styles.dGrid}>
                            <Typography className={styles.value}>{item?.numberOfSlots}</Typography>
                            <Typography className={styles.value}>
                              {item?.typeOfSession === 'One on One' ? 'Live Video(One on One)' : 'Live Video(Group)'}
                            </Typography>
                            <Typography className={styles.value} sx={{ textAlign: 'right' }}>
                              {item?.price?.toFixed(2)}
                            </Typography>
                          </Box>
                        </Box>
                      )}

                      {Boolean(item?.slots?.length) && (
                        <Box sx={{ marginTop: '20px' }}>
                          <Accordion
                            sx={{
                              '&.MuiAccordion-root::before': {
                                content: 'none',
                              },
                              '&.MuiAccordion-root': {
                                borderRadius: 0,
                                margin: 0,
                                background: '#F9F9F9',
                                boxShadow: 'none',
                              },
                              '.css-1grcmts-MuiButtonBase-root-MuiAccordionSummary-root': {
                                padding: '0 10px',
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ArrowIcon />}
                              className={styles.accordionSummary}
                              sx={{
                                minHeight: 40,
                                maxHeight: 40,
                                '&.Mui-expanded': {
                                  minHeight: 40,
                                  outline: 'none',
                                },
                                '& .MuiAccordionSummary-content.Mui-expanded': {
                                  margin: '12px 0',
                                  outline: 'none',
                                },
                              }}
                            >
                              <Typography>View Slots</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '10px' }}>
                              <Box className={styles.slotGrid}>
                                {(item?.slots || []).map((slotItem, slotIndex) => {
                                  return (
                                    <Box
                                      key={slotIndex}
                                      className={item?.isFinished ? styles.usedSlot : styles.unUsedSlot}
                                    >
                                      <Typography className={item?.isFinished ? styles.usedDate : styles.unUsedDate}>
                                        {moment(slotItem?.date).format('ddd DD-MM-YY')}
                                      </Typography>
                                      <Typography className={item?.isFinished ? styles.usedTime : styles.unUsedTime}>
                                        {slotItem?.time}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}

            {/* Order Amount */}
            <Box>
              <Box className={styles.columnFlex}>
                <Box className={styles.spaceBetween}>
                  <Typography className={styles.label} sx={{ color: '#242424 !important' }}>
                    Item Total
                  </Typography>
                  <Typography className={styles.value}>₹ {orderDetails?.total?.toFixed(2)}</Typography>
                </Box>
                <Box className={styles.spaceBetween}>
                  <Typography className={styles.label} sx={{ color: '#242424 !important' }}>
                    Taxes
                  </Typography>
                  <Typography className={styles.value}>₹ {orderDetails?.taxes?.toFixed(2)}</Typography>
                </Box>
                <Box className={styles.spaceBetween}>
                  <Typography className={styles.label} sx={{ color: '#242424 !important' }}>
                    Convenience Fee
                  </Typography>
                  <Typography className={styles.value}>₹ {orderDetails?.convenienceFee?.toFixed(2)}</Typography>
                </Box>
              </Box>
              <Box className={styles.grandTotalBox}>
                <Typography className={styles.grandTotalText}>Grand Total</Typography>
                <Typography className={styles.grandTotalText}>₹ {orderDetails?.grandTotal?.toFixed(2)}</Typography>
              </Box>
            </Box>

            <Box className={styles.orderInfoBox}>
              <Typography className={styles.orderInfoText}>Order Information</Typography>
              <Box className={styles.colFlex}>
                <Typography className={styles.label}>Order Number</Typography>
                <Typography className={styles.value}>{orderDetails?.orderNumber}</Typography>
              </Box>
              <Box className={styles.colFlex}>
                <Typography className={styles.label}>Date</Typography>
                <Typography className={styles.value}>
                  {`${moment(orderDetails?.orderDate).format('MMM DD,YYYY')} at ${moment(
                    orderDetails?.orderDate
                  ).format('HH:MM A')}`}
                </Typography>
              </Box>
              {Boolean(orderDetails?.deliveryAddress) && (
                <Box className={styles.colFlex}>
                  <Typography className={styles.label}>Delivery Address</Typography>
                  <Typography className={styles.value}>
                    {`
                    ${orderDetails?.deliveryAddress?.address1 ? `${orderDetails?.deliveryAddress?.address1},` : ''}
                    ${orderDetails?.deliveryAddress?.address2 ? `${orderDetails?.deliveryAddress?.address2},` : ''}
                    ${orderDetails?.deliveryAddress?.landmark ? `${orderDetails?.deliveryAddress?.landmark},` : ''}
                    ${orderDetails?.deliveryAddress?.area ? `${orderDetails?.deliveryAddress?.area},` : ''}
                    ${orderDetails?.deliveryAddress?.city ? `${orderDetails?.deliveryAddress?.city},` : ''}
                    ${orderDetails?.deliveryAddress?.state ? `${orderDetails?.deliveryAddress?.state},` : ''}
                    ${orderDetails?.deliveryAddress?.pincode ? `${orderDetails?.deliveryAddress?.pincode}` : ''}
                    `}
                  </Typography>
                </Box>
              )}
              {Boolean(orderDetails?.billingAddress) && (
                <Box className={styles.colFlex}>
                  <Typography className={styles.label}>Billing Address</Typography>
                  <Typography className={styles.value}>
                    {`
                    ${orderDetails?.billingAddress?.address1 ? `${orderDetails?.billingAddress?.address1},` : ''}
                    ${orderDetails?.billingAddress?.address2 ? `${orderDetails?.billingAddress?.address2},` : ''}
                    ${orderDetails?.billingAddress?.landmark ? `${orderDetails?.billingAddress?.landmark},` : ''}
                    ${orderDetails?.billingAddress?.area ? `${orderDetails?.billingAddress?.area},` : ''}
                    ${orderDetails?.billingAddress?.city ? `${orderDetails?.billingAddress?.city},` : ''}
                    ${orderDetails?.billingAddress?.state ? `${orderDetails?.billingAddress?.state},` : ''}
                    ${orderDetails?.billingAddress?.pincode ? `${orderDetails?.billingAddress?.pincode}` : ''}
                    `}
                  </Typography>
                </Box>
              )}
              {Boolean(orderDetails?.deliveryStatus) && (
                <Box className={styles.colFlex}>
                  <Typography className={styles.label}>Delivery Status</Typography>
                  <Typography
                    className={styles.value}
                    sx={{
                      color: orderDetails?.deliveryStatus === 'Delivered' ? '#09891E !important' : '',
                      fontWeight: '500 !important',
                    }}
                  >
                    {orderDetails?.deliveryStatus}
                  </Typography>
                </Box>
              )}
              {Boolean(orderDetails?.estimatedDeliveryTime) && (
                <Box className={styles.colFlex}>
                  <Typography className={styles.label}>Expected Delivery Time</Typography>
                  <Typography className={styles.value}>{orderDetails?.estimatedDeliveryTime}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default OrderDetailModal;
