import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Card, Typography, useTheme, TablePagination } from '@mui/material';
import useLoader from '../../hooks/useLoader';
import Page from '../../components/Page';
import PayInTable from '../../components/Custom/PaymentsTable/PayInTable';
import PayOutTable from '../../components/Custom/PaymentsTable/PayOutTable';
import Loader from '../../components/Loader/Loader';
import styles from '../../pageStyles/pageStyles.module.scss';
import { getPayments } from '../../api/GetPayments';
import { getTotalPaymentsCount } from '../../api/GetTotalPaymentsCount';

const invoicesType = ['Pay In', 'Pay Out'];

const Payments = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(invoicesType[0]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { loader, showLoader, hideLoader } = useLoader();

  const handleChangeTab = (item) => {
    setActiveTab(item);
    setPage(1);
    setRowsPerPage(10);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const { data, isLoading } = useQuery(
    ['getPayemnts', activeTab, page, rowsPerPage],
    () => getPayments({ payType: activeTab === 'Pay In' ? 'payIn' : 'payOut', page, limit: rowsPerPage }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const paymentsData = data?.data?.data;

  // Get Payments Total Count
  const { data: paymentsCount, isLoading: paymentsCountLoading } = useQuery(
    ['getTotalPaymentsCount', activeTab],
    () => getTotalPaymentsCount({ type: activeTab === 'Pay In' ? 'Pay In' : 'Pay Out' }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const paymentsCountValue = paymentsCount?.data?.data;

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, data]);

  return (
    <Page title="Payments">
      {!loader && (
        <>
          <Box className={styles.invoiceTypeBox}>
            {invoicesType.map((item, index) => {
              return (
                <Box className={styles.tab} key={index}>
                  <Typography
                    className={styles.tabText}
                    onClick={() => handleChangeTab(item)}
                    sx={{ color: activeTab === item ? theme.palette.primary.main : 'rgba(36, 36, 36, 0.8)' }}
                  >
                    {item}
                  </Typography>
                  {activeTab === item && (
                    <Box
                      className={styles.tabIndicator}
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>

          {data?.data?.success && (
            <Card className={styles.invoiceCard}>
              {activeTab === 'Pay In' && <PayInTable payIn={paymentsData} />}
              {activeTab === 'Pay Out' && <PayOutTable payOut={paymentsData} />}
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={paymentsCountValue?.count ? paymentsCountValue?.count : 0}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </>
      )}
      {loader && <Loader isLoaderVisible={loader} />}
    </Page>
  );
};

export default Payments;
