import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Box,
  Card,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  useTheme,
} from '@mui/material';
import useLoader from '../../../hooks/useLoader';
import { ReactComponent as LeftArrow } from '../../../assets/icons/leftArrow.svg';
import styles from '../../../pageStyles/pageStyles.module.scss';
import Loader from '../../../components/Loader/Loader';
import Page from '../../../components/Page';
import { storeLedger } from '../../../api/StoreLedger';

const particulars = {
  Refund: 'Refund Vide Debit No.',
  'Refund Txn Cost': 'Refund Txn Cost Vide Debit No.',
  'Withdraw Charges': 'Withdrawal Charges Vide Debit No.',
  'Invoice Settled': 'Sale Vide Invoice No.',
  Withdraw: 'Withdraw Vide Payment Advise No.',
};

const Ledger = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const [storeName] = useSearchParams();
  const { loader, showLoader, hideLoader } = useLoader();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const { data, isLoading } = useQuery(
    ['storeLedger', id, page, rowsPerPage],
    () => storeLedger({ id, page, limit: rowsPerPage }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  const ledgerData = data?.data?.data;

  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, data]);

  return (
    <Page title="Ledger">
      {!loader && (
        <>
          <Box className={styles.dFlex}>
            <IconButton className={styles.icon} onClick={() => navigate(`/dashboard/invoices/${id}`)}>
              <LeftArrow />
            </IconButton>

            <Typography className={styles.linkText} onClick={() => navigate(`/dashboard/invoices/${id}`)}>
              Back
            </Typography>
          </Box>
          <Box className={styles.showFlex}>
            <Typography className={styles.storeText}>{storeName.get('storeName')}</Typography>
          </Box>

          <Card className={styles.card}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ background: '#F5F4F4' }}>
                    <TableCell width="60px" className={styles.ledgerTableHeadCell}>
                      No.
                    </TableCell>
                    <TableCell width="150px" className={styles.ledgerTableHeadCell}>
                      Date
                    </TableCell>
                    <TableCell width="490px" className={styles.ledgerTableHeadCell}>
                      Particulars
                    </TableCell>
                    <TableCell align="right" width="150px" className={styles.ledgerTableHeadCell}>
                      Debit ₹
                    </TableCell>
                    <TableCell align="right" width="150px" className={styles.ledgerTableHeadCell}>
                      Credit ₹
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(ledgerData?.transactions || []).map((item, index) => {
                    return (
                      <TableRow key={index} sx={{ '&:nth-child(even)': { backgroundColor: '#F9F9F9' } }}>
                        <TableCell className={styles.ledgerTableCell}>
                          <Typography className={styles.tableText}>{index + 1}</Typography>
                        </TableCell>

                        <TableCell className={styles.ledgerTableCell}>
                          <Typography className={styles.tableText}>{item?.date}</Typography>
                        </TableCell>

                        <TableCell className={styles.ledgerTableCell}>
                          <Typography className={styles.tableText}>
                            {particulars[item?.reason]}
                            {item?.reason === 'Invoice Settled' ? (
                              <Typography
                                component={'span'}
                                className={styles.tableText}
                                sx={{
                                  color: `${theme.palette.primary.main} !important`,
                                  padding: '20px 10px 20px 0',
                                  fontWeight: '500 !important',
                                }}
                              >
                                {item?.invoiceNumber}
                              </Typography>
                            ) : (
                              <Typography
                                component={'span'}
                                className={styles.tableText}
                                sx={{
                                  color: `${theme.palette.primary.main} !important`,
                                  padding: '20px 10px 20px 0',
                                  fontWeight: '500 !important',
                                }}
                              >
                                {item?.reason === 'Refund' && item?.orderId?.slice(0, 10)}
                                {Boolean(
                                  item?.reason === 'Refund Txn Cost' ||
                                  item?.reason === 'Withdraw Charges' ||
                                  item?.reason === 'Withdraw'
                                ) && item?._id?.slice(0, 10)}
                              </Typography>
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell align="right" className={styles.ledgerTableCell}>
                          {Boolean(item?.type !== 'Credit') && (
                            <Typography
                              className={styles.tableText}
                              sx={{
                                color: '#C11919 !important',
                                fontWeight: '500 !important',
                              }}
                            >
                              {item?.amount?.toFixed(2)}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell align="right" className={styles.ledgerTableCell}>
                          {Boolean(item?.type === 'Credit') && (
                            <Typography
                              className={styles.tableText}
                              sx={{
                                color: '#0E7A1F !important',
                                fontWeight: '500 !important',
                              }}
                            >
                              {item?.amount?.toFixed(2)}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              count={50}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <Card className={styles.invoiceCard} sx={{ padding: '15px 20px' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography className={styles.storeText} sx={{ fontWeight: '600 !important' }}>
                Current Credit Balance
              </Typography>
              <Typography className={styles.storeText} sx={{ fontWeight: '600 !important' }}>
                {`₹ ${ledgerData?.balance?.storeBalance ? ledgerData?.balance?.storeBalance?.toFixed(2) : 0}`}
              </Typography>
            </Stack>
          </Card>
        </>
      )}
      {loader && <Loader isLoaderVisible={loader} />}
    </Page>
  );
};

export default Ledger;
