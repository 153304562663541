import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Button,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  IconButton,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddGstModal from '../../components/AddGstModal/AddGstModal';
import useModal from '../../hooks/useModal';
import Page from '../../components/Page';
import { getAllGst } from '../../api/GetAllGst';
import { getTotalGstCount } from '../../api/GetTotalGstCount';
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg';
import Loader from '../../components/Loader/Loader';
import AppPagination from '../../components/AppPagination/AppPagination';
import styles from '../../pageStyles/pageStyles.module.scss';

const buttonType = ['Food', 'Product', 'Video'];

const Gst = () => {
  const theme = useTheme();

  const [categoryState, setCategoryState] = useState('Food');
  const [page, setPage] = useState(1);

  const [selectedId, setSelectedId] = useState();

  const { gstModal, handleGstModal } = useModal();

  const handlePageChange = (event, value) => {
    setSelectedId('');
    setPage(() => value);
  };

  const onClickCatBtn = (value) => {
    setCategoryState(value);
    setSelectedId('');
    setPage(1);
  };

  const expand = (index) => {
    if (selectedId === index) {
      setSelectedId('');
    } else {
      setSelectedId(index);
    }
  };

  // Get All GST
  const { data, isLoading, refetch } = useQuery(
    ['getAllGst', categoryState, page],
    () =>
      getAllGst({
        type: categoryState,
        gstExists: true,
        page,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );
  const gstData = data?.data?.data;

  // Get All subCategories Count
  const { data: gstCount } = useQuery(
    ['getTotalGstCount', categoryState],
    () => getTotalGstCount({ type: categoryState }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(categoryState),
    }
  );

  // Get

  return (
    <Page title="Gst">
      {!isLoading && (
        <Container className={styles.container}>
          <Stack direction="row" justifyContent="space-between" width="1000px" sx={{ marginBottom: '30px' }}>
            <Stack direction="row" alignItems="flex-start" gap="20px">
              {buttonType.map((item, index) => {
                return (
                  <Button
                    key={index}
                    className={styles.categoryState}
                    sx={{
                      background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                      color: categoryState === item ? 'white' : theme.palette.primary.main,
                      '&:hover': {
                        background: categoryState === item ? theme.palette[item.toLowerCase()].main : 'white',
                        boxShadow: '0px 0.920487px 5px rgba(0, 0, 0, 0.15)',
                      },
                    }}
                    onClick={() => onClickCatBtn(item)}
                  >
                    {Boolean(categoryState !== item) && (
                      <Box sx={{ background: theme.palette[item.toLowerCase()].main }} className={styles.dot} />
                    )}
                    {item}
                  </Button>
                );
              })}
            </Stack>
            <Button
              className={styles.addButton}
              sx={{
                color: theme.palette.primary.main,
                border: `1px solid ${theme.palette.primary.main}`,
                '&:hover': {
                  background: theme.palette.primary.main,
                  color: '#FFF',
                },
              }}
              onClick={() => handleGstModal()}
            >
              Add GST
            </Button>
          </Stack>

          <Box className={styles.minHeight}>
            {gstData?.length && data?.data?.success && (
              <>
                {(gstData || []).map((item, index) => (
                  <Accordion
                    key={index}
                    className={styles.accordion}
                    expanded={selectedId === index}
                    sx={{
                      background: 'transparent',
                      '&.MuiAccordion-root::before': {
                        content: 'none',
                      },
                      '&.MuiAccordion-root': {
                        borderRadius: 0,
                        margin: '0px 0px 20px 0px',
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore sx={{ color: theme.palette.primary.main, cursor: 'pointer' }} />}
                      className={styles.accordionSummary}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{
                        '&.Mui-expanded': {
                          minHeight: 0,
                          outline: 'none',
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                          margin: '12px 0',
                          outline: 'none',
                        },
                      }}
                      onClick={() => expand(index)}
                    >
                      {item?.name}
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordiondetails}>
                      <TableContainer
                        sx={{
                          width: '1000px',
                          padding: '0px 15px 20px 15px',
                        }}
                      >
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell width="450px" align="left" className={styles.tableCell}>
                                Category
                              </TableCell>

                              <TableCell width="150px" align="center" className={styles.tableCell}>
                                HSN/SAC
                              </TableCell>

                              <TableCell width="260px" align="center" className={styles.tableCell}>
                                GST
                              </TableCell>

                              <TableCell
                                width="140px"
                                align="center"
                                className={styles.tableCell}
                                sx={{ borderRight: '0 !important' }}
                              >
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(item?.subCategories || []).map((itemId, indexId) => {
                              return (
                                <TableRow key={indexId} sx={{ '&:last-child td,&:last-child th': { borderBottom: 0 } }}>
                                  <TableCell className={styles.tableDataCell} align="left">
                                    <Typography className={styles.tableText}>{itemId?.name}</Typography>
                                  </TableCell>

                                  <TableCell className={styles.tableDataCell} align="center">
                                    <Typography className={styles.tableText}> {itemId?.hsnCode}</Typography>
                                  </TableCell>

                                  <TableCell className={styles.tableDataCell} align="center">
                                    {(itemId?.gst?.gstSlots || []).map((ite, ind) => {
                                      return (
                                        <Stack
                                          key={ind}
                                          direction="row"
                                          gap="15px"
                                          justifyContent="center"
                                          marginTop="5px"
                                        >
                                          <Typography className={styles.tableText}>
                                            {ite?.priceRange?.maximum
                                              ? `${ite?.priceRange?.minimum} - ${ite?.priceRange?.maximum}`
                                              : `	≥ ${ite?.priceRange?.minimum}`}
                                          </Typography>
                                          <Typography className={styles.tableText}>{ite?.value}%</Typography>
                                        </Stack>
                                      );
                                    })}
                                  </TableCell>

                                  <TableCell
                                    className={styles.tableDataCell}
                                    align="center"
                                    sx={{ borderRight: '0 !important' }}
                                  >
                                    <MenuButton
                                      catId={item?._id}
                                      subCatId={itemId?._id}
                                      handleGstModal={handleGstModal}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            )}
          </Box>
        </Container>
      )}

      <AppPagination handlePageChange={handlePageChange} totalItem={gstCount?.data?.data?.count} page={page} />

      {isLoading && <Loader isLoaderVisible />}

      <AddGstModal isOpen={gstModal} handleGstModal={handleGstModal} refetchData={refetch} />
    </Page>
  );
};

const MenuButton = ({ catId, subCatId, handleGstModal }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const onClickEdit = () => {
    if (params?.get('env')) {
      navigate({
        pathname: '/dashboard/gst',
        search: `?env=Prod&catId=${catId}&subCatId=${subCatId}`,
      });
    } else {
      navigate({
        pathname: '/dashboard/gst',
        search: `?catId=${catId}&subCatId=${subCatId}`,
      });
    }
    handleGstModal();
  };

  return (
    <Box>
      <IconButton onClick={onClickEdit} sx={{ padding: '0 !important' }}>
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default Gst;
