import React from 'react';
import { useQuery } from 'react-query';
import {
  Stack,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  IconButton,
  Card,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useModal from '../../hooks/useModal';
import Page from '../../components/Page';
import AddMarginModal from '../../components/AddMarginModal/AddMarginModal';
import { getAllMargin } from '../../api/GetAllMargin';
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg';
import Loader from '../../components/Loader/Loader';
import styles from '../../pageStyles/pageStyles.module.scss';

const Margin = () => {
  const theme = useTheme();
  const { marginModal, handleMarginModal } = useModal();

  const {
    data: marginData,
    isLoading,
    refetch,
  } = useQuery('getAllMargin', () => getAllMargin(), {
    refetchOnWindowFocus: false,
  });

  return (
    <Page title="Margin">
      {!isLoading && (
        <>
          <Stack direction="row" alignItems="center" gap="830px" marginBottom="15px">
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                lineHeight: '37px',
                textTransform: 'capitalize',
                color: '#000',
              }}
            >
              Margin
            </Typography>
          </Stack>

          {marginData?.data && marginData?.data.success && (
            <Card className={styles.card}>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.09)',
                  borderRadius: '5px',
                  width: 'fit-content',
                }}
              >
                <Table sx={{ width: 'fit-content' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        width="824px"
                        className={styles.tableCell}
                        align="left"
                        sx={{ paddingLeft: '20px !important' }}
                      >
                        Type
                      </TableCell>

                      <TableCell width="88px" className={styles.tableCell} align="center">
                        Value
                      </TableCell>

                      <TableCell
                        width="126px"
                        className={styles.tableCell}
                        align="center"
                        sx={{ borderRight: '0 !important' }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(marginData?.data.data || []).map((item, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
                        <TableCell
                          sx={{
                            border: '1px solid #CACACA',
                            borderLeft: 0,
                            borderRight: 0,
                          }}
                          align="left"
                        >
                          <Stack direction="row" alignItems="center" gap="16px">
                            <Box
                              sx={{
                                width: 11,
                                height: 11,
                                borderRadius: '50%',
                                background: theme.palette[item.type.toLowerCase()].main,
                              }}
                            />
                            {item?.type}
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: '1px solid #CACACA',
                            borderRight: 0,
                          }}
                          align="center"
                        >
                          {item?.margin?.value ? `${item?.margin?.value}%` : '-'}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: '1px solid #CACACA',
                            borderRight: 0,
                          }}
                          align="center"
                        >
                          <MenuButton type={item.type} handleMarginModal={handleMarginModal} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </>
      )}
      {isLoading && <Loader isLoaderVisible />}

      <AddMarginModal isOpen={marginModal} handleMarginModal={handleMarginModal} refetchData={refetch} />
    </Page>
  );
};

const MenuButton = ({ type, handleMarginModal }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const onClickEdit = () => {
    if (params?.get('env')) {
      navigate({
        pathname: '/dashboard/margin',
        search: `?env=Prod&marginId=${type}`,
      });
    } else {
      navigate({
        pathname: '/dashboard/margin',
        search: `?marginId=${type}`,
      });
    }

    handleMarginModal();
  };
  return (
    <Box>
      <IconButton onClick={onClickEdit} sx={{ padding: '0 !important' }}>
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default Margin;
