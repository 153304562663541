import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
import Page from '../../components/Page';
import { AppCurrentVisits } from '../../sections/@dashboard/app';
import { Info } from '../../components/Custom/Info/Info';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch';
import styles from './Dashboard.module.scss';
import { getDashboardInfo } from '../../api/DashboardInfo';
import Loader from '../../components/Loader/Loader';
import { VerticalDataBox } from '../../components/Custom/VerticalDataBox/VerticalDataBox';

const DashboardApp = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(window?.location?.search);

  const { data, isLoading } = useQuery('getDashboardInfo', () => getDashboardInfo(), {
    refetchOnWindowFocus: false,
  });
  const dashboardData = data?.data?.data;

  // const handleChange = () => {
  //   if (!window?.location?.search) {
  //     navigate({
  //       pathname: '/dashboard/app',
  //       search: '?env=Prod',
  //     });
  //   } else {
  //     navigate({
  //       pathname: '/dashboard/app',
  //     });
  //   }
  //   setChecked(window?.location?.search);

  //   window?.location?.reload();
  // };

  const dashBoardInfo = [
    {
      title: 'Total Users',
      info: [
        {
          label: '',
          count: dashboardData?.totalUsers ? dashboardData?.totalUsers : 0,
        },
      ],
      extraData: [
        { label: 'Active', count: dashboardData?.activeUsers ? dashboardData?.activeUsers : 0 },
        { label: 'Blocked', count: dashboardData?.blockedUsers ? dashboardData?.blockedUsers : 0 },
      ],
    },
    {
      title: 'Supply Chain',
      info: [
        { label: 'Sellers', count: dashboardData?.sellers ? dashboardData?.sellers : 0 },
        { label: 'Businesses', count: dashboardData?.businesses ? dashboardData?.businesses : 0 },
      ],
      extraData: [
        { label: 'Food', count: dashboardData?.foodBusiness ? dashboardData?.foodBusiness : 0 },
        { label: 'Product', count: dashboardData?.productBusiness ? dashboardData?.productBusiness : 0 },
        { label: 'Video', count: dashboardData?.videoBusiness ? dashboardData?.videoBusiness : 0 },
      ],
    },
    {
      title: 'Consumption',
      info: [
        { label: 'Buyers', count: dashboardData?.buyers ? dashboardData?.buyers : 0 },
        { label: 'Average Spend', count: dashboardData?.averageSpend ? dashboardData?.averageSpend : 0 },
      ],
    },
    {
      title: 'Financials',
      info: [
        { label: 'Invoices Total', count: dashboardData?.invoiceTotal ? dashboardData?.invoiceTotal : 0 },
        { label: 'GMV', count: dashboardData?.gmv ? dashboardData?.gmv : 0 },
        { label: 'Revenue', count: dashboardData?.revenue ? dashboardData?.revenue : 0 },
        { label: 'Seller Payout', count: dashboardData?.sellerPayout ? dashboardData?.sellerPayout : 0 },
        { label: 'Logistics Payout', count: dashboardData?.logisticsPayout ? dashboardData?.logisticsPayout : 0 },
        { label: 'GST Payout', count: dashboardData?.gstPayout ? dashboardData?.gstPayout : 0 },
      ],
    },
  ];

  const getPercentageAndAverage = (title, label) => {
    if (title === 'Supply Chain') {
      if (label === 'Sellers') {
        return Number((dashboardData?.sellers * 100) / dashboardData?.totalUsers).toFixed(2);
      }
      if (label === 'Businesses') {
        return Math.floor(dashboardData?.businesses / dashboardData?.sellers);
      }
    }
    if (title === 'Consumption' && label === 'Buyers') {
      return Number((dashboardData?.buyers * 100) / dashboardData?.totalUsers).toFixed(2);
    }
    return undefined;
  };

  return (
    <Page title="Dashboard">
      {!isLoading && (
        <Container maxWidth="100%" className={styles.mainFlex}>
          <Box className={styles.leftBox}>
            {/* <Box className={styles.displayFlex}>
              <Typography className={checked ? styles.notChecked : styles.checked}>Test</Typography>
              <ToggleSwitch checked={checked} onChange={() => handleChange()} />
              <Typography className={checked ? styles.checked : styles.notChecked}> Production</Typography>
            </Box> */}
            {(dashBoardInfo || []).map((item, index) => {
              return (
                <Box key={index} className={styles.dFlex}>
                  <Typography className={styles.title}>{item?.title}</Typography>
                  <Box className={styles.showFlex}>
                    {(item?.info || []).map((element, ind) => {
                      return (
                        <Info
                          label={element?.label}
                          count={
                            element?.label !== 'Sellers' ||
                            element?.label !== 'Businesses' ||
                            element?.label !== 'Buyers'
                              ? element?.count
                              : element?.count?.toFixed(2)
                          }
                          key={ind}
                          percentageAndAverage={getPercentageAndAverage(item?.title, element?.label)}
                        />
                      );
                    })}
                  </Box>
                  {Boolean(item?.extraData) && item?.title === 'Total Users' && (
                    <Box className={styles.showFlex}>
                      {item.extraData.map((subItem, index) => {
                        return (
                          <Box className={styles.extraDataOneWrapper} key={index}>
                            <Typography
                              className={subItem?.label === 'Active' ? styles.extraDataLabelA : styles.extraDataLabelB}
                            >
                              {subItem.label} -{' '}
                              <Typography component={'span'} className={styles.extraDataCount}>
                                {subItem.count}
                              </Typography>
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  {Boolean(item?.extraData) && item?.title === 'Supply Chain' && (
                    <Box className={styles.showFlexVertical}>
                      {item.extraData.map((subItem, index) => (
                        <VerticalDataBox key={index} vertical={subItem.label} count={subItem.count} />
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box className={styles.graphContainer}>
            <AppCurrentVisits
              chartData={[
                {
                  label: 'Food',
                  value: dashboardData?.foodBusiness ? dashboardData?.foodBusiness : 0,
                },
                {
                  label: 'Product',
                  value: dashboardData?.productBusiness ? dashboardData?.productBusiness : 0,
                },
                {
                  label: 'Video',
                  value: dashboardData?.videoBusiness ? dashboardData?.videoBusiness : 0,
                },
              ]}
              chartColors={[theme.palette.food.main, theme.palette.product.main, theme.palette.video.main]}
              title={'Businesses'}
            />
            <AppCurrentVisits
              chartData={[
                {
                  label: 'Food',
                  value: dashboardData?.orderDistribution?.food ? dashboardData?.orderDistribution?.food : 0,
                },
                {
                  label: 'Product',
                  value: dashboardData?.orderDistribution?.product ? dashboardData?.orderDistribution?.product : 0,
                },
                {
                  label: 'Video',
                  value: dashboardData?.orderDistribution?.video ? dashboardData?.orderDistribution?.video : 0,
                },
              ]}
              chartColors={[theme.palette.food.main, theme.palette.product.main, theme.palette.video.main]}
              title={'Orders'}
            />
            <AppCurrentVisits
              chartData={[
                {
                  label: 'Food',
                  value: dashboardData?.revenueDistribution?.food ? dashboardData?.revenueDistribution?.food : 0,
                },
                {
                  label: 'Product',
                  value: dashboardData?.revenueDistribution?.product ? dashboardData?.revenueDistribution?.product : 0,
                },
                {
                  label: 'Video',
                  value: dashboardData?.revenueDistribution?.video ? dashboardData?.revenueDistribution?.video : 0,
                },
              ]}
              chartColors={[theme.palette.food.main, theme.palette.product.main, theme.palette.video.main]}
              title={'Revenue'}
            />
            <AppCurrentVisits
              chartData={[
                {
                  label: 'Revenue',
                  value: dashboardData?.revenue ? dashboardData?.revenue : 0,
                },
                {
                  label: 'Seller Payout',
                  value: dashboardData?.sellerPayout ? dashboardData?.sellerPayout : 0,
                },
                {
                  label: 'GST Payout',
                  value: dashboardData?.gstPayout ? dashboardData?.gstPayout : 0,
                },
                {
                  label: 'Logistics',
                  value: dashboardData?.logisticsPayout ? dashboardData?.logisticsPayout : 0,
                },
              ]}
              chartColors={[theme.palette.food.main, theme.palette.product.main, theme.palette.video.main]}
              title={'Financial'}
            />
          </Box>
        </Container>
      )}
      {isLoading && <Loader isLoaderVisible />}
    </Page>
  );
};

export default DashboardApp;
