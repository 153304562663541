import client from './Client';

const getItem = ({ id, type, videoType, storeId }) => {
  const token = localStorage.getItem('token');

  const value = {};
  value.type = type;
  if (type === 'Video') {
    value.videoType = videoType;
  }
  const endPoint = `/su/store/${storeId}/item/${id}`;

  return client.get(endPoint, value, {
    headers: {
      Authorization: token,
    },
  });
};

export { getItem };
