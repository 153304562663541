import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import styles from './Payments.module.scss';

const PayOutTable = ({ payOut }) => {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#F5F4F4' }}>
            <TableCell width="299px" className={styles.tableCell}>
              Paid To
            </TableCell>
            <TableCell width="173px" className={styles.tableCell}>
              Reason
            </TableCell>
            <TableCell width="219px" className={styles.tableCell}>
              Date
            </TableCell>
            <TableCell width="182px" className={styles.tableCell}>
              Doc
            </TableCell>
            <TableCell width="127px" className={styles.tableCell}>
              Amount ₹
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(payOut || []).map((item, index) => {
            return (
              <TableRow key={index} className={styles.tableRow}>
                <TableCell className={styles.tableDataCell}>
                  <Typography className={styles.linkText}>{item?.paymentTo}</Typography>
                </TableCell>

                <TableCell className={styles.tableDataCell}>{item?.reason}</TableCell>

                <TableCell className={styles.tableDataCell}>
                  {moment(item?.createdAt).format('DD-MM-YYYY - hh:mm A')}
                </TableCell>

                <TableCell className={styles.tableDataCell}>
                  {/* <Typography className={styles.linkText}> */}
                  {/* {item?.docType === 'Invoice' ? item?.invoiceNumber : ''} */}
                  {item?.docType}
                  {/* </Typography> */}
                </TableCell>

                <TableCell className={styles.tableDataCell}>{item?.amount?.toFixed(2)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayOutTable;
