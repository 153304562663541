import { Box, Typography, useTheme } from '@mui/material';
import styles from './StoreDetailStyles.module.scss';

const StoreBusinessParameters = ({ businessDays, preparationTime, businessTiming, open24Hours }) => {
  const theme = useTheme();

  return (
    <Box className={styles.generalInfo}>
      <Box className={styles.dFlex} sx={{ alignItems: 'center' }}>
        <Typography className={styles.title}>Business days</Typography>
        <Typography component={'div'} className={styles.value} sx={{ display: 'flex', gap: '10px' }}>
          {(businessDays || []).map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: '2px',
                  padding: '5px 10px',
                  background: '#F9F9FF',
                }}
              >
                {item}
              </Box>
            );
          })}
        </Typography>
      </Box>

      <Box className={styles.dFlex}>
        <Typography className={styles.title}>business Timings</Typography>
        {
          open24Hours
            ?
            (
              <Typography component={'div'} className={styles.value} sx={{ display: 'flex', gap: '10px' }}>
                {'Open 24 Hours'}
              </Typography>
            ) :
            (
              <Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  <Typography component={'div'} className={styles.value} sx={{ width: '100px' }}>
                    Start Time
                  </Typography>
                  <Typography component={'div'} className={styles.value}>
                    {businessTiming?.startTime}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                  <Typography component={'div'} className={styles.value} sx={{ width: '100px' }}>
                    End Time
                  </Typography>
                  <Typography component={'div'} className={styles.value}>
                    {businessTiming?.endTime}
                  </Typography>
                </Box>
              </Box>
            )
        }
      </Box>

      {/* <Box className={styles.dFlex}>
        <Typography className={styles.title}>preperation Timings</Typography>
        <Typography className={styles.value} sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {Number(preparationTime?.days) !== 0 && (
            <Typography className={styles.value}>{preparationTime?.days} days</Typography>
          )}

          {Number(preparationTime?.hours) !== 0 && (
            <Typography className={styles.value}>{preparationTime?.hours} hours</Typography>
          )}

          {Number(preparationTime?.mins) !== 0 && (
            <Typography className={styles.value}>{preparationTime?.mins} mins</Typography>
          )}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default StoreBusinessParameters;
