import client from "./Client";

const endPoint = '/su/category/';

const getSubCategories = ({ catId, fetchFor }) => {
    const token = localStorage.getItem("token");
    return client.get(
        endPoint + catId,
        {
            fetchFor
        },
        {
            headers: {
                Authorization: token
            }
        })
}

export { getSubCategories }