import client from './Client';

const endPoint = '/su/whatsapp/media/';

const deleteMedia = (mediaId) => {
  const token = localStorage.getItem('token');
  return client.delete(
    endPoint + mediaId,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { deleteMedia };
