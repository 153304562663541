import client from "./Client";

const endPoint = '/su/carousel/';

const createCarousel = (value) => {
    const token = localStorage.getItem("token");
    return client.post(endPoint, value, {
        headers: {
            Authorization: token
        }
    })
}

export { createCarousel }