import client from './Client';

const endPoint = '/su/category/';

const updateCategory = (value, id) => {
  const token = localStorage.getItem('token');
  return client.put(endPoint + id, value, {
    headers: {
      Authorization: token,
    },
  });
};

export { updateCategory };
