import client from './Client';

const endPoint = '/su/gst/';

const getStateGstById = (id) => {
  const token = localStorage.getItem('token');
  return client.get(
    endPoint + id,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export { getStateGstById };
