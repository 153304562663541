import { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import styles from './StoreDetailStyles.module.scss';

const StoreAddress = ({ residentialAddress, pickUpAddress, storeType }) => {
  const [isPickUpAddressSame, setisPickUpAddressSame] = useState(pickUpAddress?.isPickUpSameAsResidential);

  return (
    <>
      <Box className={styles.mainAddressContainer}>
        <Box sx={{ borderRight: storeType !== 'Video' ? '1px solid #CACACA' : 'none', paddingRight: '30px' }}>
          <Typography className={styles.addressTitle}>Residential Address</Typography>

          <Box className={styles.addressContainer}>
            <Box className={styles.dFlex}>
              <Typography className={styles.title}>Address</Typography>
              <Typography className={styles.value} sx={{ width: storeType === "Video" ? "auto" : 255 }}>
                {residentialAddress?.address}
              </Typography>
            </Box>

            {
              residentialAddress?.address2 && (
                <Box className={styles.dFlex}>
                  <Typography className={styles.title}>Address 2</Typography>
                  <Typography className={styles.value} sx={{ width: storeType === "Video" ? "auto" : 255 }}>
                    {residentialAddress?.address2}
                  </Typography>
                </Box>
              )
            }

            <Box className={styles.dFlex}>
              <Typography className={styles.title}>State</Typography>
              <Typography className={styles.value}>{residentialAddress?.state}</Typography>
            </Box>

            <Box className={styles.dFlex}>
              <Typography className={styles.title}>City</Typography>
              <Typography className={styles.value}>{residentialAddress?.city}</Typography>
            </Box>

            <Box className={styles.dFlex}>
              <Typography className={styles.title}>Pincode</Typography>
              <Typography className={styles.value}>{residentialAddress?.pincode}</Typography>
            </Box>
          </Box>
        </Box>

        {storeType !== 'Video' && (
          <Box sx={{ marginLeft: '30px' }}>
            <Typography className={styles.addressTitle}>Pick-Up Address</Typography>

            <Box className={styles.addressContainer}>
              <Box className={styles.dFlex}>
                <Typography className={styles.title}>Address</Typography>
                <Typography className={styles.value} sx={{ width: 255 }}>
                  {isPickUpAddressSame ? residentialAddress?.address : pickUpAddress?.address?.address}
                </Typography>
              </Box>

              {pickUpAddress?.address?.address && (
                <Box className={styles.dFlex}>
                  <Typography className={styles.title}>Address 2</Typography>
                  <Typography className={styles.value} sx={{ width: 255 }}>
                    {isPickUpAddressSame ? residentialAddress?.address2 : pickUpAddress?.address?.address2}
                  </Typography>
                </Box>
              )}

              <Box className={styles.dFlex}>
                <Typography className={styles.title}>State</Typography>
                <Typography className={styles.value}>
                  {isPickUpAddressSame ? residentialAddress?.state : pickUpAddress?.address?.state}
                </Typography>
              </Box>

              <Box className={styles.dFlex}>
                <Typography className={styles.title}>City</Typography>
                <Typography className={styles.value}>
                  {isPickUpAddressSame ? residentialAddress?.city : pickUpAddress?.address?.city}
                </Typography>
              </Box>

              <Box className={styles.dFlex}>
                <Typography className={styles.title}>Pincode</Typography>
                <Typography className={styles.value}>
                  {isPickUpAddressSame ? residentialAddress?.pincode : pickUpAddress?.address?.pincode}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default StoreAddress;
