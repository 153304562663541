// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QuestionContainer_btn__kfg5e {\n  border: 1px solid;\n  border-radius: 4px;\n  width: 100px;\n  height: 36px;\n}", "",{"version":3,"sources":["webpack://./src/components/Custom/QuestionContainer/QuestionContainer.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AACJ","sourcesContent":[".btn {\n    border: 1px solid;\n    border-radius: 4px;\n    width: 100px;\n    height: 36px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "QuestionContainer_btn__kfg5e"
};
export default ___CSS_LOADER_EXPORT___;
