import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Avatar, Box, Divider, IconButton, Modal, Slide, SvgIcon, Typography, useTheme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ImageIcon from '@mui/icons-material/Image';
import { ReactComponent as VegIcon } from '../../assets/icons/vegSelectedIcon.svg';
import { ReactComponent as NonVegIcon } from '../../assets/icons/nonVegSelectedIcon.svg';
import { ReactComponent as PetIcon } from '../../assets/icons/petIcon.svg';
import { ReactComponent as VeganIcon } from '../../assets/icons/veganIcon.svg';
import { ReactComponent as JainFoodIcon } from '../../assets/icons/jainFoodIcon.svg';
import { ReactComponent as NoAddedSugarIcon } from '../../assets/icons/noAddedSugarIcon.svg';
import { ReactComponent as ContainSugarIcon } from '../../assets/icons/containSugarIcon.svg';
import { ReactComponent as ContainsEggIcon } from '../../assets/icons/containsEggIcon.svg';
import { ReactComponent as GluttenFree } from '../../assets/icons/gluttenFree.svg';
import { ReactComponent as EggLessIcon } from '../../assets/icons/eggLessIcon.svg';
import { ReactComponent as ExtraSpicyIcon } from '../../assets/icons/extraSpicyIcon.svg';
import { ReactComponent as SpicyIcon } from '../../assets/icons/spicyIcon.svg';
import { ReactComponent as LactoseFreeIcon } from '../../assets/icons/lactoseFreeIcon.svg';
import getS3Bucket from '../../utils/getS3Bucket';
import styles from './ViewItemModal.module.scss';
import { getStoreDetail } from '../../api/GetStoreDetail';

const Icons = {
  'Jain Food': <JainFoodIcon />,
  Vegan: <VeganIcon />,
  'N/A': 'N/A',
  'Egg-Less': <EggLessIcon />,
  'Contains Egg': <ContainsEggIcon />,
  'Gluten Free': <GluttenFree />,
  'Lactose Free': <LactoseFreeIcon />,
  'No Added Sugar': <NoAddedSugarIcon />,
  'Contains Sugar': <ContainSugarIcon />,
  Spicy: <SpicyIcon />,
  'Medium Spicy': <SpicyIcon />,
  'Extra Spicy': <ExtraSpicyIcon />,
};

const FoodTypeIcon = {
  Veg: <VegIcon />,
  'Non Veg': <NonVegIcon />,
  Pet: <PetIcon />,
};

const Quantity = {
  'Pack of': 'Pack of',
  g: 'gm',
  kg: 'kg',
  mL: 'ml',
  L: 'litres',
  inch: 'Inches',
  cm: 'cm',
  m: 'm',
  'Full Portion': 'Full Portion',
  'Half Portion': 'Half Portion',
  'Per Piece': 'Per Piece',
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
};

const ViewItemModal = ({ handleViewItemModal, isOpen, FoodItemDetail, isSuccess }) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { id } = useParams();

  const { data: storeDetailData } = useQuery(['getStoreDetails', id], () => getStoreDetail(id), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });
  const storeDetail = storeDetailData?.data?.data?.[0];

  const onClick = (index) => {
    setSelectedIndex(index);
  };

  const prepTime = FoodItemDetail?.prepTime;


  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      disableAutoFocus
      sx={{
        '.MuiBackdrop-root': {
          background: 'none',
        },
      }}
      className={styles.mainContainer}
    >
      <Slide in={isOpen} direction="up">
        <Box>
          <Box className={styles.modalBody}>
            <IconButton className={styles.closeBtn} onClick={() => handleViewItemModal()}>
              <ClearOutlinedIcon
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 30,
                }}
              />
            </IconButton>
            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>View Item</Typography>
            </Box>
            {isSuccess && (
              <Box className={styles.modalInnerContainer}>
                <Box className={`${styles.nameContainer} ${styles.inputBoxContainer}`} z>
                  <Typography className={styles.inputLabel}>Item Name</Typography>
                  <Typography className={styles.nameText}>{FoodItemDetail?.name}</Typography>
                </Box>
                <Divider className={styles.divider} />

                <Box className={styles.imageNameContainer}>
                  {(FoodItemDetail?.images || []).map((item, index) => {
                    return (
                      <Avatar
                        variant="rounded"
                        className={`${styles.image} ${styles.animatedBackground}`}
                        key={index}
                        src={getS3Bucket() ? getS3Bucket() + item : ''}
                      >
                        <ImageIcon
                          sx={{
                            fontSize: 30,
                          }}
                        />
                      </Avatar>
                    );
                  })}
                </Box>
                <Divider className={styles.divider} />
                <Box className={styles.inputBoxContainer}>
                  <Typography className={styles.inputLabel}>Item description</Typography>
                  <Typography className={styles.desc}>{FoodItemDetail?.description}</Typography>
                </Box>
                <Divider className={styles.divider} />

                <Box className={styles.foodTypeContainer}>
                  <Box className={styles.tagMainContainer}>
                    <Typography className={styles.inputLabel}>Type</Typography>
                    <Box className={styles.tagContainer}>
                      <InfoInput
                        value={
                          <Box className={styles.iconContainer}>
                            <SvgIcon
                              sx={{
                                height: 12,
                                width: 12,
                              }}
                              viewBox="0 0 16 16"
                            >
                              {FoodTypeIcon[FoodItemDetail?.type]}
                            </SvgIcon>

                            <Typography className={styles.iconText}>{FoodItemDetail?.type}</Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                  {FoodItemDetail?.attribute?.spiceLevel !== 'N/A' && (
                    <Box className={styles.tagMainContainer}>
                      <Typography className={styles.inputLabel}>Spice levels</Typography>
                      <Box className={styles.tagContainer}>
                        <InfoInput
                          value={
                            <Box className={styles.iconContainer}>
                              {Icons[FoodItemDetail?.attribute?.spiceLevel]}
                              <Typography className={styles.iconText}>
                                {FoodItemDetail?.attribute?.spiceLevel}
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  )}
                  {FoodItemDetail?.attribute?.vegSpecific !== 'N/A' && (
                    <Box className={styles.tagMainContainer}>
                      <Typography className={styles.inputLabel}>Veg Preparation</Typography>
                      <Box className={styles.tagContainer}>
                        <InfoInput
                          value={
                            <Box className={styles.iconContainer}>
                              {Icons[FoodItemDetail?.attribute?.vegSpecific]}
                              <Typography className={styles.iconText}>
                                {FoodItemDetail?.attribute?.vegSpecific}
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box className={styles.othersContainer}>
                  <Box className={styles.tagMainContainer}>
                    <Typography className={styles.inputLabel}>Other Attributes</Typography>
                    <Box className={styles.tagContainer}>
                      {(FoodItemDetail?.attribute?.other || []).map((item, index) => {
                        return (
                          <InfoInput
                            value={
                              <Box className={styles.iconContainer}>
                                {Icons[item]}
                                <Typography className={styles.iconText}>{item}</Typography>
                              </Box>
                            }
                            key={index}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </Box>

                {FoodItemDetail?.isCustomizable && (
                  <Box>
                    <Divider className={styles.divider} />
                    <Typography className={styles.inputLabel} sx={{ marginBottom: '10px' }}>
                      Customization
                    </Typography>
                    {FoodItemDetail?.customization.map((item, index) => {
                      return (
                        <Box key={index}>
                          <Box className={styles.customizeBox} onClick={() => onClick(index)}>
                            <Typography className={styles.customizeTitle}>{item.title}</Typography>
                            {FoodItemDetail?.customization?.length > 1 && (
                              <KeyboardArrowDownIcon
                                className={styles.arrowIcon}
                                sx={{
                                  transform: selectedIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                }}
                              />
                            )}
                          </Box>
                          <Typography className={styles.typeOfCustomization}>
                            {item.selectionMode === 'Multiple' &&
                              `Multiple Selection - Select ${item.noOfItemSelectable} out of ${item.options}`}
                          </Typography>
                          {selectedIndex === index &&
                            item.listOfItems.map((customItem, customIndex) => {
                              return (
                                <Box className={styles.customizationRow} key={customIndex}>
                                  {FoodTypeIcon[!customItem.type ? 'Veg' : customItem.type]}
                                  <Typography className={styles.customizeOption}>{customItem.name}</Typography>
                                  {customItem?.price && (
                                    <Typography className={styles.customizeOption}>₹ {customItem?.price}.00</Typography>
                                  )}
                                </Box>
                              );
                            })}
                        </Box>
                      );
                    })}
                  </Box>
                )}

                <Divider className={styles.divider} />

                <Box>
                  <Box className={styles.weightAttribute}>
                    <Typography className={styles.inputLabel}>Price</Typography>
                    <Typography className={styles.inputLabel}>UOM</Typography>
                    {/* <Typography className={styles.inputLabel}>Weight</Typography> */}
                  </Box>
                  {(FoodItemDetail?.price || []).map((item, index) => {
                    return (
                      <Box className={styles.weightAttribute} key={index} sx={{ marginTop: '6px' }}>
                        <Typography className={styles.priceText}>₹ {item?.price}</Typography>
                        <Typography className={styles.priceText}>
                          {item?.unit === 'Pack of'
                            ? `Pack of (${item?.value})`
                            : `${item?.value ? item?.value : ''} ${Quantity[item?.unit]}`}
                        </Typography>
                        {/* <Typography className={styles.priceText}>
                            {item?.packageVolume &&
                              `${item?.packageVolume?.length}x${item?.packageVolume?.length}x${item?.packageVolume?.length}`}
                            {item?.packageWeight &&
                              `${item?.packageWeight?.value} ${Quantity[item?.packageWeight?.unit]}`}
                          </Typography> */}
                      </Box>
                    );
                  })}
                </Box>

                <Divider sx={styles.divider} />

                <Box className={styles.shippingContainer}>
                  <Typography className={styles.inputLabel}>Preparation Time</Typography>
                  {prepTime !== undefined ? (
                    <>
                      {Number(prepTime?.days) + Number(prepTime?.hours) + Number(prepTime?.mins) !== 0 ? (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                            }}
                          >
                            {Boolean(Number(prepTime?.days)) && (
                              <Typography component="span" className={styles.colorText}>
                                {prepTime?.days}
                                {Number(prepTime?.days) > 1 ? ' Days ' : ' Day '}
                                {Boolean(Number(prepTime?.hours) || Number(prepTime?.mins)) && '- '}
                              </Typography>
                            )}
                            {Boolean(Number(prepTime?.hours)) && (
                              <Typography component="span" className={styles.colorText}>
                                {prepTime?.hours}
                                {Number(prepTime?.hours) > 1 ? ' Hours ' : ' Hour '}
                                {Boolean(Number(prepTime?.mins)) && '- '}
                              </Typography>
                            )}
                            {Boolean(Number(prepTime?.mins)) && (
                              <Typography component="span" className={styles.colorText}>
                                {prepTime?.mins} {' Mins '}
                              </Typography>
                            )}
                          </Box>
                        </>
                      ) : (
                        <Typography component="span" className={styles.colorText}>
                          Item always ready for pick-up
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography component="span" className={styles.colorText}>
                      Item always ready for pick-up
                    </Typography>
                  )}
                </Box>

                <Divider className={styles.divider} />

                <Box className={styles.priceContainer}>
                  <Typography className={styles.inputLabel}>Sell your items within</Typography>
                  {FoodItemDetail?.sellWithin === 'city' && (
                    <Typography component="span" className={styles.priceText}>
                      {storeDetail?.addresses?.pickUpAddress?.isPickUpSameAsResidential
                        ? storeDetail?.addresses?.residentialAddress?.address?.city
                        : storeDetail?.addresses?.pickUpAddress?.address?.city}
                    </Typography>
                  )}
                  {FoodItemDetail?.sellWithin === 'country' && (
                    <Typography component="span" className={styles.priceText}>
                      {storeDetail?.addresses?.pickUpAddress.isPickUpSameAsResidential
                        ? storeDetail?.addresses?.residentialAddress?.address?.country
                        : storeDetail?.addresses?.pickUpAddress?.address?.country}
                    </Typography>
                  )}

                  {FoodItemDetail?.sellWithin === 'state' && (
                    <Typography component="span" className={styles.priceText}>
                      {storeDetail?.addresses?.pickUpAddress.isPickUpSameAsResidential
                        ? storeDetail?.addresses?.residentialAddress?.address?.state
                        : storeDetail?.addresses?.pickUpAddress?.address?.state}
                    </Typography>
                  )}

                  {/\d/.test(FoodItemDetail?.sellWithin) && (
                    <Typography component="span" className={styles.priceText}>
                      {`${FoodItemDetail?.sellWithin} Km`}
                    </Typography>
                  )}
                </Box>

                {(FoodItemDetail?.searchKeywords || []).join(',') && (
                  <>
                    <Divider className={styles.divider} />
                    <Box className={styles.priceContainer}>
                      <Typography className={styles.inputLabel}>Keywords</Typography>
                      <Typography component="span" className={styles.priceText}>
                        {FoodItemDetail?.searchKeywords.join(',')}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            )}
            {/* <Loader isLoaderVisible={loader} /> */}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

const InfoInput = ({ value }) => {
  return <Box className={styles.inputContainer}>{value}</Box>;
};

export default ViewItemModal;
