import client from "./Client";

const endPoint = '/su/faq/heading/';

const UpdateHeading = ({ id, heading }) => {
    const token = localStorage.getItem("token");
    return client.put(
        endPoint + id,
        {
            heading
        },
        {
            headers: {
                Authorization: token
            }
        }
    )
}

export { UpdateHeading }